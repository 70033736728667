import { FormikProvider, useFormik, useFormikContext } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import InputMask from 'react-input-mask'
import { InputLabel } from '../../../../components/InputLabel'
import { AuthStore, getUsuarioLogado, selectEcvAtual } from '../../../auth/AuthStore'
import { Show } from '../../../../components/Show'
import { Button } from '../../../../components/Button'
import { UsuarioLogado } from '../../../../components/UsuarioLogado'
import { useApi } from '../../../../components/Api'
import toast from 'react-hot-toast'
import { Schemas } from '../../../../components/ApiSchemas'
import { useNavigate } from 'react-router'
import { useWindowWidth } from '../../../../components/hooks/use-window-width'
import { Input } from '../../../../components/Input'

export const CadastroInfoECV = () => {
  const { ContainerButtons, Layout, Flex } = CadastroInfoECV
  const ecvAtual = AuthStore.useSelector(selectEcvAtual)

  const formik = useFormik({
    initialValues: { ...ecvAtual },
    enableReinitialize: true,
    validate: (values) => {
      const errors: any = {}
      if (!values.nome) errors.nome = 'O Nome é obrigatório'
      return errors
    },
    onSubmit: () => {},
  })

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Layout>
          <Flex>
            <Cadastro />
            <Mapa />
          </Flex>

          <ContainerButtons>
            <Cancelar />
            <Salvar />
          </ContainerButtons>
        </Layout>
      </form>
    </FormikProvider>
  )
}
CadastroInfoECV.Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
CadastroInfoECV.ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;

  button {
    width: 100px;
  }

  & > *:last-child {
    margin-left: 20px;
  }

  @media (max-width: 600px) {
    justify-content: center;
    height: 78px;
    padding-bottom: 52px;
    margin-top: 6px;
    margin-bottom: 20px;
  }
`
CadastroInfoECV.Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

const Cadastro = () => {
  const { Layout, Flex } = Cadastro
  const formik = useFormikContext<Schemas.Ecv>()

  const handleAfterPasteCoordenadas = (coordenada) => {
    if (!coordenada) return
    const [coord1, coord2] = coordenada.split(',')

    if (coord1 && coord2) {
      formik.setValues({ ...formik.values, latitude: coord1.trim(), longitude: coord2.trim() })
    }
  }

  return (
    <Layout>
      <Flex>
        <InputCnpj />
      </Flex>
      <Flex>
        <InputNomeECV />
        <InputValorECV />
      </Flex>

      <Flex>
        <InputUf />
        <InputMunicipio />
      </Flex>

      <Flex>
        <InputLatitude handleAfterPasteCoordenadas={handleAfterPasteCoordenadas} />
        <InputLongitude handleAfterPasteCoordenadas={handleAfterPasteCoordenadas} />
      </Flex>

      <InputEndereco />

      <Flex>
        <InputBairro />
        <InputTelefone />
      </Flex>

      <TipoAtendimento />
    </Layout>
  )
}
Cadastro.Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
Cadastro.Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: 650px) {
  }
`

const InputCnpj = ({ ...props }) => {
  const { Grid } = InputCnpj
  const formik = useFormikContext<{ cnpj }>()
  const usuarioLogado = AuthStore.useSelector(getUsuarioLogado)
  const authDispatch = AuthStore.useDispatch()

  const [{ loading }, reqImport] = useApi.Ecvs.importEcv({
    params: { cnpj: formik.values.cnpj },
    options: { manual: true },
  })

  if (!usuarioLogado?.isAdmin) {
    return <InputLabel disabled value={formik.values.cnpj} label="CNPJ *" />
  }

  async function handleClick() {
    try {
      const ecv = await reqImport().then((x) => x.data)
      authDispatch(AuthStore.actions.importEcvSuccess(ecv))
      toast.success('ECV Importado')
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Erro ao importar ECV'
      toast.error(message)
    }
  }

  return (
    <Grid>
      <InputMask mask="99.999.999/9999-99" maskChar="" {...formik.getFieldProps('cnpj')}>
        {(inputProps) => <InputLabel {...props} {...inputProps} label="CNPJ" />}
      </InputMask>
      <Button loading={loading} disabled={!usuarioLogado?.isAdmin} onClick={handleClick}>
        Importar
      </Button>
    </Grid>
  )
}

InputCnpj.Grid = styled.div`
  display: grid;
  align-items: end;
  gap: 20px;
  grid-template-columns: 1fr 120px;
`

const InputNomeECV = ({ ...props }) => {
  const formik = useFormikContext()
  return (
    <InputLabel
      {...props}
      {...formik.getFieldProps('nome')}
      label="Razão Social/Nome"
      className="label-nome-ecv"
    />
  )
}

const InputValorECV = ({ ...props }) => {
  const formik = useFormikContext()
  return (
    <InputLabel
      {...props}
      {...formik.getFieldProps('valor')}
      label="Valor (R$)"
      className="label-valor-ecv"
    />
  )
}

const InputUf = ({ ...props }) => {
  const formik = useFormikContext()

  return (
    <InputLabel {...props} {...formik.getFieldProps('uf')} label="UF" className="label-uf-ecv" />
  )
}

const InputMunicipio = ({ ...props }) => {
  const formik = useFormikContext()

  return (
    <InputLabel
      {...props}
      {...formik.getFieldProps('municipio')}
      label="Município"
      className="label-municipio-ecv"
    />
  )
}

const InputLatitude = ({ handleAfterPasteCoordenadas, ...props }) => {
  const formik = useFormikContext<{ latitude }>()

  return (
    <InputLabel
      {...props}
      {...formik.getFieldProps('latitude')}
      label="Latitude"
      className="label-latitude-ecv"
      onPaste={(e: any) => {
        setTimeout(() => {
          handleAfterPasteCoordenadas(e.target.value)
        }, 0)
      }}
    />
  )
}

const InputLongitude = ({ handleAfterPasteCoordenadas, ...props }) => {
  const formik = useFormikContext()

  return (
    <InputLabel
      {...props}
      {...formik.getFieldProps('longitude')}
      label="Longitude"
      className="label-longitude-ecv"
      onPaste={(e: any) => {
        setTimeout(() => {
          handleAfterPasteCoordenadas(e.target.value)
        }, 0)
      }}
    />
  )
}

const InputEndereco = ({ ...props }) => {
  const formik = useFormikContext()

  return (
    <InputLabel
      {...props}
      {...formik.getFieldProps('endereco')}
      label="Endereço"
      className="label-endereco-ecv"
    />
  )
}

const InputBairro = ({ ...props }) => {
  const formik = useFormikContext()

  return (
    <InputLabel
      {...props}
      {...formik.getFieldProps('bairro')}
      label="Bairro"
      className="label-bairro-ecv"
    />
  )
}

const InputTelefone = ({ ...props }) => {
  const formik = useFormikContext()

  return (
    <InputLabel
      {...props}
      {...formik.getFieldProps('telefone')}
      label="Telefone"
      className="label-telefone-ecv"
    />
  )
}

const TipoAtendimento = () => {
  const { Layout, RadioGroup } = TipoAtendimento
  const formik = useFormikContext()
  const tipoAtendimento = formik.getFieldProps('tipoAtendimento').value

  const handleChange = (tipoAtendimento: string) => {
    formik.setFieldValue('tipoAtendimento', tipoAtendimento)
  }

  return (
    <Layout>
      <label>
        Forma de atendimento
        <RadioGroup>
          <label>
            <input
              type="radio"
              checked={tipoAtendimento === 'AGENDAMENTO'}
              onChange={() => handleChange('AGENDAMENTO')}
            />{' '}
            Agendamento
          </label>
          <label>
            <input
              type="radio"
              checked={tipoAtendimento === 'ORDEM_CHEGADA'}
              onChange={() => handleChange('ORDEM_CHEGADA')}
            />{' '}
            Ordem de chegada
          </label>
        </RadioGroup>
      </label>
      <Show when={tipoAtendimento === 'AGENDAMENTO'}>
        <InputLabel
          label="Quantidade de vistoriadores"
          style={{ width: '190px' }}
          type="number"
          {...formik.getFieldProps('qtdVistoriadores')}
        />
      </Show>
    </Layout>
  )
}
TipoAtendimento.Layout = styled.div`
  font-size: 14px;
  line-height: 120%;
  color: #545451;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`
TipoAtendimento.RadioGroup = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 50px;
`

const Mapa = () => {
  const windowSize = useWindowWidth()
  const { Layout } = Mapa
  const {
    values: { latitude, longitude },
  } = useFormikContext<Schemas.Ecv>()

  if (windowSize <= 650) return null

  return (
    <Layout>
      <img
        src={`${
          process.env.GOOGLE_MAPS_API
        }/staticmap?key=AIzaSyDiZsogKGoeOD_XLVeqPvrSyRzTCoR3y-I&markers=color:0xFFBC01|${
          latitude || 0
        },${
          longitude || 0
        }&zoom=15&format=png&maptype=roadmap&style=feature:administrative%7Celement:geometry%7Cvisibility:off&style=feature:administrative.land_parcel%7Celement:labels%7Cvisibility:off&style=feature:poi%7Cvisibility:off&style=feature:poi%7Celement:labels.text%7Cvisibility:off&style=feature:road%7Celement:labels.icon%7Cvisibility:off&style=feature:road.local%7Celement:labels%7Cvisibility:off&style=feature:transit%7Cvisibility:off&size=240x360`}
      />
    </Layout>
  )
}
Mapa.Layout = styled.div``

const Cancelar = () => {
  const navigate = useNavigate()
  return (
    <Button type="button" onClick={() => navigate('/adm')}>
      Cancelar
    </Button>
  )
}

const Salvar = () => {
  const formik = useFormikContext<Schemas.Ecv>()
  const { ecvs: ecvs } = AuthStore.useState()
  const ecvAtual = AuthStore.useSelector(selectEcvAtual)
  const dispatch = AuthStore.useDispatch()
  const { values } = formik

  const [{ loading }, update] = (useApi.Ecvs.updateECV as any)(
    values,
    { id: values.id },
    { options: { manual: true } }
  )

  const handleUpdate = async () => {
    const { values } = formik
    if (values.cnpj !== ecvAtual?.cnpj) {
      toast.error('O CNPJ é diferente do selecionado, faça a importação para ser atualizado')
      return
    }

    try {
      await update().then((x) => x.data)
      dispatch(AuthStore.actions.changeEcvs([...ecvs.filter((e) => e.id !== values.id), values]))
      toast.success('Alterações feitas com sucesso')
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'Não foi possível fazer as alterações'
      toast.error(message)
    }
  }
  return (
    <Button disabled={!formik.isValid} type="submit" primary onClick={handleUpdate}>
      Salvar
    </Button>
  )
}
