import React, { useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import iconCheck from '../../../assets/icon-check.svg'
import iconHidden from '../../../assets/icon-hidden.svg'
import { InputLabel } from '../../../components/InputLabel'
import { Button } from '../../../components/Button'
import { TelefoneInput } from '../../../components/TelefoneInput'
import { ISenha } from './dadosDto'
import { removeCaracter, validarSenha } from './functions'
import { AuthStore, getUsuarioLogado } from '../../auth/AuthStore'
import { useApi } from '../../../components/Api'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { InputSenha } from '../../../components/InputSenha'
import { UsuarioLogado } from '../../../components/UsuarioLogado'
import { Show } from '../../../components/Show'
import { Schemas } from '../../../components/ApiSchemas'

export const CadastroCliente = () => {
  const { Layout, Container, Flex, VerticalDivider, OpcoesTitulo, Divider } = CadastroCliente
  const [formulario, setFormulario] = useState<'Cadastro' | 'Senha'>('Cadastro')
  const { token } = AuthStore.useState()

  if (!token) return null

  const handleSelectTitle = nome => {
    setFormulario(nome)
  }

  return (
    <Layout>
      <Flex>
        <UsuarioLogado aplicacao='preto' />
      </Flex>
      <Divider />
      <Container>
        <OpcoesTitulo>
          <Titulo
            nome='Cadastro'
            formularioAtivo={formulario}
            handleSelectTitle={handleSelectTitle}
          />
          <VerticalDivider />
          <Titulo
            nome='Senha'
            formularioAtivo={formulario}
            handleSelectTitle={handleSelectTitle}
          />
        </OpcoesTitulo>
        <Show when={formulario === 'Cadastro'}>
          <Cadastro />
        </Show>
        <Show when={formulario === 'Senha'}>
          <Senha />
        </Show>
      </Container>
    </Layout>
  )
}
CadastroCliente.Layout = styled.div`
  width: 100%;
  padding: 0 40px;
  @media (max-width: 900px) {
    padding: 0 24px;
  }
`
CadastroCliente.Container = styled.div`
`
CadastroCliente.Flex = styled.div`
  display: flex;
  margin: 30px 0;
  justify-content: flex-end;
  @media (max-width: 900px) {
    display: none;
  }
`
CadastroCliente.VerticalDivider = styled.div`
  height: 18px;
  width: 3px;
  background: #545451;
  margin-top: 2px;
`
CadastroCliente.OpcoesTitulo = styled.div`
  display: flex;
  margin: 20px 0px 20px 0px;
  gap: 12px;
`
CadastroCliente.Divider = styled.div`
  border-bottom: 2px solid #D4D4D4;
  width: 100%;
  @media (max-width: 900px) {
    display: none;
  }
`

const Cadastro = () => {
  const { Layout, Flex, ButtonsContainer } = Cadastro
  const usuarioLogado = AuthStore.useSelector(getUsuarioLogado)
  const [dadosCadastro, setDadosCadastro] = useState(usuarioLogado as Schemas.Cliente)
  const dispatch = AuthStore.useDispatch()
  const navigate = useNavigate()

  const handleDadosCadastro = (data) => {
    setDadosCadastro({
      ...dadosCadastro,
      ...data,
    })
  }

  const [{ loading }, update] = useApi.Clientes.updateCadastro(dadosCadastro, { options: { manual: true } })

  const handleUpdate = async () => {
    if (dadosCadastro.telefone.length > 0 && dadosCadastro.telefone.length < 11) return toast.error('Para atualizar o telefone, insira um número válido')
    if (!dadosCadastro.email) return toast.error('Para atualizar seus dados é necessário um e-mail')
    if (!dadosCadastro.nome) return toast.error('Para atualizar seus dados é necessário um nome')
    try {
      const { token } = await update().then(x => x.data)
      dispatch(AuthStore.actions.updateCadastroToken(token))
      toast.success('Cadastro atualizado com sucesso')
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Erro ao fazer atualização do cadastro'
      toast.error(message)
    }
  }

  return (
    <Layout>
      <Flex>
        <Nome nome={dadosCadastro.nome} handleDadosCadastro={handleDadosCadastro} />
        <CpfCnpj cpfCnpj={dadosCadastro.cpfCnpj} />
      </Flex>
      <Flex>
        <Telefone telefone={dadosCadastro.telefone} handleDadosCadastro={handleDadosCadastro} />
        <Email email={dadosCadastro.email} handleDadosCadastro={handleDadosCadastro} />
      </Flex>
      <ButtonsContainer>
        <Button onClick={() => navigate('/adm')}>Cancelar</Button>
        <Button
          loading={loading}
          primary={!!dadosCadastro.nome && !!dadosCadastro.telefone}
          disabled={!dadosCadastro.nome || !dadosCadastro.telefone}
          onClick={handleUpdate}>Salvar</Button>
      </ButtonsContainer>
    </Layout>
  )
}
Cadastro.Layout = styled.div`
  width: 800px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 1024px) {
    width: 100%;
  }
`
Cadastro.Flex = styled.div`
  display: flex;
  gap: 20px;
  &>* {
    flex: 1
  }
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`
Cadastro.ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  &>*:last-child{
    margin-left: 20px;
  }
`

const Senha = () => {
  const { Layout, Flex, ButtonsContainer, LabelModificarSenha } = Senha

  const usuarioLogado = AuthStore.useSelector(getUsuarioLogado) as Schemas.Cliente
  const navigate = useNavigate()
  const [dadosSenha, setDadosSenha] = useState<ISenha>({ senhaAtual: '', novaSenha: '', confirmarSenha: '' })

  const handleSetDadosSenha = (data: ISenha) => {
    setDadosSenha({
      ...dadosSenha,
      ...data,
    })
  }

  const [{ loading }, updateSenha] = useApi
    .Clientes
    .updateSenha({ ...dadosSenha, cpfCnpj: usuarioLogado.cpfCnpj }, { options: { manual: true } })

  const handleUpdateSenha = async () => {
    try {
      await updateSenha().then(x => x.data)
      toast.success('Senha alterada com sucesso')
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Erro ao fazer a atualização da senha'
      toast.error(message)
    }
  }

  const valid = useMemo(() => {
    const { confirmarSenha, novaSenha, senhaAtual } = dadosSenha
    if (!confirmarSenha || !novaSenha || !senhaAtual) return false
    if (novaSenha.length < 6) return false
    if (novaSenha !== confirmarSenha) return false
    return true
  }, [dadosSenha])

  return (
    <Layout>
      <LabelModificarSenha>Modificar Senha</LabelModificarSenha>
      <Flex>
        <SenhaAtual handleSetDadosSenha={handleSetDadosSenha} senhaAtual={dadosSenha.senhaAtual} />
      </Flex>
      <Flex>
        <NovaSenha handleSetDadosSenha={handleSetDadosSenha} novaSenha={dadosSenha.novaSenha} />
        <ConfirmarSenha
          handleSetDadosSenha={handleSetDadosSenha}
          confirmarSenha={dadosSenha.confirmarSenha}
          novaSenha={dadosSenha.novaSenha}
        />
      </Flex>
      <ButtonsContainer>
        <Button onClick={() => navigate('/adm')}>
          Cancelar
        </Button>
        <Button
          disabled={!valid}
          primary={valid}
          loading={loading}
          onClick={handleUpdateSenha}
        >
          Salvar
        </Button>
      </ButtonsContainer>
    </Layout>
  )
}
Senha.Layout = styled.div`
  width: 800px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media(max-width: 1024px){
    width: 100%;
  }
`
Senha.Flex = styled.div`
  display: flex;
  gap: 20px;
  &>*{
    flex: 1;
  }
  @media(max-width: 1024px){
    flex-direction: column;
  }
`
Senha.LabelModificarSenha = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #545451;
`
Senha.ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  &>*:last-child{
  margin-left: 20px;
  }
`

const SenhaAtual = ({ senhaAtual, handleSetDadosSenha }) => {
  return (
    <InputSenha
      value={senhaAtual}
      onChange={e => handleSetDadosSenha({ senhaAtual: e.target.value })}
      label='Senha Atual'
      placeholder='Digite sua senha atual'
    />
  )
}

const NovaSenha = ({ novaSenha, handleSetDadosSenha }) => {
  const errors = novaSenha && novaSenha.length < 6 ? ['A senha deve ter no mínimo 6 caracteres'] : []

  return (
    <InputSenha
      value={novaSenha}
      onChange={e => handleSetDadosSenha({ novaSenha: e.target.value })}
      label='Nova Senha'
      placeholder='Digite a nova senha'
      errors={errors}
    />
  )
}

const ConfirmarSenha = ({ confirmarSenha, handleSetDadosSenha, novaSenha }) => {
  const errors = confirmarSenha && confirmarSenha !== novaSenha ? ['Senhas não conferem'] : []
  return (
    <InputSenha
      value={confirmarSenha}
      onChange={e => handleSetDadosSenha({ confirmarSenha: e.target.value })}
      label='Confirmar senha'
      placeholder='Repita a senha'
      errors={errors}
    />
  )
}

const Nome = ({ handleDadosCadastro, nome }) => {
  return (
    <InputLabel
      value={nome}
      label="Nome"
      onChange={e => handleDadosCadastro({ nome: e.target.value })}
      iconSvg={nome && iconCheck}
    />
  )
}

const Telefone = ({ handleDadosCadastro, telefone }) => {
  const errors = telefone && telefone.length < 11 ? ['Um telefone válido deve conter 11 dígitos'] : []
  return (
    <TelefoneInput
      value={telefone}
      name='Telefone'
      onChange={e => handleDadosCadastro({ telefone: removeCaracter(e.target.value) })}
      inputAdorment
      errors={errors}
      iconSvg={telefone && iconCheck}
    />
  )
}

const CpfCnpj = ({ cpfCnpj }) => {
  return (
    <InputLabel
      label="CPF/CNPJ"
      disabled
      value={cpfCnpj}
    />
  )
}

const Email = ({ email, handleDadosCadastro }) => {
  return (
    <InputLabel
      label="E-mail"
      data-cy="input-email"
      value={email}
      onChange={e => handleDadosCadastro({ email: e.target.value })}
      iconSvg={email && iconCheck}
    />
  )
}

const Titulo = ({ nome, handleSelectTitle, formularioAtivo }) => {
  const { Style } = Titulo

  return (
    <Style onClick={() => handleSelectTitle(nome)} active={formularioAtivo === nome}>{nome}</Style>
  )
}
Titulo.Style = styled.div<{ active }>(({ active }) => `
  color: ${active ? 'black' : '#aaaaa8'};
  font-weight: bold;
  cursor: pointer;
  font-size: 20px;
`)
