import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { HyperpayCobranca } from '@hyperdev-tech/hyperpay-cobranca'
import { darken } from 'polished'
import { Schemas } from '../../../components/ApiSchemas'
import { formatValor } from '../../../components/format-valor'
import { api } from '../../../components/Api'
import { handleClickWhatsapp, ReciboComponent } from './ReciboComponent'
import { Button } from '../../../components/Button'
import iconWhatsapp from '../../../assets/icon-whatsapp.svg'
import { Show } from '../../../components/Show'
import toast from 'react-hot-toast'
import { Modal } from '../../../components/Modal'
import { AgendamentoComponent } from './AgendamentoComponent'
import { createSimpleStore } from 'react-simple-reducer'
import { createSelector } from 'reselect'
import { encodeHashCobranca } from '@hyperdev-tech/hash-cobranca'

const Store = createSimpleStore(
  {
    loadingCheckPagamento: false,
    vistoria: {} as Schemas.Vistoria,
    isPagamentoOpen: false,
    isAgendamentoOpen: false,
    role: '' as 'ADM' | 'HOME',
  },
  {
    checkPagamentoStarted(state) {
      state.loadingCheckPagamento = true
    },
    checkPagamentoError(state) {
      state.loadingCheckPagamento = false
    },
    checkPagamentoSuccess(state, payload) {
      state.vistoria = { ...state.vistoria, ...payload }
      state.loadingCheckPagamento = false
    },
    changeDataAgendamento(state, dataAgendamento) {
      state.vistoria.dataAgendamento = dataAgendamento
    },
    openAgendamento(state) {
      state.isAgendamentoOpen = true
      state.vistoria.dataAgendamento = null
    },
    openPagamento(state) {
      state.isPagamentoOpen = true
    },
    init(state, payload: { vistoria: Schemas.Vistoria; role }) {
      state.vistoria = payload.vistoria
      state.role = payload.role
    },
  },
  {
    thunks: {
      checkPagamentoVistoria: (id: number) => {
        return async (dispatch) => {
          try {
            dispatch(Store.actions.checkPagamentoStarted())
            const data = await api.Vistorias.checkPagamento({ vistoriaId: id })
            dispatch(Store.actions.checkPagamentoSuccess(data))
          } catch (error) {
            console.log(error)
            dispatch(Store.actions.checkPagamentoError())
          }
        }
      },
    },
  }
)

const selectStage = createSelector(
  (s) => s.vistoria.dataPagamentoCobranca,
  (s) => s.vistoria.dataAgendamento,
  (s) => s.vistoria.dataConclusao,
  (s) => s.role,
  (s) => s.isAgendamentoOpen,
  (s) => s.isPagamentoOpen,
  (dataPagamento, dataAgendamento, dataConclusao, role, isAgendamentoOpen, isPagamentoOpen) => {
    if (dataConclusao) return 'CONCLUIDA'
    if (dataAgendamento) return 'COMPROVANTE_AGENDAMENTO'
    if (dataPagamento && isAgendamentoOpen) return 'AGENDAMENTO'
    if (dataPagamento) return 'PAGAMENTO_EFETUADO'
    if (role === 'ADM' && !isPagamentoOpen) return 'SOLICITACAO_CRIADA'
    if (!dataPagamento) return 'PAGAMENTO'
  }
)

export const FluxoSolicitacao = ({
  vistoria,
  role,
}: {
  vistoria: Schemas.Vistoria | null
  role: 'ADM' | 'HOME'
}) => {
  const init = useCallback(
    (dispatch) => {
      if (vistoria) dispatch(Store.actions.init({ vistoria, role }))
    },
    [vistoria]
  )

  if (!vistoria) return null

  return (
    <Store.Provider init={init}>
      <StoreSwitch case={selectStage}>
        <Match when="SOLICITACAO_CRIADA">
          <SolicitacaoCriada />
        </Match>
        <Match when="PAGAMENTO">
          <Pagamento />
        </Match>
        <Match when="PAGAMENTO_EFETUADO">
          <ComprovantePagamento />
        </Match>
        <Match when="AGENDAMENTO">
          <Agendamento />
        </Match>
        <Match when="COMPROVANTE_AGENDAMENTO">
          <ComprovanteAgendamento />
        </Match>
        <Match when="CONCLUIDA">
          <VistoriaConcluida />
        </Match>
      </StoreSwitch>
    </Store.Provider>
  )
}

function SolicitacaoCriada() {
  const { Layout, LinkCobrancaContainer } = SolicitacaoCriada
  const { vistoria } = Store.useState()
  const dispatch = Store.useDispatch()
  const linkCobranca = `https://rep.hyperpay.com.br/cobranca/${encodeHashCobranca(
    vistoria.cobrancaId
  )}`

  function handleClickCopiarLinkCobranca() {
    navigator.clipboard.writeText(linkCobranca).then(
      () => {
        toast.success('Copiado com sucesso!')
      },
      () => {
        toast.error('Não foi possível copiar')
      }
    )
  }

  return (
    <Layout>
      <Heading>Solicitação de Vistoria Criada</Heading>
      <Subheading>Cobrança {vistoria?.cobrancaId}</Subheading>

      <LinkCobrancaContainer>
        <a href={linkCobranca}>{linkCobranca}</a>
        <Button onClick={handleClickCopiarLinkCobranca}>Copiar link de cobrança</Button>
      </LinkCobrancaContainer>

      <ReciboComponent selectedVistoria={vistoria} />

      <ButtonContainer>
        <ButtonCancelarVistoria />
        <Button onClick={() => dispatch(Store.actions.openPagamento())}>
          Ir para o ambiente de cobrança
        </Button>
      </ButtonContainer>
    </Layout>
  )
}
SolicitacaoCriada.Layout = styled.div`
  .comprovante-info-footer,
  .local-da-vistoriadora {
    display: none;
  }
`
SolicitacaoCriada.LinkCobrancaContainer = styled.div`
  padding: 48px;
  text-align: center;
  border: 1px solid #ccc;
  margin: 24px 24px 0 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  a {
    font-size: 1.3em;
  }
`

function ButtonCancelarVistoria() {
  const [isModalOpen, setModalOpen] = useState(false)

  const handleClickCancelamento = async () => {
    setModalOpen(true)
  }

  return (
    <>
      <ButtonCancelar onClick={handleClickCancelamento}>Cancelar Solicitação</ButtonCancelar>
      <Modal onClose={() => setModalOpen(false)} visible={isModalOpen} width={400}>
        <ModalCancelamentoSolicitacao close={() => setModalOpen(false)} />
      </Modal>
    </>
  )
}

function Pagamento() {
  const { vistoria, loadingCheckPagamento } = Store.useState()
  const dispatch = Store.useDispatch()
  const cobrancaEnv: any = process.env.REACT_APP_ENV
    ? process.env.REACT_APP_ENV
    : process.env.NODE_ENV

  useEffect(() => {
    if (vistoria?.cobrancaId) dispatch(Store.thunks.checkPagamentoVistoria(vistoria.id))
  }, [vistoria?.cobrancaId])

  const handleCheckPagamento = () => {
    if (loadingCheckPagamento) return
    dispatch(Store.thunks.checkPagamentoVistoria(vistoria.id))
  }
  const { ContainerValor, ContainerEcv, HyperpayCobranca } = Pagamento
  return (
    <div>
      <Heading>Seja bem vindo ao ambiente de pagamento Vistoria Fácil</Heading>
      <Subheading>Selecione a forma de pagamento desejada!</Subheading>
      <Subheading>Cobrança {vistoria?.cobrancaId}</Subheading>
      <ContainerValor>Valor: {formatValor(vistoria?.valorCobranca)}</ContainerValor>
      <ContainerEcv>{vistoria?.ecv?.nome}</ContainerEcv>
      <HyperpayCobranca
        cobrancaId={vistoria?.cobrancaId}
        token="asdfasdf"
        env={cobrancaEnv}
        onCobrancaSuccess={handleCheckPagamento}
      />
      <ButtonContainer>
        <ButtonCancelarVistoria />
        <ButtonWhatsapp onClick={() => handleClickWhatsapp(vistoria)}>
          Enviar via WhatsApp <img src={iconWhatsapp} />
        </ButtonWhatsapp>
      </ButtonContainer>
      <Subheading>
        Você tem até 30 dias a partir da data de emissão dessa solicitação para realizar o
        pagamento. Após essa data a solicitação será cancelada automaticamente.
      </Subheading>
    </div>
  )
}

Pagamento.ContainerValor = styled.div`
  margin-top: 15px;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  color: #2043a1;
`
Pagamento.Divider = styled.div`
  border-bottom: 1px solid #d4d4d4;
  max-width: 564px;
  margin: 0 auto;
  margin-top: 24px;
`
Pagamento.ContainerEcv = styled.div`
  font-size: 16px;
  color: #545451;
  text-align: center;
  font-weight: bold;
`
Pagamento.HyperpayCobranca = styled(HyperpayCobranca)`
  --primary-color: #ffbc01;
  --primary-color-shade: ${darken(0.1, '#FFBC01')};
  --titles-color: white;
  --text-color: #545451;

  font-family: 'Inter', 'Molde', sans-serif;

  .layout {
    border-radius: 8px;
  }
  .meio-pagamento-pix {
    border-radius: 8px 8px 0 0;
  }
  .meio-pagamento-cartao_credito {
    border-radius: 0 0 8px 8px;
  }
  button {
    font-weight: 600;
    height: 35px;
  }
  .heading-container {
    background: none;
    margin-top: 0;
    max-height: 500px;
    overflow-y: auto;
    .heading {
      display: none;
    }
  }
  .instrucao {
    display: none;
  }
  .title {
    font-weight: 600;
  }
  .codigo-seguranca {
    width: 145px !important;
  }
  .data-expiracao {
    width: 125px !important;
  }
  .email-titular {
    width: 280px !important;
  }
  .codigo-barras {
    max-width: calc(100vw - 40px);
  }
`

function StoreSwitch(props) {
  const state = Store.useState()
  const caseValue = props.case(state)
  const index = props.children.findIndex((x) => {
    if (x.type !== Match) return false
    return x.props.when === caseValue
  })
  if (index < 0 && !props.fallback) return null
  if (index < 0) return props.fallback
  return <>{props.children[index]}</>
}

function Match({ when, children }) {
  return children
}

const Heading = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  text-align: center;
  color: #122640;
  max-width: 490px;
  margin: 0 auto;
  margin-top: 14px;
  padding: 0 10px;
`
const Subheading = styled.div`
  color: #545451;
  font-size: 16px;
  text-align: center;
  margin-top: 14px;
`
const ButtonAgendar = styled(Button)(
  ({ disabled }) => `
  background-color: #FFBC01;
  color: #292926;
  border: ${disabled ? 'none' : '1px solid #FFBC01'};
  width: 201px;
  height: 44px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

`
)
const ContainerButtonAgendamento = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  grid-gap: 20px;
  @media print {
    display: none;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  padding: 24px;
  justify-content: center;
  gap: 16px;
  align-items: center;
  @media print {
    display: none;
  }
  @media (max-width: 480px) {
    flex-direction: column;
  }
`
// const Button = styled(Button)`
//   display: flex;
//   align-items: center;
//   gap: 10px;
// `
const ButtonWhatsapp = styled(Button)`
  border-color: #239c39;
  color: #239c39;
`
const ButtonCancelar = styled(Button)`
  border-color: #0d0e0f;
  color: #0d0e0f;
`

const ComprovanteAgendamento = () => {
  const dispatch = Store.useDispatch()
  const { vistoria, role } = Store.useState()

  function handleClickReagendar() {
    dispatch(Store.actions.openAgendamento())
  }

  return (
    <>
      <Heading>Agendamento confirmado</Heading>
      <Subheading>
        A partir de agora você já pode comparecer no local escolhido para realizar sua vistoria.
        <br />
        Procure chegar no horário agendado. O tempo máximo de tolerância para atraso é de no máximo
        30 minutos.
        <br />
        <ContainerButtonAgendamento>
          <ButtonAgendar onClick={handleClickReagendar}>Reagendar &gt;</ButtonAgendar>
        </ContainerButtonAgendamento>
        <ReciboComponent
          selectedVistoria={vistoria}
          shouldMascararInformacoesSensiveis={role === 'HOME'}
        />
        <ContainerButtonAgendamento>
          <ButtonAgendar onClick={handleClickReagendar}>Reagendar &gt;</ButtonAgendar>
        </ContainerButtonAgendamento>
      </Subheading>
    </>
  )
}

const ComprovantePagamento = () => {
  const dispatch = Store.useDispatch()
  const { vistoria, role } = Store.useState()

  const handleClickAgendamento = () => {
    dispatch(Store.actions.openAgendamento())
  }

  return (
    <React.Fragment>
      <Heading>Pagamento confirmado</Heading>
      <Subheading>
        A partir de agora você já pode agendar o horário no local escolhido para realizar sua
        vistoria quando quiser.
      </Subheading>
      <ContainerButtonAgendamento>
        <ButtonAgendar onClick={handleClickAgendamento}>Agendar &gt;</ButtonAgendar>
      </ContainerButtonAgendamento>

      <ReciboComponent
        selectedVistoria={vistoria}
        shouldMascararInformacoesSensiveis={role === 'HOME'}
      />

      <ContainerButtonAgendamento>
        <ButtonAgendar onClick={handleClickAgendamento}>Agendar &gt;</ButtonAgendar>
      </ContainerButtonAgendamento>
    </React.Fragment>
  )
}

const Agendamento = () => {
  const { vistoria } = Store.useState()
  const dispatch = Store.useDispatch()
  const [loading, setLoading] = useState(false)

  const handleAgendar = async (
    horarioSelecionadoId: number,
    horariosDisponiveis: Schemas.GetHorariosDisponiveisResponseDto[]
  ) => {
    if (loading) return
    if (horarioSelecionadoId === null) return toast.error('Selecione um horário!')
    const dataAgendamento: any = horariosDisponiveis[horarioSelecionadoId].inicio

    try {
      setLoading(true)
      await api.Vistorias.createAgendamento({ dataAgendamento }, { vistoriaId: vistoria.id })
      toast.success('Agendamento efetuado com sucesso!')
      dispatch(Store.actions.changeDataAgendamento(dataAgendamento))
      setLoading(false)
    } catch (error: any) {
      toast.error(error.response?.data?.message ?? 'Erro ao criar agendamento!')
      setLoading(false)
    }
  }

  return <AgendamentoComponent vistoria={vistoria} handleAgendar={handleAgendar} />
}

const ModalCancelamentoSolicitacao = ({ close }) => {
  const { Layout, SubHeading, Button, ButtonContainer } = ModalCancelamentoSolicitacao
  const { vistoria } = Store.useState()
  const [loading, setLoading] = useState(false)

  async function handleCancelarSolicitacao() {
    try {
      setLoading(true)
      const response: any = await api.Vistorias.cancelaVistoria({ vistoriaId: vistoria.id })
      if (response.success) {
        toast.success('Solicitação cancelada')
        close()
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      return toast.error('Falha ao cancelar solicitação')
    }
  }

  return (
    <Layout>
      <SubHeading>Tem certeza que deseja cancelar esta solicitação?</SubHeading>
      <ButtonContainer>
        <Button primary loading={loading} onClick={handleCancelarSolicitacao}>
          Sim
        </Button>
        <Button primary onClick={close}>
          Não
        </Button>
      </ButtonContainer>
    </Layout>
  )
}
ModalCancelamentoSolicitacao.Layout = styled.div`
  width: 100%;
  color: #545451;
`
ModalCancelamentoSolicitacao.Heading = styled.div`
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  color: #122640;
  text-align: center;
`
ModalCancelamentoSolicitacao.SubHeading = styled.div`
  font-size: 16px;
  color: #122640;
  text-align: center;
  margin-top: 16px;
`
ModalCancelamentoSolicitacao.Button = styled(Button)`
  width: 40%;
  margin-top: 24px;
  height: 36px;
  font-size: 15px;
  text-align: center;
`
ModalCancelamentoSolicitacao.ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`

const VistoriaConcluida = () => {
  return (
    <React.Fragment>
      <Heading>Vistoria concluída</Heading>
    </React.Fragment>
  )
}
