import React from 'react'
import { ReciboComponent } from './components/ReciboComponent'
import { NovaVistoriaStore as Store } from './NovaVistoriaStore'
import styled from '@emotion/styled'
import { Button } from '../../components/Button'
import { useNavigate } from 'react-router'

export const ComprovantePagamento = () => {
  const { vistoria } = Store.useState()

  return (
    <React.Fragment>
      <HeaderComprovantePagamento />
      <ReciboComponent selectedVistoria={vistoria} />
    </React.Fragment>
  )
}

const HeaderComprovantePagamento = () => {
  const { Layout, Title, SubTitle, ContainerButton } = HeaderComprovantePagamento
  const navigate = useNavigate()

  return (
    <Layout>
      <Title>Pagamento confirmado</Title>
      <SubTitle>
        A partir de agora você já pode agendar o horário no local escolhido para realizar sua
        vistoria quando quiser.
        <br />
        <br />
        <span className="aviso-comprovante-pagamento">
          Caso o veículo seja reprovado ou bloqueado na vistoria, o solicitante terá até 30 dias
          corridos para realizar uma nova solicitação gratuita.
          <br />
          Caso perca o prazo ou seja novamente reprovado, será necessário pagar uma nova vistoria.
        </span>
      </SubTitle>
      <ContainerButton>
        <Button primary onClick={() => navigate('/agendamento')}>
          Agendar vistoria
        </Button>
      </ContainerButton>
    </Layout>
  )
}
HeaderComprovantePagamento.Layout = styled.div`
  display: grid;
  margin: 0 auto;
  text-align: center;
  max-width: 900px;
  margin-top: 20px;
  justify-content: center;
  gap: 15px;
`
HeaderComprovantePagamento.Title = styled.span`
  color: #122640;
  font-size: 23px;
  font-weight: bold;
`

HeaderComprovantePagamento.SubTitle = styled.span`
  color: #292926;
  font-size: 15px;
  padding: 20px;
  .aviso-comprovante-pagamento {
    color: #ffbc10;
    font-weight: bold;
  }
`
HeaderComprovantePagamento.ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 5px;

  @media print {
    display: none;
  }
  @media (max-width: 480px) {
    flex-direction: column;
  }
`
