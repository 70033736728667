import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import {
  validateNomeRegex,
  validateEmailRegex,
  validateCpfCnpj,
  partialHideTelefone,
  partialHideNome,
  partialHideEmail,
  selectSvg,
  validateCapitalize,
} from './functions'
import styled from '@emotion/styled'
import { Button } from '../../components/Button'
import { InputLabel } from '../../components/InputLabel'
import { FormikProvider, useFormik, useFormikContext } from 'formik'
import InputMask from 'react-input-mask'
import { Schemas } from '../../components/ApiSchemas'
import { useApi, api } from '../../components/Api'
import toast from 'react-hot-toast'
import { CpfCnpjInput } from '../../components/CpfCnpjInput'
import { InputSenha } from '../../components/InputSenha'
import iconCheck from '../../assets/icon-check.svg'
import { NovaVistoriaStore as Store } from './NovaVistoriaStore'
import { AuthStore, getUsuarioLogado } from '../auth/AuthStore'
import { useOpenModal, GlobalStore } from '../../GlobalStore'
import { useNavigate } from 'react-router'
import { Modal } from '../../components/Modal'
import { createSimpleStore } from 'react-simple-reducer'
import { Show } from '../../components/Show'
import Select from 'react-select'

const initialValues = {
  nome: '',
  cpfCnpj: '',
  telefone: '',
  email: '',
  senha: '',
  confirmacaoSenha: '',
  checkConcordancia: false,
  existeUsuario: false,
}

const StoreDadosSolicitante = createSimpleStore(
  {
    finalizacaoCadastro: false,
    callFinalizeCadastro: false,
    cadastroPelaSolicitacao: false,
    loading: false,
    vistoriasExistentes: [] as Schemas.Vistoria[],
    showConcordancia: false,
    isCompletaCadastro: false,
    isConfirmaPlaca: false,
    isUsuarioComSenha: false,
    ecvId: null as number | null,
  },
  {
    getClienteIfExistStarted: (state) => ({
      ...state,
      loading: true,
    }),
    getClienteIfExistError: (state) => ({
      ...state,
      showConcordancia: false,
      vistoriasExistentes: [] as Schemas.Vistoria[],
      isCompletaCadastro: false,
      isUsuarioComSenha: false,
      loading: false,
    }),
    getClienteIfExistSuccess: (
      state,
      { vistoriasExistentes, isCompletaCadastro, isUsuarioComSenha }
    ) => ({
      ...state,
      loading: false,
      showConcordancia: true,
      vistoriasExistentes,
      isCompletaCadastro,
      isUsuarioComSenha,
    }),
    changeConfirmaPlaca: (state, isConfirmaPlaca) => ({
      ...state,
      isConfirmaPlaca,
    }),
    setParamsOnStore: (
      state,
      { finalizacaoCadastro, callFinalizeCadastro, cadastroPelaSolicitacao }
    ) => ({
      ...state,
      finalizacaoCadastro,
      callFinalizeCadastro,
      cadastroPelaSolicitacao,
    }),
    setIsUsuarioComSenha: (state, { isUsuarioComSenha }) => ({ ...state, isUsuarioComSenha }),
    changeEcvId(state, ecvId) {
      state.ecvId = ecvId
    },
  },
  {
    thunks: {
      getClienteIfExist: ({
        cpfCnpj,
        cadastroPelaSolicitacao,
        formik,
      }: {
        cpfCnpj: string
        cadastroPelaSolicitacao?: boolean
        formik: any
      }) => {
        return async (dispatch) => {
          try {
            if (!cpfCnpj || !validateCpfCnpj(cpfCnpj)) {
              await formik.setValues({ ...initialValues, cpfCnpj })
              return
            }
            dispatch(StoreDadosSolicitante.actions.getClienteIfExistStarted())
            const { cliente } = await api.Clientes.getIfExistCliente({
              params: { cpfCnpj },
              options: { manual: true },
            })
            const { vistorias } = cliente
            const formikNewValues = {
              ...formik.values,
              ...cliente,
              telefone: partialHideTelefone(cliente.telefone),
              email: partialHideEmail(cliente.email),
              nome: partialHideNome(cliente.nome),
              senha: '',
              checkConcordancia: true,
              existeUsuario: true,
            }
            await formik.setValues({ ...formikNewValues })
            dispatch(
              StoreDadosSolicitante.actions.getClienteIfExistSuccess({
                vistoriasExistentes: vistorias,
                isCompletaCadastro: !cliente.senha,
                ...(!cadastroPelaSolicitacao
                  ? { isUsuarioComSenha: !!cliente.senha }
                  : { isUsuarioComSenha: false }),
              })
            )
          } catch (error) {
            await formik.setValues({
              ...initialValues,
              cpfCnpj: formik.values.cpfCnpj,
            })
            dispatch(StoreDadosSolicitante.actions.getClienteIfExistError())
          }
        }
      },
    },
  }
)
type TState = ReturnType<typeof StoreDadosSolicitante.useState>
export const DadosSolicitante = ({
  fechar,
  finalizacaoCadastro,
  onSuccess,
  callFinalizeCadastro,
  cadastroPelaSolicitacao,
}: {
  fechar: () => void
  onSuccess: (response: { cliente: Schemas.Cliente; vistoria?: Schemas.Vistoria; cobranca? }) => any
  finalizacaoCadastro: boolean
  callFinalizeCadastro?: boolean
  cadastroPelaSolicitacao?: boolean
}) => {
  const { Layout, Divider, ContainerBotoes, DisplayFlex } = DadosSolicitante
  const { ecvs } = AuthStore.useState()
  const usuarioLogado = AuthStore.useSelector(getUsuarioLogado)
  const init = useCallback(function includeParamsOnStore(dispatch) {
    dispatch(
      StoreDadosSolicitante.actions.setParamsOnStore({
        finalizacaoCadastro,
        callFinalizeCadastro,
        cadastroPelaSolicitacao,
      })
    )
  }, [])

  return (
    <StoreDadosSolicitante.Provider init={init}>
      <Layout>
        <Form>
          <ShowContent when={(state) => !state.finalizacaoCadastro}>
            <DisplayFlex>
              <CpfCnpj />
              <Nome />
            </DisplayFlex>
          </ShowContent>
          <DisplayFlex>
            <Telefone />
            <Email />
          </DisplayFlex>
          <ShowContent when={(state) => state.finalizacaoCadastro}>
            <DisplayFlex>
              <Senha />
              <ConfirmarSenha />
            </DisplayFlex>
          </ShowContent>
          <ShowContent when={(state) => !state.finalizacaoCadastro}>
            <Show when={usuarioLogado && ecvs.length}>
              <ContainerECVSDisponiveis />
            </Show>
            <LimparFormulario />
            <ShowContent when={(state) => !state.showConcordancia && !usuarioLogado}>
              <Divider />
              <Concordancia />
            </ShowContent>
            <Show when={!usuarioLogado}>
              <ContainerIrParaLogin fechar={fechar} />
            </Show>
          </ShowContent>
          <ContainerBotoes>
            <Fechar fechar={fechar} />
            <Continuar onSuccess={onSuccess} />
          </ContainerBotoes>
        </Form>
        <ContainerModalUsuarioComSenha fechar={fechar} />
      </Layout>
    </StoreDadosSolicitante.Provider>
  )
}

const ShowContent = ({
  when,
  children,
}: {
  when: (state: TState) => boolean
  children: ReactNode
}): any => {
  const state = StoreDadosSolicitante.useState()
  return when(state) ? children : null
}

DadosSolicitante.Layout = styled.div`
  color: #545451;
`
const Form = ({ children }: { children: ReactNode }) => {
  const dispatchGlobalStore = GlobalStore.useDispatch()
  const dispatch = StoreDadosSolicitante.useDispatch()
  const { isCompletaCadastro, finalizacaoCadastro, callFinalizeCadastro, cadastroPelaSolicitacao } =
    StoreDadosSolicitante.useState()
  const { openModal } = useOpenModal()

  const validaNaoFinalizacaoCadastro = (errors, values) => {
    if (finalizacaoCadastro) return
    const { existeUsuario } = values
    if (!values.nome) errors.nome = 'Campo obrigatório'
    if (!values.cpfCnpj) errors.cpf = 'Campo obrigatório'
    if (!validateCpfCnpj(values.cpfCnpj)) errors.cpfCnpj = 'CPF/CNPJ inválido'
    if (values.cpfCnpj.length === 14 && validateCpfCnpj(values.cpfCnpj)) {
      if (values.nome && !validateNomeRegex({ value: values.nome, existeUsuario }))
        errors.nome = 'Nome inválido'
    }
  }
  const validaFinalizacaoCadastro = (errors, values) => {
    if (!finalizacaoCadastro) return
    if (!values.email) errors.email = 'Campo obrigatório'
    if (!values.senha) errors.senha = 'Campo obrigatório'
    if (!values.confirmacaoSenha) errors.confirmacaoSenha = 'Campo obrigatório'
    if (values.senha && values.senha.length < 6)
      errors.senha = 'A senha deve ter no mínimo 6 caracteres'
  }

  const validacaoPadrao = (errors, values) => {
    const { existeUsuario } = values
    if (
      values.telefone.replace(/[^0-9]/g, '').length &&
      values.telefone.replace(/[^0-9]/g, '').length < 11 &&
      !existeUsuario
    )
      errors.telefone = 'Um telefone válido deve possuir 11 dígitos'
    if (values.senha !== values.confirmacaoSenha) errors.confirmacaoSenha = 'Senhas não conferem'
    if (values.email && !validateEmailRegex({ value: values.email }))
      errors.email = 'Email inválido'
    if (values.senha && !values.confirmacaoSenha)
      errors.confirmacaoSenha = 'A confirmação de senha precisa ter uma senha'
    if (values.senha && values.senha.length < 6)
      errors.senha = 'A senha deve ter no mínimo 6 caracteres'
    if (values.confirmacaoSenha && values.confirmacaoSenha !== values.senha)
      errors.confirmacaoSenha = 'Senhas não conferem'
    if (values.senha && !values.email) errors.email = 'Para cadastrar senha tem que haver email'
  }

  const validate = (values: typeof initialValues) => {
    const errors: any = {}
    validaNaoFinalizacaoCadastro(errors, values)
    validaFinalizacaoCadastro(errors, values)
    validacaoPadrao(errors, values)
    return errors
  }

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: () => {},
  })

  const handleSuccessFinalizarCadastro = () => {
    dispatchGlobalStore(GlobalStore.actions.setGlobalCpfCnpj(formik.values.cpfCnpj))
    openModal('FINALIZACAO_CADASTRO')
  }

  useEffect(() => {
    if (!isCompletaCadastro || !callFinalizeCadastro) return
    handleSuccessFinalizarCadastro()
  }, [isCompletaCadastro])

  useEffect(
    function getCliente() {
      const getCliente = async () => {
        dispatch(
          StoreDadosSolicitante.thunks.getClienteIfExist({
            cpfCnpj: formik.values.cpfCnpj,
            cadastroPelaSolicitacao,
            formik,
          })
        )
      }
      getCliente()
    },
    [formik.values.cpfCnpj]
  )

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>{children}</form>
    </FormikProvider>
  )
}
DadosSolicitante.Divider = styled.div`
  margin-top: 32px;
  border-bottom: 1px solid #d4d4d4;
`
DadosSolicitante.Heading = styled.div`
  font-size: 24px;
  font-weight: bold;
  line-height: 120%;
  margin-top: 24px;
`
DadosSolicitante.ContainerBotoes = styled.div`
  margin-top: 48px;
  display: flex;
  gap: 24px;
  display: flex;
  justify-content: flex-end;
`
DadosSolicitante.DisplayFlex = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 16px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  & > * {
    flex: 1;
  }
`
const CpfCnpj = () => {
  const formik = useFormikContext<typeof initialValues>()
  const { loading } = StoreDadosSolicitante.useState()

  return (
    <CpfCnpjInput {...formik.getFieldProps('cpfCnpj')} data-cy="input-cpf-cnpj" loading={loading} />
  )
}

const Nome = () => {
  const formik = useFormikContext<typeof initialValues>()
  const [errors, setErrors] = useState<string[]>([])
  const { loading } = StoreDadosSolicitante.useState()

  const validaNomePessoaFisica = () => {
    if (formik.values.cpfCnpj.length === 14 && validateCpfCnpj(formik.values.cpfCnpj)) {
      validateNomeRegex({ value: formik.values.nome, setErrors })
    }
  }

  return (
    <InputLabel
      label="Nome/Razão Social *"
      disabled={formik.values.existeUsuario}
      {...formik.getFieldProps('nome')}
      data-cy="input-nome"
      errors={errors}
      onBlurCapture={() => validaNomePessoaFisica()}
      iconSvg={selectSvg(formik.values.nome, errors)}
      loading={loading}
    />
  )
}

const Telefone = () => {
  const formik = useFormikContext<typeof initialValues>()
  const { existeUsuario } = formik.values
  const { loading } = StoreDadosSolicitante.useState()

  const telefone = formik.values.telefone.replace(/[^0-9]/g, '')
  const errors =
    telefone && telefone.length < 11 && !existeUsuario
      ? ['Um telefone válido deve conter 11 dígitos']
      : []

  return (
    <InputMask
      disabled={formik.values.existeUsuario}
      mask="(99) 99999-9999"
      {...formik.getFieldProps('telefone')}
    >
      {(inputProps) => (
        <InputLabel
          iconSvg={telefone && telefone.length >= 10 && iconCheck}
          loading={loading}
          {...inputProps}
          disabled={formik.values.existeUsuario}
          errors={errors}
          label="Telefone"
          data-cy="input-telefone"
        />
      )}
    </InputMask>
  )
}

const Email = () => {
  const formik = useFormikContext<typeof initialValues>()
  const { values } = formik
  const [errors, setErrors] = useState<string[]>([])
  const { loading } = StoreDadosSolicitante.useState()

  const errorSenhaEmail =
    values.senha && !values.email ? ['Para cadastrar senha é necessário um email'] : ['']

  return (
    <InputLabel
      label="E-mail"
      {...formik.getFieldProps('email')}
      data-cy="input-email"
      onBlurCapture={() => validateEmailRegex({ value: formik.values.email, setErrors })}
      errors={[...errors, ...errorSenhaEmail]}
      iconSvg={selectSvg(formik.values.email, errors)}
      disabled={formik.values.existeUsuario}
      loading={loading}
    />
  )
}

const CheckboxCriarLogin = ({ showCriarLogin, setShowCriarLogin }) => {
  const { Layout } = CheckboxCriarLogin
  return (
    <Layout>
      <input
        type="checkbox"
        checked={showCriarLogin}
        onChange={() => setShowCriarLogin(!showCriarLogin)}
      />
      Deseja salvar dados para login?
    </Layout>
  )
}
CheckboxCriarLogin.Layout = styled.label`
  input {
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    width: 21px;
    height: 21px;
  }
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 24px;
`

const Senha = () => {
  const formik = useFormikContext<typeof initialValues>()
  const { values } = formik

  const errors =
    values.senha && values.senha.length < 6 ? ['A senha deve ter no mínimo 6 caracteres'] : []

  return (
    <InputSenha
      label="Senha"
      data-cy="input-senha"
      errors={errors}
      {...formik.getFieldProps('senha')}
    />
  )
}

const ConfirmarSenha = () => {
  const formik = useFormikContext<typeof initialValues>()
  const { values } = formik
  const errors =
    values.confirmacaoSenha && values.confirmacaoSenha !== values.senha
      ? ['Senhas não conferem']
      : []
  return (
    <InputSenha
      label="Confirmação de Senha"
      data-cy="input-confirmar-senha"
      errors={errors}
      {...formik.getFieldProps('confirmacaoSenha')}
    />
  )
}

const Fechar = ({ fechar }) => {
  return (
    <Button type="button" onClick={fechar}>
      Fechar
    </Button>
  )
}

const Continuar = ({
  onSuccess,
}: {
  onSuccess: (response: { cliente: Schemas.Cliente; vistoria?: Schemas.Vistoria; cobranca? }) => any
}) => {
  const { finalizacaoCadastro } = StoreDadosSolicitante.useState()
  const formik = useFormikContext<typeof initialValues>()
  const values = formik.values
  const { cpfCnpj } = GlobalStore.useState()
  const usuarioLogado = AuthStore.useSelector(getUsuarioLogado)
  const { ecvs } = AuthStore.useState()
  const {
    placa,
    vistoria: { veiculo, cliente, ecv },
  } = Store.useState()
  const dispatch = Store.useDispatch()
  const dispatchGlobalStore = GlobalStore.useDispatch()
  const { openModal } = useOpenModal()
  const navigate = useNavigate()
  // const [cadastroAdmin, setCadastroAdmin] = useState(false)

  // const bodyGetOrCreateCliente: Schemas.GetOrCreateClienteDto = {
  //   cpfCnpj: values.cpfCnpj,
  //   nome: validateCapitalize(values.nome),
  //   email: values.email.toLowerCase(),
  //   senha: values.senha,
  //   telefone: values.telefone,
  // }

  const [loading, setLoading] = useState(false)

  // const bodyFinalizaCadastro: Schemas.FinalizaCadastroDto = {
  //   cpfCnpj: cpfCnpj,
  //   telefone: values.telefone,
  //   email: values.email.toLowerCase(),
  //   senha: values.senha,
  //   confirmarSenha: values.confirmacaoSenha,
  // }

  // const [{ loading }, req] = useApi.Clientes.getOrCreateCliente(bodyGetOrCreateCliente, {
  //   options: { manual: true },
  // })
  // const [{ data, loading: loadingVistoria }, reqCreateVistoria] = useApi.Vistorias.createVistoria(
  //     {
  //       clienteId: cliente?.id ?? 0,
  //       ecvId: ecv?.id ?? 0,
  //       veiculoId: veiculo?.id ?? 0,
  //     },
  //     { options: { manual: true } }
  //   )

  const handleSubmit = async () => {
    // if (
    //   (values.senha && values.senha.length < 6) ||
    //   (values.senha && values.senha.length < 6 && finalizacaoCadastro)
    // )
    //   return toast.error('A senha deve ter no mínimo 6 caracteres')

    // if (usuarioLogado?.isAdmin) {
    //   try {
    //     const responseGetOrCreateCliente = await req().then((x) => x?.data)
    //     dispatch(Store.actions.getOrCreateClienteSuccess(responseGetOrCreateCliente.cliente))
    //     if (placa && !veiculo.id)
    //       dispatch(
    //         Store.thunks.getVeiculo({
    //           placa,
    //           cpfCnpj: responseGetOrCreateCliente.cliente.cpfCnpj,
    //         })
    //       )

    //     setCadastroAdmin(true)
    //     return toast.success('Cliente cadastrado com sucesso!')
    //   } catch (error: any) {
    //     const message = error.response?.data?.message ?? 'Erro ao fazer cadastro'
    //     toast.error(message)
    //   }
    // }

    if (!values.checkConcordancia && !finalizacaoCadastro && !usuarioLogado) {
      return toast.error('Você deve concordar com os termos de Política e Privacidade')
    }

    if (usuarioLogado && ecvs.length && !ecv) return toast.error('Selecione uma ECV')
    if (formik.values.cpfCnpj) {
      dispatchGlobalStore(GlobalStore.actions.setGlobalCpfCnpj(formik.values.cpfCnpj))
    }

    setLoading(true)
    try {
      const cliente = await api.Clientes.getOrCreateCliente({
        cpfCnpj: values.cpfCnpj,
        nome: validateCapitalize(values.nome),
        email: values.email.toLowerCase(),
        senha: values.senha,
        telefone: values.telefone,
      })

      if (!ecv?.id) onSuccess({ cliente })

      const veiculo = await api.Veiculos.getOrCreateVeiculo({ placa })
      dispatch(Store.actions.getOrCreateVeiculoSuccess(veiculo))

      const { vistoria, cobranca } = await api.Vistorias.createVistoria({
        clienteId: cliente.id,
        ecvId: ecv.id,
        veiculoId: veiculo.id,
      })
      onSuccess({ cliente: cliente, vistoria, cobranca })

      // if (finalizacaoCadastro) {
      //   openModal('LOGIN')
      //   dispatch(Store.actions.clear())
      // }
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro'
      toast.error(message)
    } finally {
      setLoading(false)
    }
  }

  // const { ecvIdSelecionada, token } = AuthStore.useState()
  // const [{ data: ecv }] = useApi.Ecvs.getEcv({ id: ecvIdSelecionada ?? 0 })
  // const [{ data, loading: loadingVistoria }, reqCreateVistoria] = useApi.Vistorias.createVistoria(
  //   {
  //     clienteId: cliente?.id ?? 0,
  //     ecvId: ecv?.id ?? 0,
  //     veiculoId: veiculo?.id ?? 0,
  //   },
  //   { options: { manual: true } }
  // )

  // const handleContinuarPagamento = async () => {
  //   if (loading) return
  //   try {
  //     const responseReqCreateVistoria = await reqCreateVistoria().then((x) => x.data)

  //     if (responseReqCreateVistoria) {
  //       dispatch(Store.actions.createVistoriaSuccess(responseReqCreateVistoria))
  //       navigate('/pagamento')
  //     }
  //   } catch (error: any) {
  //     const message =
  //       error?.response?.data?.message ?? 'Ocorreu um erro ao prosseguir para pagamento'
  //     toast(message)
  //   }
  // }

  // if (cadastroAdmin) {
  //   return (
  //     <Button
  //       onClick={handleContinuarPagamento}
  //       primary
  //       data-cy="btn-continuar-pagamento"
  //       loading={loadingVistoria}
  //     >
  //       Continuar com pagamento
  //     </Button>
  //   )
  // }

  return (
    <Button
      type="submit"
      primary
      disabled={!formik.isValid}
      loading={loading}
      onClick={handleSubmit}
      data-cy="btn-continuar"
    >
      Cadastrar
    </Button>
  )
}

const Concordancia = () => {
  const { Container, Flex } = Concordancia
  const formik = useFormikContext()

  return (
    <Container>
      <Flex>
        <InputCheckBox
          id="concordancia-input"
          type="checkbox"
          {...formik.getFieldProps('checkConcordancia')}
          data-cy="input-concordancia"
        />
        <label htmlFor="concordancia-input">
          Eu li, estou ciente das condições de tratamento dos meus dados pessoais e dou meu
          consentimento, quando aplicável, conforme descrito nesta
          <a href="/politica-de-privacidade" target="_blank">
            {' '}
            Politica de Privacidade.
          </a>
        </label>
      </Flex>
    </Container>
  )
}

Concordancia.Container = styled.div`
  margin-top: 9px;
`
Concordancia.Flex = styled.div`
  display: flex;
  align-items: center;
  label {
    font-size: 13.5px;
    margin-left: 8px;
    cursor: pointer;
  }
  a {
    color: #2043a1;
    cursor: pointer;
    text-decoration: none;
  }
`

const InputCheckBox = styled.input`
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  width: 21px;
  height: 21px;
  cursor: pointer;
`

const LimparFormulario = ({ ...props }) => {
  const formik = useFormikContext<typeof initialValues>()
  const { Container } = LimparFormulario
  const handleClearFormikValues = () => formik.setValues({ ...initialValues })
  return (
    <Container onClick={handleClearFormikValues} {...props}>
      <span>Limpar Formulário</span>
    </Container>
  )
}

LimparFormulario.Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  span {
    color: #2043a1;
    cursor: pointer;
    font-size: 13.3px;
  }
`
const StyleOptionUser = styled.div`
  margin-top: 24px;
  color: #aaaaa8;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  a {
    margin-top: 8px;
    color: #2043a1;
    display: block;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
  }
`
const ContainerModalUsuarioComSenha = ({ fechar }: { fechar: () => void }) => {
  const { isUsuarioComSenha } = StoreDadosSolicitante.useState()
  const dispatch = StoreDadosSolicitante.useDispatch()
  const { openModal } = useOpenModal()
  return (
    <Modal
      width={500}
      visible={isUsuarioComSenha}
      data-cy="modal-usuario-com-senha"
      onClose={() => {
        dispatch(StoreDadosSolicitante.actions.setIsUsuarioComSenha(false))
        openModal('')
      }}
    >
      <StyleOptionUser>
        <div>
          <img width={50} src={iconCheck} />
        </div>
        Você ja possui cadastro!
        <a
          onClick={() => {
            fechar()
            openModal('LOGIN')
          }}
        >
          Faça o login clicando aqui!
        </a>
      </StyleOptionUser>
    </Modal>
  )
}

const ContainerIrParaLogin = ({ fechar }: { fechar: () => void }) => {
  const { openModal } = useOpenModal()
  return (
    <StyleOptionUser>
      É um <strong>despachante</strong> ou <strong>ECV</strong>?
      <a
        onClick={() => {
          fechar()
          openModal('LOGIN')
        }}
      >
        Faça o login agora!
      </a>
      {/* <span style={{ marginTop: 4 }}>ou</span>
      <a href="/cadastro">Faça o cadastro</a> */}
    </StyleOptionUser>
  )
}

const ContainerECVSDisponiveis = () => {
  const { Layout } = ContainerECVSDisponiveis
  const { ecvs } = AuthStore.useState()
  const {
    vistoria: { ecv },
  } = Store.useState()
  const dispatch = Store.useDispatch()

  useEffect(() => {
    if (ecvs.length === 1) dispatch(Store.actions.setEcvToVistoria(ecvs[0]))
  }, [ecvs])

  const styles = {
    control: (base) => ({
      ...base,
      fontSize: '14px',
    }),
    menu: (base) => ({
      ...base,
      fontSize: '14px',
    }),
  }

  function changeInput(option) {
    const selectedEcv = ecvs.find((x) => x.id === +option.value) as Schemas.Ecv
    dispatch(Store.actions.setEcvToVistoria(selectedEcv))
  }

  const options = ecvs.map((ecv) => ({
    value: ecv.id,
    label: `${ecv.nome} - ${ecv.municipio}`,
  })) as any

  const label = !ecv ? <em>Selecione uma ECV</em> : `${ecv?.nome} - ${ecv?.municipio}`

  return (
    <Layout>
      <label>
        <span>ECV</span>
        <Select
          styles={styles}
          options={options}
          onChange={changeInput}
          value={{
            value: ecv?.id,
            label,
          }}
        />
      </label>
    </Layout>
  )
}
ContainerECVSDisponiveis.Layout = styled.div`
  label span {
    font-size: 14px;
    line-height: 120%;
    color: #545451;
    margin-top: 12px;
    margin-bottom: 8px;
    display: block;
  }
`
