import React from 'react'
import styled from '@emotion/styled'
import iconUserWhite from '../assets/icon-user-white.svg'
import iconUserBlack from '../assets/icon-user-black.svg'
import iconChevronDownWhite from '../assets/icon-chevron-down-white.svg'
import iconChevronDownBlack from '../assets/icon-chevron-down-black.svg'
import { AuthStore, getUsuarioLogado } from '../domains/auth/AuthStore'
import { useNavigate } from 'react-router'
import { useOpenModal } from '../GlobalStore'

export const UsuarioLogado = ({
  aplicacao,
  ...props
}: {
  aplicacao: 'preto' | 'branco'
} & React.HTMLAttributes<HTMLDivElement>) => {
  const { Layout } = UsuarioLogado
  const usuarioLogado = AuthStore.useSelector(getUsuarioLogado)
  const cor = { preto: '#022859', branco: '#ffffff' }[aplicacao]
  const corInvertida = { preto: '#ffffff', branco: '#122640' }[aplicacao]
  const navigate = useNavigate()
  const dispatch = AuthStore.useDispatch()
  const { openModal } = useOpenModal()

  function handleLogout() {
    dispatch(AuthStore.actions.logout())
  }

  function handleVisualizarSolicitacoes() {
    navigate('/adm')
  }

  if (!usuarioLogado) {
    return (
      <>
        <Layout
          data-cy="btn-login-cadastro"
          cor={cor}
          corInvertida={corInvertida}
          {...props}
          usuarioLogado={false}
          onClick={() => openModal('LOGIN')}
        >
          <div className="usuario-logado-container">
            <Img icon="user" cor={aplicacao} /> Login
          </div>
        </Layout>
      </>
    )
  }

  return (
    <Layout
      cor={cor}
      corInvertida={corInvertida}
      usuarioLogado={true}
      {...props}
    >
      <div className="usuario-logado-container">
        <Img icon="user" cor={aplicacao} />
        <span>{usuarioLogado.nome}</span>
        <Img icon="chevron" cor={aplicacao} />
      </div>
      <div className="usuario-logado-container-user-options">
        <div onClick={() => handleVisualizarSolicitacoes()}>
          Visualizar Solicitações
        </div>
        <div onClick={() => handleLogout()}>Sair</div>
      </div>
    </Layout>
  )
}
UsuarioLogado.Layout = styled.div<{ cor; corInvertida; usuarioLogado }>(
  ({ cor, corInvertida, usuarioLogado }) => `
  position: relative;
  height: 35px;
  .usuario-logado-container {
    transition: background-color 0.17s ease-in-out;
    border: 1px solid;
    border-radius: 8px;
    height: 35px;
    padding: 0 18px;
    display: flex;
    gap: 6px;
    align-items: center;
    cursor: pointer;
    color: ${cor};
    border-color: ${cor};
    background: ${corInvertida === '#ffffff' ? corInvertida : 'transparent'};
    white-space: nowrap;
    &:hover {
      ${ternary(
        !usuarioLogado,
        `
        background: ${cor};
        color: ${corInvertida};
        img {
          filter: invert(1);
        }
      `
      )}
    }
    @media (max-width: 600px) {
      span {
        display: none;
      }
    }
  }

  .usuario-logado-container-user-options {
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 100%;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    &>div {
      transition: background 0.22s ease-in-out;
      :first-of-type, :last-of-type {
        border-bottom: 1px solid ${cor};
        border-left: 1px solid ${cor};
        border-right: 1px solid ${cor};
      }
      padding: 12px;
      cursor: pointer;
      text-align: right;
      background: ${corInvertida === '#ffffff' ? corInvertida : 'transparent'};
      color: ${cor};
      &:hover {
        background: ${cor};
        color: ${corInvertida};
        font-weight: 600;
      }
      &:last-child {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      @media (max-width: 600px) {
        background: ${corInvertida};
      }
    }
  }

  &:hover {
    .usuario-logado-container {
      ${ternary(
        usuarioLogado,
        `
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      `
      )}
    }

    .usuario-logado-container-user-options {
      ${usuarioLogado ? 'display: block;' : ''}
      opacity: 1;
    }
  }
`
)

const Img = ({ icon, cor }: { icon: 'user' | 'chevron'; cor }) => {
  const usedIcon = {
    user: {
      preto: iconUserBlack,
      branco: iconUserWhite,
    },
    chevron: {
      preto: iconChevronDownBlack,
      branco: iconChevronDownWhite,
    },
  }[icon][cor]
  return <img src={usedIcon} />
}

function ternary(condition, string) {
  return condition ? string : ''
}
