import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Modal } from '../../components/Modal'
import { GlobalStore, useOpenModal } from '../../GlobalStore'
import { InputLabel } from '../../components/InputLabel'
import { Button } from '../../components/Button'
import { DadosSolicitante } from '../home/DadosSolicitante'
import { Schemas } from '../../components/ApiSchemas'
import { AuthStore } from '../auth/AuthStore'
import toast from 'react-hot-toast'

export const ModalFinalizacaoCadastro = () => {
  return null
}
// export const ModalFinalizacaoCadastro = () => {
//   const { modalOpen } = GlobalStore.useState()
//   const { Layout, Title, Subtitle, Divider, Heading } = ModalFinalizacaoCadastro
//   const authDispatch = AuthStore.useDispatch()
//   const { openModal, closeModal } = useOpenModal()

//   function handleSuccessCadastroSolicitante(response: Schemas.GetOrCreateClienteResponseDto) {
//     // authDispatch(AuthStore.actions.loginSuccess({ token: response.token }))
//     toast.success('Cadastro efetuado com sucesso!')
//     closeModal()
//   }

//   return (
//     <Modal
//       visible={modalOpen === 'FINALIZACAO_CADASTRO'}
//       width={696}
//       onClose={() => closeModal()}
//       data-cy="modal-finaliza-cadastro"
//     >
//       <Layout>
//         <Title>Completar Cadastro</Title>
//         <Subtitle>Você precisa completar o cadastro para ter acesso ao sistema</Subtitle>
//         <Divider />
//         <DadosSolicitante
//           fechar={() => closeModal()}
//           onSuccess={handleSuccessCadastroSolicitante}
//           finalizacaoCadastro={true}
//         />
//       </Layout>
//     </Modal>
//   )
// }
// ModalFinalizacaoCadastro.Layout = styled.div``
// ModalFinalizacaoCadastro.Title = styled.div`
//   font-weight: bold;
//   font-size: 32px;
//   line-height: 120%;
//   color: #122640;
//   text-align: center;
// `
// ModalFinalizacaoCadastro.Subtitle = styled.div`
//   font-size: 14px;
//   line-height: 120%;
//   color: #636c74;
//   text-align: center;
//   margin-top: 12px;
// `
// ModalFinalizacaoCadastro.Divider = styled.div`
//   margin-top: 32px;
//   border-bottom: 1px solid #d4d4d4;
// `
// ModalFinalizacaoCadastro.Heading = styled.div`
//   font-size: 24px;
//   font-weight: bold;
//   line-height: 120%;
//   margin-top: 24px;
//   color: #545451;
// `
// ModalFinalizacaoCadastro.ButtonFechar = styled(Button)`
//   border: none;
// `
