import * as forge from 'node-forge'

export function createKeyPemPairUser() {
  const { privateKey, publicKey } = forge.pki.rsa.generateKeyPair(1024)
  const privateKeyPem = forge.pki.privateKeyToPem(privateKey)
  const publicKeyPem = forge.pki.publicKeyToPem(publicKey)
  return { publicKeyPem, privateKeyPem }
}

export function encryptMessage(message: string, publicKeyPem: string) {
  const publicKey = forge.pki.publicKeyFromPem(publicKeyPem)
  const encryptedMessage = publicKey.encrypt(encodeURIComponent(message), 'RSA-OAEP')
  return btoa(encryptedMessage)
}

export function decryptMessage(encryptedMessage: string, privateKeyPem: string) {
  const privateKey = forge.pki.privateKeyFromPem(privateKeyPem)
  const decodedMessage = forge.util.decode64(encryptedMessage)

  const decryptedMessage = privateKey.decrypt(decodedMessage, 'RSA-OAEP')

  return decodeURIComponent(decryptedMessage)
}
