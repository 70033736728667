import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { NovaVistoriaContainer } from './nova-vistoria/NovaVistoriaContainer'
import { NovaVistoriaStore } from './NovaVistoriaStore'
import 'react-calendar/dist/Calendar.css'
import { api, useApi } from '../../components/Api'
import toast from 'react-hot-toast'
import { AuthStore } from '../auth/AuthStore'
import { AgendamentoComponent } from './components/AgendamentoComponent'
import { Schemas } from '../../components/ApiSchemas'
import { Show } from '../../components/Show'
import { ReciboComponent } from './components/ReciboComponent'

export const Agendamento = () => {
  const [loading, setLoading] = useState(false)
  const { vistoria } = NovaVistoriaStore.useState()
  const dispatch = NovaVistoriaStore.useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (!vistoria?.dataPagamentoCobranca) navigate('/pagamento')
    // if (vistoria.dataAgendamento) navigate('/comprovante-agendamento')
  }, [])

  const handleAgendar = async (
    horarioSelecionadoId: number,
    horariosDisponiveis: Schemas.GetHorariosDisponiveisResponseDto[]
  ) => {
    if (loading) return
    if (horarioSelecionadoId === null) return toast.error('Selecione um horário!')
    const dataAgendamento: any = horariosDisponiveis[horarioSelecionadoId].inicio

    try {
      setLoading(true)
      await api.Vistorias.createAgendamento({ dataAgendamento }, { vistoriaId: vistoria.id })
      dispatch(NovaVistoriaStore.actions.efetuarAgendamentoSuccess(dataAgendamento))
      toast.success('Agendamento efetuado com sucesso!')
      setLoading(false)
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Erro ao criar agendamento!')
      setLoading(false)
    }
  }

  return (
    <NovaVistoriaContainer passoAtual={5}>
      <Show when={!!vistoria.dataAgendamento}>
        <ReciboComponent selectedVistoria={vistoria} />
      </Show>
      <Show when={!vistoria.dataAgendamento}>
        <AgendamentoComponent vistoria={vistoria} handleAgendar={handleAgendar} />
      </Show>
    </NovaVistoriaContainer>
  )
}
