import React, { useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { Button } from './Button'
import { Select } from './Select'
import { useWindowWidth } from './hooks/use-window-width'

export const Paginacao = ({ total, limitPage, paginaAtual = 1, handlePageActive }:
{ total: number, limitPage: number, paginaAtual: number, handlePageActive: (page, newLimit?) => void }) => {
  const { Layout, ContainerButtons, ButtonAnterior, ButtonProxima } = Paginacao

  const handleActivePage = ({ page, newLimit }: { page: number, newLimit?: number }) => {
    handlePageActive({ page, newLimit })
  }

  if (!total) return null

  return (
    <Layout>
      <SelectLimitPage
        limitPage={limitPage}
        handleActivePage={handleActivePage}
      />
      <ContainerButtons>
        <ButtonAnterior
          onClick={() => {
            if (paginaAtual === 1) return
            handleActivePage({ page: paginaAtual - 1 })
          }}
        >
          Anterior
        </ButtonAnterior>
        <ContentPages
          total={total}
          limitPage={limitPage}
          paginaAtual={paginaAtual}
          handleActivePage={handleActivePage}
        />
        <ButtonProxima
          onClick={() => {
            const totalPages = Math.ceil(total / limitPage)
            if (paginaAtual === totalPages) return
            handleActivePage({ page: paginaAtual + 1 })
          }}
        >
          Próxima
        </ButtonProxima>
      </ContainerButtons>
    </Layout>
  )
}

Paginacao.Layout = styled.div`
  padding: 13px;
  display: flex;
  margin-top: 10px;
  justify-content: center;
`

const SelectLimitPage = ({ limitPage, handleActivePage }) => {
  const { Layout } = SelectLimitPage
  return (
    <Layout>
      <Select
        value={limitPage}
        onChange={(e) => {
          handleActivePage({ newLimit: e.target.value, page: 1 })
        }}
      >
        <option value={15}>15</option>
        <option value={25}>25</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </Select>
    </Layout>
  )
}

SelectLimitPage.Layout = styled.div`
  padding: 10px;
  min-width: 100px;
`

Paginacao.ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`
Paginacao.ButtonAnterior = styled(Button)`
  border: none;
`
Paginacao.ButtonProxima = styled(Button)`
 border: none;
`

const ContentPages = ({ paginaAtual, handleActivePage, total, limitPage }) => {
  const { Layout } = ContentPages

  const range = (start, end) => {
    const arr: number[] = []
    for (let i = start; i <= end; i++) {
      arr.push(i)
    }
    return arr
  }
  const start = Math.max(1, paginaAtual - 3)
  const end = Math.min(Math.ceil(total / limitPage), start + 7)
  return (
    <Layout>
      {range(start, end).map(index => (
        <Page
          key={index}
          paginaAtual={paginaAtual}
          handleActivePage={handleActivePage}
          page={index}
          finalPage={paginaAtual === Math.ceil(total / limitPage)}
        />
      ))}
    </Layout>
  )
}

ContentPages.Layout = styled.div`
  display: flex;
  gap: 3px;
  max-width: 180px;
  overflow: hidden;
  @media(max-width: 600px){
    max-width: 70px;
  }
`

const Page = ({ paginaAtual, handleActivePage, page, finalPage }:
{ page: number, handleActivePage, paginaAtual, finalPage }) => {
  const { Layout } = Page
  const isMobile = useWindowWidth() < 600

  return (
    <Layout
      onClick={() => handleActivePage({ page })}
      active={paginaAtual === page}
    >
      {page}
    </Layout>
  )
}

Page.Layout = styled.div<{ active: boolean }>(({ active }) => `
  min-width: 30px;
  height: 30px;
  padding: 3px;
  cursor: pointer;
  background-color: #FFFFFF;
  color: #122640;
  border: 1px solid #122640;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.26s ease-in-out;
  ${active && `
    background-color: #122640;
    color: #FFFFFF;
    font-weight: bold;
  `}
`)

function getMarginSpace (page: number) {
  const widthContentPage = 300
  const widthPage = 33
  if (page === Math.floor(widthContentPage / widthPage)) {
    console.log('dar espaçamento')
  }
}
