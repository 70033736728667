import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Schemas } from '../../../components/ApiSchemas'
import logo from '../../../assets/logo-invertida.svg'
import { format, formatDistance } from 'date-fns'
import { Button } from '../../../components/Button'
import iconPrinter from '../../../assets/icon-printer.svg'
import iconWhatsapp from '../../../assets/icon-whatsapp.svg'
import iconNotaFiscal from '../../../assets/icon-notafiscal.svg'
import { Show } from '../../../components/Show'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { useWindowWidth } from '../../../components/hooks/use-window-width'
import { api } from '../../../components/Api'
import { Modal } from '../../../components/Modal'
import { NFComponent } from './NFComponent'
import { getMeioPagamento } from '../functions'
import { ptBR } from 'date-fns/locale'
import { encodeHashCobranca } from '@hyperdev-tech/hash-cobranca'

function handleClickImprimir() {
  window.print()
}

export function handleClickWhatsapp(vistoria: Schemas.Vistoria) {
  let msg

  if (!vistoria.dataPagamentoCobranca) {
    const linkCobranca = `https://rep.hyperpay.com.br/cobranca/${encodeHashCobranca(
      vistoria.cobrancaId
    )}`
    msg = `✅ Olá! Sua solicitação Nº *${vistoria.id}* foi aberta com sucesso no Vistoria Fácil no veículo de placa *${vistoria.veiculo.placa}* e ECV *${vistoria.ecv.nome}* ✅ \n\n`
    msg +=
      'A partir de agora você já pode realizar o pagamento. Você tem até 30 dias a partir da data de emissão dessa solicitação para realizar o pagamento. \n\n'
    msg += 'Após essa data a solicitação será finalizada automaticamente.\n\n'
    msg += `Utilize esse link seguro para efetuar o pagamento ${linkCobranca}`
  }

  if (vistoria.dataPagamentoCobranca && !vistoria.dataAgendamento) {
    msg = `✅ Olá! Sua solicitação Nº *${vistoria.id}* está com o pagamento realizado com sucesso no Vistoria Fácil! ✅ \n\n`

    if (vistoria.ecv.tipoAtendimento === 'ORDEM_CHEGADA') {
      msg += `${vistoria.dataPagamentoCobranca} A partir de agora você já pode comparecer no local escolhido para realizar sua vistoria. A *${vistoria.ecv.nome}*  está localizada no endereço *${vistoria.ecv.endereco}, ${vistoria.ecv.bairro}, ${vistoria.ecv.municipio}-${vistoria.ecv.uf}*.\n\n`
    }

    if (vistoria.ecv.tipoAtendimento === 'AGENDAMENTO' && !vistoria.dataAgendamento) {
      msg +=
        'A partir de agora você já pode agendar o horário no local escolhido para realizar sua vistoria quando quiser. \n\n'
    }

    msg +=
      'Caso a vistoria seja reprovada, você terá até 30 dias corridos para realizar uma nova solicitação gratuita. \n\n'
    msg += 'Caso perca o prazo ou seja reprovado, será necessário iniciar uma nova solicitação.'
  }

  if (vistoria.dataAgendamento && !vistoria.dataConclusao) {
    msg = `✅ Olá! Sua solicitação Nº *${
      vistoria.id
    }* está agendada no Vistoria Fácil no Vistoria Fácil! em ${format(
      new Date(vistoria.dataAgendamento),
      'dd/MM/yyyy HH:mm'
    )} ✅ \n\n`
    msg += `A partir de agora você já pode comparecer no local escolhido para realizar sua vistoria. A *${vistoria.ecv.nome}* está localizada no endereço *${vistoria.ecv.endereco}, ${vistoria.ecv.bairro}, ${vistoria.ecv.municipio}-${vistoria.ecv.uf}*.\n\n`
    msg +=
      'Procure chegar no horário agendado. O veículo será recepcionado com uma tolerância máxima de 30 minutos para atrasos. \n\n'
    msg +=
      'Caso o veículo seja reprovado ou bloqueado na vistoria, o solicitante terá até 30 dias corridos para realizar uma nova solicitação gratuita. \n\n'
    msg +=
      '*Caso perca o prazo ou seja novamente reprovado, será necessário pagar uma nova vistoria.*'
  }

  if (vistoria.dataConclusao) {
    msg = `✅ Olá *${vistoria.cliente.nome}* \n\n`
    msg += 'Sua vistoria foi *aprovada* ✅ \n\n'
    msg += 'Agradecemos a sua preferência!'
  }

  msg = window.encodeURIComponent(msg)
  window.open(`https://api.whatsapp.com/send?text=${msg}`)
}

function handleClickLocalizacao(vistoria: Schemas.Vistoria) {
  window.open(
    `https://www.google.com/maps/dir/?api=1&destination=${vistoria.ecv.latitude}%20${vistoria.ecv.longitude}`
  )
}

const getFormatDataAgendamento = (vistoria) =>
  vistoria.dataAgendamento ? format(new Date(vistoria.dataAgendamento), 'dd/MM/yyyy HH:mm:ss') : ''
const getFormatDistance = (data) =>
  !data ? '' : formatDistance(new Date(data), new Date(), { addSuffix: true, locale: ptBR })
const getFormatdataEmissao = (vistoria) =>
  format(new Date(vistoria.createdAt), 'dd/MM/yyyy HH:mm:ss')
const getFormatdataPagamento = (vistoria) =>
  vistoria.dataPagamentoCobranca
    ? format(new Date(vistoria.dataPagamentoCobranca), 'dd/MM/yyyy HH:mm:ss')
    : ''

export const ReciboComponent = ({
  selectedVistoria,
  shouldMascararInformacoesSensiveis,
}: {
  selectedVistoria: Schemas.Vistoria
  shouldMascararInformacoesSensiveis?: boolean
}) => {
  const {
    Layout,
    Flex,
    Logo,
    ContainerValor,
    ContainerComprovante,
    ContainerButtonAgendamento,
    ButtonAgendamento,
    ContainerBotoes,
    Button,
    ButtonWhatsapp,
  } = ReciboComponent

  const [modalNf, setModalNf] = useState(false)
  const [loading, setLoading] = useState(false)
  const [notafiscal, setNotaFiscal] = useState<Schemas.NotaFiscal | null>()

  const navigate = useNavigate()
  const isMobile = useWindowWidth() <= 900
  const meioPagamento = getMeioPagamento(selectedVistoria)

  async function handleClickNotaFiscal() {
    try {
      setLoading(true)
      const { success, data } = await api.Vistorias.getNF({ idVistoria: selectedVistoria.id })
      if (success && !data) {
        setLoading(false)
        return window.open(`nota-fiscal/${selectedVistoria.idNotaFiscal}/pdf`)
      }
      setModalNf(true)
      setNotaFiscal(data)
      setLoading(false)
    } catch (error: any) {
      const messageError = error?.response?.data?.message ?? 'Erro ao gerar nota fiscal'
      toast(messageError)
      setLoading(false)
    }
  }

  const handleClickAlterarAgendamento = async () => {
    // try {
    //   if (!selectedVistoria.dataPagamentoCobranca) {
    //     const vistoriaComPagamento = await api.Vistorias.checkPagamento({
    //       vistoriaId: selectedVistoria.id,
    //     })
    //     // dispatch(NovaVistoriaStore.actions.checkPagamentoSuccess(vistoriaComPagamento))
    //   }
    //   navigate('/agendamento')
    // } catch (error: any) {
    //   toast.error(error.response?.data?.message || 'Erro ao cancelar agendamento!')
    // }
  }

  async function handleCancelarSolicitacao() {
    // try {
    //   setLoading(true)
    //   const response: any = await api.Vistorias.cancelaVistoria({ vistoriaId: selectedVistoria.id })
    //   if (response.success) {
    //     navigate('/adm')
    //     return toast.success('Solicitação cancelada')
    //   }
    // } catch (error) {
    //   return toast.error('Falha ao cancelar solicitação')
    // } finally {
    //   setLoading(false)
    // }
  }

  return (
    <Layout data-cy="recibo-component">
      <ContainerComprovante>
        <Flex>
          <Logo src={logo} />
          <DadosCobranca
            selectedVistoria={selectedVistoria}
            dataEmissao={getFormatdataEmissao(selectedVistoria)}
          >
            <Show when={!!selectedVistoria.dataPagamentoCobranca}>
              <div>
                Pago via <strong>{meioPagamento}</strong> em{' '}
                {getFormatdataPagamento(selectedVistoria)}
              </div>
            </Show>
          </DadosCobranca>
        </Flex>
        <Show when={isMobile}>
          <ContainerTableMobile
            selectedVistoria={selectedVistoria}
            shouldMascararInformacoesSensiveis={shouldMascararInformacoesSensiveis}
          />
        </Show>
        <Show when={!isMobile}>
          <ContainerTable
            selectedVistoria={selectedVistoria}
            shouldMascararInformacoesSensiveis={shouldMascararInformacoesSensiveis}
          />
        </Show>
        {/* <Show
          when={
            location.pathname !== '/pagamento' &&
            !!selectedVistoria.dataAgendamento &&
            !selectedVistoria.dataConclusao
          }
        >
          <ContainerButtonAgendamento>
            <Show
              when={!selectedVistoria.dataPagamentoCobranca && !selectedVistoria?.dataConclusao}
            >
              <Button
                loading={loading}
                style={{ backgroundColor: '#d15656', border: 'solid 1px #d15656', color: '#FFF' }}
                onClick={handleCancelarSolicitacao}
              >
                Cancelar solicitação
              </Button>
            </Show>
            <Show when={!!selectedVistoria.dataAgendamento}>
              <Button
                style={{ backgroundColor: '#d15656', border: 'solid 1px #d15656', color: '#FFF' }}
                onClick={handleClickAlterarAgendamento}
              >
                Alterar Agendamento
              </Button>
            </Show>
          </ContainerButtonAgendamento>
        </Show> */}
        <ContainerValor>
          Valor: R$ {selectedVistoria.valorCobranca?.toFixed(2).replace('.', ',')}
        </ContainerValor>
      </ContainerComprovante>
      <ContainerBotoes>
        <Button onClick={handleClickImprimir}>
          Imprimir
          <img src={iconPrinter} />
        </Button>
        <Button loading={loading} primary={loading} onClick={handleClickNotaFiscal}>
          Nota Fiscal
          <img src={iconNotaFiscal} />
        </Button>
        <ButtonWhatsapp onClick={() => handleClickWhatsapp(selectedVistoria)}>
          Enviar via WhatsApp
          <img src={iconWhatsapp} />
        </ButtonWhatsapp>
      </ContainerBotoes>
      <div className="comprovante-info-footer">
        Caso o veículo seja reprovado ou bloqueado na vistoria, o solicitante terá até 30 dias
        corridos para realizar uma nova solicitação gratuita. Caso perca o prazo ou seja novamente
        reprovado, será necessário pagar uma nova vistoria.
      </div>
      <Modal style={{}} width={800} visible={modalNf} onClose={() => setModalNf(false)}>
        <NFComponent notafiscal={notafiscal} onClose={() => setModalNf(false)} />
      </Modal>
    </Layout>
  )
}
ReciboComponent.Layout = styled.div`
  @media print {
    position: fixed;
    min-height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3900;
    background: white;
  }
  .comprovante-info-agendamento {
    margin: 10px auto;
    color: #7f7f7d;
    text-align: center;
    font-weight: 400;
    font-size: 13px;
  }

  .comprovante-info-footer {
    @media (max-width: 600px) {
      height: 180px;
      text-align: center;
    }
  }
`
ReciboComponent.Flex = styled.div`
  display: flex;
  color: #292926;
  font-size: 16px;
  gap: 48px;
  justify-content: space-between;
  align-items: center;
  small {
    font-weight: 600;
    font-size: 14px;
  }
`

const ContainerTableMobile = ({ selectedVistoria, shouldMascararInformacoesSensiveis = false }) => {
  const { Layout, Flex, Grid, ContainerButtonLocalizacaoVistoria } = ContainerTableMobile
  const labelCpfCnpj = selectedVistoria.cliente.cpfCnpj.length === 14 ? 'CPF' : 'CNPJ'

  const nome = shouldMascararInformacoesSensiveis
    ? maskNome(selectedVistoria?.cliente?.nome)
    : selectedVistoria?.cliente?.nome

  const cpfCnpj = shouldMascararInformacoesSensiveis
    ? maskCpfCnpj(selectedVistoria?.cliente?.cpfCnpj)
    : selectedVistoria?.cliente?.cpfCnpj

  return (
    <Layout>
      <Flex>
        <Grid>
          <span>Solicitante</span>
          <span>{nome}</span>
        </Grid>
        <Grid>
          <span>{labelCpfCnpj}</span>
          <span>{cpfCnpj}</span>
        </Grid>
      </Flex>
      <Flex>
        <Grid>
          <span>Placa</span>
          <span>{selectedVistoria?.veiculo?.placa.toUpperCase()}</span>
        </Grid>
        <Grid>
          <span>Veículo</span>
          <span>{selectedVistoria?.veiculo?.marcaModelo}</span>
        </Grid>
      </Flex>
      <Flex>
        <Grid>
          <span>Local da ECV/Vistoria</span>
          <span>{selectedVistoria?.ecv?.nome}</span>
          <span>
            {selectedVistoria?.ecv?.endereco}, {selectedVistoria?.ecv?.bairro},{' '}
            {selectedVistoria?.ecv?.municipio} - {selectedVistoria?.ecv?.uf}
          </span>
          <span>{selectedVistoria?.ecv?.telefone}</span>
          <ContainerButtonLocalizacaoVistoria>
            <Button onClick={() => handleClickLocalizacao(selectedVistoria)}>
              Localização da vistoriadora
            </Button>
          </ContainerButtonLocalizacaoVistoria>
        </Grid>
      </Flex>
      <Show when={!!selectedVistoria?.dataAgendamento}>
        <Flex>
          <Grid>
            <span>Data Agendamento</span>
            <span>
              {format(new Date(selectedVistoria.dataAgendamento), `dd/MM/yyyy 'às' HH:mm`)}{' '}
              <em>{getFormatDistance(selectedVistoria.dataAgendamento)}</em>
            </span>
          </Grid>
        </Flex>
      </Show>
    </Layout>
  )
}
ContainerTableMobile.Layout = styled.div`
  padding-top: 10px;
  border-top: 1px solid #d4d4d4;
`
ContainerTableMobile.Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: space-between;
  padding: 10px 6px;
  border-bottom: 1px solid #d4d4d4;
`
ContainerTableMobile.Grid = styled.div`
  display: grid;
  color: #292926;
  font-size: 14px;
  & > *:first-of-type {
    font-weight: bold;
    font-size: 15px;
  }
`
ContainerTableMobile.ContainerButtonLocalizacaoVistoria = styled.div`
  margin-top: 12px;
  @media print {
    display: none;
  }
`

function maskNome(nome: string) {
  const [first, ...lasts] = nome.split(' ').filter(Boolean)
  const lastsMasked = lasts.map((x) => `${x.charAt(0)}${''.padEnd(x.length - 1, '*')}`).join(' ')
  return `${first} ${lastsMasked}`
}

function maskCpfCnpj(cpfCnpj: string) {
  return cpfCnpj.replace(/\.\d+/g, '.***')
}

const ContainerTable = ({ selectedVistoria, shouldMascararInformacoesSensiveis = false }) => {
  const { Table } = ContainerTable

  return (
    <Table cellPadding={0} cellSpacing={0}>
      <tbody>
        <ContainerTrInicial
          vistoria={selectedVistoria}
          shouldMascararInformacoesSensiveis={shouldMascararInformacoesSensiveis}
        />
        <ContainerTrFinal vistoria={selectedVistoria} />
        <Show when={selectedVistoria?.dataAgendamento}>
          <ContainerTrAgendamento vistoria={selectedVistoria} />
        </Show>
      </tbody>
    </Table>
  )
}

ContainerTable.Table = styled.table`
  color: #292926;
  font-size: 16px;
  width: 100%;
  small {
    font-weight: 600;
    font-size: 14px;
  }
  td {
    vertical-align: top;
    border-bottom: 1px solid #d4d4d4;
    padding: 24px 0;
  }
`

const ContainerTrInicial = ({ vistoria, shouldMascararInformacoesSensiveis = false }) => {
  const meioPagamento = getMeioPagamento(vistoria)
  const labelCpfCnpj = vistoria.cliente.cpfCnpj.length === 14 ? 'CPF' : 'CNPJ'

  const nome = shouldMascararInformacoesSensiveis
    ? maskNome(vistoria?.cliente?.nome)
    : vistoria?.cliente?.nome

  const cpfCnpj = shouldMascararInformacoesSensiveis
    ? maskCpfCnpj(vistoria?.cliente?.cpfCnpj)
    : vistoria?.cliente?.cpfCnpj

  return (
    <tr>
      <td>
        <small>Solicitante</small>
        <div>{nome}</div>
      </td>
      <td style={{ minWidth: 163 }}>
        <small>{labelCpfCnpj}</small>
        <div>{cpfCnpj}</div>
      </td>
      <td>
        <small>Forma Pagamento</small>
        <div>{meioPagamento}</div>
      </td>
    </tr>
  )
}

const ContainerTrFinal = ({ vistoria }) => {
  const { ContainerButtonLocalizacaoVistoria } = ContainerTrFinal
  return (
    <tr>
      <td>
        <small>Placa</small>
        <div style={{ fontSize: 20, fontWeight: 'bold' }}>
          {vistoria.veiculo.placa.toUpperCase()}
        </div>
      </td>
      <td>
        <small>Veículo</small>
        <div>{vistoria.veiculo.marcaModelo}</div>
      </td>
      <td>
        <div className="local-da-vistoriadora">
          <small>Local da ECV/Vistoria</small>
          <div>{vistoria.ecv.nome}</div>
          <div>
            {vistoria.ecv.endereco}, {vistoria.ecv.bairro}, {vistoria.ecv.municipio} -{' '}
            {vistoria.ecv.uf}
          </div>
          <div>{vistoria.ecv.telefone}</div>
          <ContainerButtonLocalizacaoVistoria>
            <Button onClick={() => handleClickLocalizacao(vistoria)}>
              Localização da vistoriadora
            </Button>
          </ContainerButtonLocalizacaoVistoria>
        </div>
      </td>
    </tr>
  )
}

ContainerTrFinal.ContainerButtonLocalizacaoVistoria = styled.div`
  margin-top: 12px;
  @media print {
    display: none;
  }
`

const ContainerTrAgendamento = ({ vistoria }) => {
  return (
    <tr>
      <td>
        <small>Data do agendamento</small>
        <div>{format(new Date(vistoria.dataAgendamento), 'dd/MM/yyyy')}</div>
      </td>
      <td>
        <small>Horário do agendamento</small>
        <div>
          {format(new Date(vistoria.dataAgendamento), 'HH:mm')}h{' '}
          <em>{getFormatDistance(vistoria?.dataAgendamento)}</em>
        </div>
      </td>
      <td></td>
    </tr>
  )
}

const DadosCobranca = ({ selectedVistoria, dataEmissao, children, ...props }) => {
  const { Layout, Title } = DadosCobranca

  return (
    <Layout>
      <Title className="titulo-comprovante">Comprovante</Title>
      <span>Cobrança #{selectedVistoria.cobrancaId} </span>
      <span>Emissão em {dataEmissao} </span>
      <div>{children}</div>
    </Layout>
  )
}

DadosCobranca.Layout = styled.div`
  display: flex;
  flex-direction: column;
  text-align: end;
  & > *:first-of-type {
    margin-bottom: 10px;
  }
  @media (max-width: 900px) {
    .titulo-comprovante {
      font-size: 16px;
    }
    span {
      font-size: 13px;
    }
    div {
      font-size: 13px;
    }
  }
`

DadosCobranca.Title = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: #122640;
`

ReciboComponent.Logo = styled.img`
  @media (max-width: 900px) {
    width: 80px;
    align-self: flex-start;
  }
`
ReciboComponent.Divider = styled.div`
  border-bottom: 1px solid #d4d4d4;
  width: 100%;
  margin: 24px 0;
`

ReciboComponent.ContainerValor = styled.div`
  font-weight: 700;
  font-size: 20px;
  text-align: right;
  margin-top: 24px;
`

ReciboComponent.ContainerButtonAgendamento = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  grid-gap: 20px;
  @media print {
    display: none;
  }
`

ReciboComponent.ButtonAgendamento = styled(Button)`
  font-size: 18px;
  font-weight: bold;
`

ReciboComponent.ContainerComprovante = styled.div`
  padding: 28px;
  border-radius: 8px;
  border: 1px solid #d4d4d4;
  margin: 24px auto 0 auto;
  max-width: 1200px;
  @media (max-width: 1269px) {
    margin: 24px 24px 0 24px;
  }
  @media (max-width: 900px) {
    padding: 16px;
    margin: 18px auto 0 auto;
  }
`
ReciboComponent.InnerHeading = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: #545451;
  text-align: center;
  margin-bottom: 24px;
`
ReciboComponent.ContainerBotoes = styled.div`
  display: flex;
  padding: 24px;
  justify-content: center;
  gap: 16px;
  align-items: center;
  @media print {
    display: none;
  }
  @media (max-width: 600px) {
    button {
      width: 100%;
      justify-content: center;
    }
    flex-direction: column;
    width: 100%;
  }
`
ReciboComponent.Button = styled(Button)`
  display: flex;
  align-items: center;
  gap: 10px;
`
ReciboComponent.ButtonWhatsapp = styled(ReciboComponent.Button)`
  border-color: #239c39;
  color: #239c39;
`
