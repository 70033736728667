import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { Button } from '../../../components/Button'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { addDays, addMonths, format as formatDate, formatRelative, getDay, isTomorrow } from 'date-fns'
import { ReactComponent as ClockIcon } from '../../../assets/icon-clock.svg'
import { Schemas } from '../../../components/ApiSchemas'
import { api, useApi } from '../../../components/Api'
import { createSimpleStore } from 'react-simple-reducer'
import toast, { LoaderIcon } from 'react-hot-toast'

type HorariosDisponiveis = Schemas.GetHorariosDisponiveisResponseDto[]

const AgendamentoStore = createSimpleStore({
  horariosDisponiveis: [] as HorariosDisponiveis,
  horarioSelecionadoId: null as number | null,
  possibilidadePrimeiroHorario: Boolean,
  loading: false,
}, {
  changeHorarioSelecionadoId (state, id) {
    state.horarioSelecionadoId = id
  },
  verificaPossibilidadePrimeiroHorario (state, possibilidadePrimeiroHorario) {
    state.possibilidadePrimeiroHorario = possibilidadePrimeiroHorario
  },
  getHorariosDisponiveisSuccess (state, horariosDisponiveis: HorariosDisponiveis) {
    state.horariosDisponiveis = horariosDisponiveis
    state.loading = false
  },
  setVistoria: (state, { vistoria }: { vistoria: Schemas.Vistoria }) => ({ ...state, vistoria }),
}, {
  thunks: {
    getHorariosDisponiveis (vistoriaId: number, data: Date) {
      return async (dispatch) => {
        try {
          const horariosDisponiveis = await api.Vistorias.getHorariosDisponiveis({
            vistoriaId,
          }, {
            params: { data: data.toISOString() },
          })
          dispatch(AgendamentoStore.actions.getHorariosDisponiveisSuccess(horariosDisponiveis))
        } catch (error: any) {
          toast.error(error.response?.data?.message || 'Erro ao buscar horários disponíveis!')
        }
      }
    },
  },
})

export type IState = ReturnType<typeof AgendamentoStore.useState>

export const AgendamentoComponent = ({ vistoria, handleAgendar }:
{ vistoria: Schemas.Vistoria, handleAgendar }) => {
  return (
    <AgendamentoStore.Provider>
      <Heading>Agendamento</Heading>
      <Subheading>Selecione uma data e um horário!</Subheading>
      <Divider />
      <Container>
        <SubContainer>
          <SelecaoHorario vistoria={vistoria} />
        </SubContainer>
        <SubContainer style={{ justifyContent: 'center' }}>
          <BtnAgendar handleAgendar={handleAgendar} />
        </SubContainer>
      </Container>
    </AgendamentoStore.Provider>
  )
}

const Heading = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  text-align: center;
  color: #122640;
  max-width: 490px;
  margin: 0 auto;
  margin-top: 36px;
  padding: 0 10px;
`
const Subheading = styled.div`
  color: #545451;
  font-size: 16px;
  text-align: center;
  margin-top: 24px;
`
const Divider = styled.div`
  border-bottom: 1px solid #D4D4D4;
  max-width: 564px;
  margin: 0 auto;
  margin-top: 24px;
`
const Container = styled.div`
  max-width: 800px;
  margin: 50px auto;
  .react-calendar {
    width: 100%;
  }

  @media (max-width: 600px) {
    .react-calendar {
      max-width: 350px;
    }

    text-align: center;
  }
`
const SubContainer = styled.div`
  display: flex;
  gap: 20px;
  margin: 20px 0px;

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const SelecaoHorario = ({ vistoria }: { vistoria: Schemas.Vistoria }) => {
  const { ListaHorarios } = SelecaoHorario
  const { horariosDisponiveis, loading } = AgendamentoStore.useState()
  const dispatch = AgendamentoStore.useDispatch()
  const initialDate = new Date()

  const handleChange = (date: Date) => {
    dispatch(AgendamentoStore.actions.changeHorarioSelecionadoId(null))
    dispatch(AgendamentoStore.thunks.getHorariosDisponiveis(vistoria.id, date))
  }

  useEffect(() => {
    handleChange(initialDate)
  }, [])

  if (!horariosDisponiveis) return null

  return (
    <>
      <Calendar
        defaultValue={initialDate}
        minDate={initialDate}
        maxDate={addMonths(new Date(), 3)}
        onChange={d => handleChange(d)}
        locale="pt-BR"
      />
      <ListaHorarios>
        {
          loading
            ? <LoaderIcon style={{ width: '100px', height: '100px', margin: '70px auto' }} />
            : horariosDisponiveis.map((h, i) => <Horario key={i} id={i} horario={h} />)
        }
      </ListaHorarios>
    </>
  )
}
SelecaoHorario.ListaHorarios = styled.div`
  width: 100%;
  height: 307px;
  border: 1px solid #a0a096;
  padding: 10px;
  overflow-x: hidden;

  @media (max-width: 600px) {
    width: 350px;
  }
`

const BtnAgendar = ({ handleAgendar }: any) => {
  const { Layout } = BtnAgendar
  const { horariosDisponiveis, horarioSelecionadoId, possibilidadePrimeiroHorario } = AgendamentoStore.useState()
  return (
    <Layout
      disabled={horarioSelecionadoId === null || !possibilidadePrimeiroHorario}
      onClick={() => handleAgendar(horarioSelecionadoId, horariosDisponiveis)}
    >
      Agendar
    </Layout>
  )
}

BtnAgendar.Layout = styled(Button)`
  background-color: #FFBC01;
  color: #292926;
  &:disabled {
    border: none;
  }
  border: 1px solid #FFBC01;
  width: 201px;
  height: 44px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
    
`

const Horario = ({ id, horario }: { id: number, horario: Schemas.GetHorariosDisponiveisResponseDto }) => {
  const { Layout } = Horario
  const { horariosDisponiveis, horarioSelecionadoId } = AgendamentoStore.useState()
  const dispatch = AgendamentoStore.useDispatch()

  const isAmanha = isTomorrow(new Date(horario.inicio))
  const inicio = formatDate(new Date(horario.inicio), 'HH:mm')
  const fim = formatDate(new Date(horario.fim), 'HH:mm')
  const selected = id === horarioSelecionadoId

  const handleClick = () => {
    dispatch(AgendamentoStore.actions.changeHorarioSelecionadoId(id === horarioSelecionadoId ? null : id))
    const dataAgendamento: any = horariosDisponiveis[id].inicio

    const agora = new Date().getTime()
    const primeiroHorario = new Date(dataAgendamento).getTime()

    dispatch(AgendamentoStore.actions.verificaPossibilidadePrimeiroHorario(primeiroHorario - agora >= 3600000))
  }

  return (
    <Layout onClick={handleClick} selected={selected}>
      <ClockIcon fill={selected ? '#FFF' : '#545451'} />
      <p>
        {isAmanha ? ' Amanhã ' : formatDate(new Date(horario.inicio), 'dd/MM')} de {inicio} até {fim}
      </p>
    </Layout>
  )
}
Horario.Layout = styled.div<{ selected }>(({ selected }) => `
  display: flex;
  align-items: center;
  gap: 10px;
  height: 40px;
  cursor: pointer;
  padding: 5px;
  background-color: ${selected ? '#3498db' : ''};
  
  p {
    color: ${selected ? '#FFF' : '#545451'};
    font-size: 16px;
  }

  :hover {
    background-color: ${selected ? 'none' : 'rgba(62, 145, 253, 0.062)'};
  }
`)
