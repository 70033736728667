import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { UsuarioLogado } from '../../../components/UsuarioLogado'
import { createSimpleStore } from 'react-simple-reducer'
import { AuthStore, selectEcvAtual } from '../../auth/AuthStore'
import { api, useApi } from '../../../components/Api'
import { Schemas } from '../../../components/ApiSchemas'
import { Input } from '../../../components/Input'
import toast, { LoaderIcon } from 'react-hot-toast'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { ReactComponent as CertificateIcon } from '../../../assets/icon-certificate.svg'
import { ReactComponent as DownloadIcon } from '../../../assets/icon-download.svg'
import { addMinutes, addHours, startOfDay, format } from 'date-fns'
import { Button } from '../../../components/Button'
import { CadastroInfoECV } from './cadastro-ecv/CadastroInfoECV'
import { useWindowWidth } from '../../../components/hooks/use-window-width'
import { InputLabel } from '../../../components/InputLabel'
import DatePickerComponent from '../../../components/DatePicker'
import { Show } from '../../../components/Show'
import { useParams } from 'react-router-dom'

type TAba = 'Cadastro' | 'Foto' | 'Valores' | 'Certificado Digital' | 'Agendamentos'
const Store = createSimpleStore(
  {
    abaAtiva: '' as TAba,
    loading: false,
    relatorioVistorias: [] as Schemas.Vistoria[],
    maximoAgendamentoIntervalo: 5,
    dataAgendamento: new Date(),
  },
  {
    changeAba (state, aba: TAba) {
      state.abaAtiva = aba
    },

    changeAbaByParams (state, aba: TAba) {
      state.abaAtiva = aba
    },
    getVistoriasRelatorioStarted (state) {
      state.loading = true
    },
    getVistoriasRelatorioSuccess (state, responseRelatorioVistoria) {
      state.loading = false
      state.relatorioVistorias = responseRelatorioVistoria
    },
    getVistoriasRelatorioFail (state) {
      state.loading = false
    },
    setDataAgendamento (state, data: Date) {
      state.dataAgendamento = data
    },

  }
)

const TabController = ({ children }: any) => {
  const dispatch = Store.useDispatch()
  const currentTab = useParams().agendamentos ? 'Agendamentos' : 'Cadastro'

  const queryParams = useParams()

  useEffect(() => {
    if (currentTab) dispatch(Store.actions.changeAbaByParams(currentTab as TAba))
  }, [queryParams])

  return (
    <>
      {children}
    </>
  )
}

export const CadastroEcv = () => {
  const windowWidth = useWindowWidth()
  const { Layout, Flex, Abas, Divider } = CadastroEcv
  const { token } = AuthStore.useState()

  if (!token) return null

  return (
    <Store.Provider>
      <TabController>
        <Layout>
          <Flex>
            <UsuarioLogado aplicacao='preto' />
          </Flex>
          <Divider />
          <div>
            <Abas>
              <AbaTitulo aba="Cadastro" />
              <AbaTitulo aba="Valores" />
              <AbaTitulo aba="Foto" />
              <AbaTitulo aba="Certificado Digital" />
              <AbaTitulo aba="Agendamentos" />
            </Abas>
            <AbaConteudo aba="Cadastro">
              <Cadastro />
            </AbaConteudo>
            <AbaConteudo aba="Valores">
              <Valores />
            </AbaConteudo>
            <AbaConteudo aba="Foto">
              <Foto />
            </AbaConteudo>
            <AbaConteudo aba="Certificado Digital">
              <CertificadoDigital />
            </AbaConteudo>
            <AbaConteudo aba="Agendamentos">
              <Agendamentos />
            </AbaConteudo>
          </div>
        </Layout>
      </TabController>
    </Store.Provider >
  )
}
CadastroEcv.Layout = styled.div`
  width: 100%;
  padding: 0 40px;
  @media (max-width: 900px) {
    padding: 0 24px;
  }
`
CadastroEcv.Flex = styled.div`
  display: flex;
  margin: 30px 0;
  justify-content: flex-end;
  @media (max-width: 900px) {
    display: none;
  }
`
CadastroEcv.VerticalDivider = styled.div`
  height: 18px;
  width: 3px;
  background: #545451;
  margin-top: 2px;
`
CadastroEcv.Abas = styled.div`
  display: flex;
  margin: 20px 0px 20px 0px;
  gap: 12px;
  align-items: center;
  @media(max-width: 515px) and (min-width: 360px){
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  @media(max-width: 360px){
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: 90px;
    overflow-y: auto;
  }
`
CadastroEcv.Divider = styled.div`
  border-bottom: 2px solid #D4D4D4;
  width: 100%;
  @media (max-width: 900px) {
    display: none;
  }
`

const AbaTitulo = ({ aba }: { aba: TAba }) => {
  const { Style } = AbaTitulo
  const dispatch = Store.useDispatch()
  const { abaAtiva } = Store.useState()

  return (
    <Style
      onClick={() => dispatch(Store.actions.changeAba(aba))}
      active={abaAtiva === aba}
    >
      {aba}
    </Style>
  )
}
AbaTitulo.Style = styled.div<{ active }>(({ active }) => `
  color: ${active ? 'black' : '#aaaaa8'};
  font-weight: bold;
  cursor: pointer;
  font-size: 20px;
  @media(min-width: 516px){
    &:not(:last-child):after {
    content: ' ';
    height: 18px;
    width: 2.7px;
    background: #545451;
    margin-top: 2px;
    display: inline-block;
    margin-left: 12px;
  }
  }
  @media(max-width: 515px){
    font-size: 13px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 50px;
    border: 1px solid #2d4565;
    background-color: ${active ? '#122640' : '#FFFFFF'};
    color: ${active ? '#FFFFFF ' : '#122640'};
    box-shadow: ${active ? '0px 0px 3px #122640' : 'none'};
    padding: 4px 0px;
    transition: .3s all;
  }
`)

const AbaConteudo = ({ aba, children }: { aba: TAba, children }) => {
  const { abaAtiva } = Store.useState()

  if (abaAtiva !== aba) return null

  return children
}

const Cadastro = () => {
  const { Layout, Titulo, CadastroEcvContainer } = Cadastro
  return (
    <Layout>
      <Titulo>Informações de Cadastro ECV</Titulo>
      <CadastroEcvContainer>
        <CadastroInfoECV />
      </CadastroEcvContainer>
    </Layout>
  )
}
Cadastro.Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 1024px;
  max-width: 100%;
`
Cadastro.Titulo = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
`
Cadastro.CadastroEcvContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Valores = () => {
  const { formatValor, getValorUgc, Layout, UgcContainer, Input, Flex, Button, Heading, Info } = Valores
  const ecvAtual = AuthStore.useSelector(selectEcvAtual)

  const [{ data: ecv }] = useApi.Ecvs.getEcv({ id: ecvAtual?.id ?? 0 })

  if (!ecv) return null

  const valorUgc = getValorUgc(ecv.ugc?.ugcValores)

  return (
    <Layout>
      <Heading>Vinculado à UGC</Heading>
      <UgcContainer>
        {ecv.ugc?.descricao}
      </UgcContainer>
      <Info>Para alterar entre em contato com o suporte.</Info>
      <Heading>Valor da UGC por vistoria</Heading>
      <UgcContainer>
        {valorUgc}
      </UgcContainer>
      <Heading>Valor da vistoria</Heading>
      <Flex style={{ marginTop: 12 }}>
        <Input value={formatValor(ecv.valor)} disabled />
        <Button disabled>Alterar</Button>
      </Flex>

    </Layout>
  )
}
Valores.getValorUgc = (valoresUgc?: Schemas.UgcValor[]) => {
  if (!valoresUgc?.length) return 'Valor não configurado'
  const valorEspecifico = valoresUgc.find(v => v.ecvId)
  if (valorEspecifico) return Valores.formatValor(valorEspecifico.valor)
  const valorPadrao = valoresUgc.find(v => !v.ecvId)
  if (valorPadrao) return Valores.formatValor(valorPadrao.valor)
  return 'Valor não configurado'
}
Valores.formatValor = (valor: number) => 'R$ ' + valor.toFixed(2).replace('.', ',')
Valores.Layout = styled.div`
  max-width: 600px;
  margin: 24px auto 0 auto;
`
Valores.Heading = styled.div`
  color: #545451;
  font-size: 18px;
  margin-top: 36px;
`
Valores.UgcContainer = styled.div`
  font-size: 34px;
  font-weight: 600;
  width: 100%;
  border-radius: 8px;
  border: 4px solid #ffbc01;
  padding: 24px;
  text-align: center;
  display: inline-block;
  margin-top: 12px;
`
Valores.Input = styled(Input)`
  height: 80px;
  font-size: 34px;
`
Valores.Flex = styled.div`
  display: flex;
  gap: 24px;
`
Valores.Info = styled.div`
  color: #545451;
  text-align: right;
`
Valores.Button = styled(Button)`
  height: 80px;
  width: 250px;
`

const Foto = () => {
  const { Layout, getPhoto, resizeBase64Image } = Foto
  const { ecvIdSelecionada } = AuthStore.useState()

  const [{ data, loading, error }, loadFoto] = useApi.Ecvs.getEcvFoto({
    params: { ecvId: ecvIdSelecionada as any },
  })

  const imageBase64 = (data as any)?.imageBase64

  useEffect(() => {
    if (!ecvIdSelecionada) return
    void loadFoto()
  }, [ecvIdSelecionada])

  const handleUploadPhoto = async () => {
    if (!ecvIdSelecionada) return

    const { cancelled, success, message, image } = await getPhoto()
    if (cancelled) return
    if (!success) return toast.error(message || '')

    // upload image
    const resizedImage = await resizeBase64Image(image)
    await api.Ecvs.uploadEcvFoto({ ecvId: ecvIdSelecionada, imageBase64: resizedImage })
    await loadFoto()
    toast.success('Foto enviada com sucesso!')
  }

  const handleDeletePhoto = async () => {
    if (!ecvIdSelecionada) return
    await api.Ecvs.deleleEcvFoto({ ecvId: ecvIdSelecionada })
    void loadFoto()
    toast.success('Foto excluida com sucesso!')
  }

  if (loading) {
    return (
      <div style={{ margin: '20px 0px' }}>
        <LoaderIcon
          style={{
            width: '200px',
            height: '200px',
            margin: '0px auto',
          }}
        />
      </div>
    )
  }

  return (
    <Layout>
      <Show when={!imageBase64}>
        <p style={{ color: '#868585', margin: '80px 0px', textAlign: 'center' }}>
          Nenhuma foto encontrada!
        </p>
      </Show>
      <Show when={!(loading || error) && imageBase64}>
        <img
          style={{ width: '100%' }}
          src={imageBase64}
        />
      </Show>
      <div style={{
        display: 'flex',
        gap: '20px',
        justifyContent: 'end',
        marginBottom: '100px',
      }}>
        <Button
          onClick={handleUploadPhoto}
        >
          Carregar Foto
        </Button>
        <Show when={imageBase64}>
          <Button
            onClick={handleDeletePhoto}
            style={{
              backgroundColor: '#f14d4d',
              color: '#FFFF',
              border: '1px red',
            }}
          >
            Excluir Foto
          </Button>
        </Show>
      </div>
    </Layout>
  )
}
Foto.Layout = styled.div`

`
Foto.getPhoto = async () => {
  try {
    const photo = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      source: CameraSource.Photos,
    })

    return {
      success: true,
      image: photo?.base64String ?? '',
    }
  } catch (e: any) {
    if (e.message === 'User cancelled photos app') return { cancelled: true }

    return {
      success: false,
      message: 'Ocorreu um erro ao capturar a foto!',
    }
  }
}
Foto.resizeBase64Image = async (image) => {
  return await new Promise<string>((resolve) => {
    const img = document.createElement('img')

    img.onload = function () {
      const canvas = document.createElement('canvas')
      const ctx: any = canvas.getContext('2d')
      ctx.imageSmoothingEnabled = true
      ctx.imageSmoothingQuality = 'high'

      const ratio = 640 / Math.max(img.width, img.height)

      canvas.width = img.width * ratio
      canvas.height = img.height * ratio

      ctx.drawImage(this, 0, 0, canvas.width, canvas.height)

      const dataURL = canvas.toDataURL('image/jpeg', 0.7)

      resolve(dataURL.replace(/^data:([A-Za-z-+/]+);base64,/, 'data:image;base64,'))
    }

    img.src = 'data:image;base64,' + image.replace(/^data:([A-Za-z-+/]+);base64,/, '')
  })
}

const CertificadoDigital = () => {
  const { Layout, ButtonsContainer, fileToBase64 } = CertificadoDigital
  const { ecvIdSelecionada } = AuthStore.useState()
  const inputFileRef = useRef() as any

  const [{ data, loading, error }, loadCertificado] = useApi.Ecvs.getEcvCertificado({
    params: { ecvId: ecvIdSelecionada as any },
  })

  const certificado = data as { fileName: string, certificadoBase64: string, url: string } | null

  useEffect(() => {
    if (!ecvIdSelecionada) return
    void loadCertificado()
  }, [ecvIdSelecionada])

  const handleCarregarCertificado = () => {
    inputFileRef.current.click()
  }

  const handleChange = (e) => {
    void uploadCertificado(e.target.files[0])
  }

  const uploadCertificado = async (file) => {
    if (!ecvIdSelecionada) return

    const certificadoBase64 = await fileToBase64(file)

    // upload certificado
    await api.Ecvs.uploadEcvCertificado({
      ecvId: ecvIdSelecionada,
      fileName: file?.name,
      certificadoBase64,
    })

    await loadCertificado()
    toast.success('Certificado enviado com sucesso!')
  }

  const handleDeleteCertificado = async () => {
    if (!ecvIdSelecionada || !certificado) return
    await api.Ecvs.deleleEcvCertificado({
      ecvId: ecvIdSelecionada,
      fileName: certificado.fileName,
    })
    void loadCertificado()
    toast.success('Certificado excluído com sucesso!')
  }

  if (loading) {
    return (
      <div style={{ margin: '20px 0px' }}>
        <LoaderIcon style={{ width: '200px', height: '200px', margin: '0px auto' }} />
      </div>
    )
  }

  return (
    <Layout>
      <Show when={!certificado}>
        <p style={{ color: '#575757', margin: '80px 0px', textAlign: 'center' }}>
          Nenhum certificado encontrado!
        </p>
      </Show>
      <Show when={!(loading || error) && !!certificado}>
        <div style={{ display: 'flex', justifyContent: 'center', margin: '80px 0px', gap: '10px' }}>
          <CertificateIcon width={50} height={50} />
          <p style={{ color: '#575757' }}>{certificado?.fileName}</p>
        </div>
      </Show>
      <input type="file" name="file" style={{ display: 'none' }} ref={inputFileRef} onChange={handleChange} />
      <ButtonsContainer>
        <Button onClick={handleCarregarCertificado}>
          Carregar Certificado
        </Button>
        <Show when={!!certificado}>
          <a href={certificado?.url} style={{ textDecoration: 'none' }}>
            <Button style={{ display: 'flex', gap: '10px', justifyContent: 'center', width: '100%' }}>
              <DownloadIcon style={{ cursor: 'pointer', marginTop: '-4px', fill: '#36a1d3' }} />
              Baixar Certificado
            </Button>
          </a>
          <Button
            onClick={handleDeleteCertificado}
            style={{ backgroundColor: '#f14d4d', color: '#FFFF', border: '1px red' }}
          >
            Excluir Certificado
          </Button>
        </Show>
      </ButtonsContainer>
    </Layout>
  )
}
CertificadoDigital.Layout = styled.div`
`
CertificadoDigital.ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: end;
  margin-bottom: 100px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`
CertificadoDigital.fileToBase64 = async (file) => {
  return await new Promise<string>(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      resolve(reader.result as string)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  })
}

const Agendamentos = () => {
  const { Layout, Titulo, ContainerTable, Flex } = Agendamentos

  const ecvAtual = AuthStore.useSelector(selectEcvAtual)
  const { dataAgendamento } = Store.useState()
  const dispatch = Store.useDispatch()

  const [{ loading }, relatorios] = useApi.Vistorias.getRelatorioAgendamentos({
    ecvId: ecvAtual?.id as any,
  }, {
    params: { data: dataAgendamento.toISOString() },
    options: { useCache: false, manual: true },
  })

  async function handleRelatorioAgendamento () {
    try {
      if (!ecvAtual?.id) return
      dispatch(Store.actions.getVistoriasRelatorioStarted())
      const response = await relatorios().then(d => d.data)
      dispatch(Store.actions.getVistoriasRelatorioSuccess(response))
      toast.success('Relatório gerado')
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro'
      toast.error(message)
      dispatch(Store.actions.getVistoriasRelatorioFail())
    }
  }

  return (
    <Layout>
      <Titulo>Relatório de Agendamentos</Titulo>
      <Flex>
        <DatePickerComponent selected={dataAgendamento} onChange={e => {
          dispatch(Store.actions.setDataAgendamento(e))
        }} />
        <Button primary onClick={handleRelatorioAgendamento}>Procurar</Button>
      </Flex>
      <ContainerTable>
        <Lista />
      </ContainerTable>
    </Layout>
  )
}

Agendamentos.Flex = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-end;
`

Agendamentos.Layout = styled.div`
  display: flex;
  flex-direction: column;
`

Agendamentos.Titulo = styled.p`
  font-size: 16px;
  text-align: start;
`

Agendamentos.ContainerTable = styled.div`
  padding: 12px;
`

const Lista = () => {
  const { Table } = Lista

  const { dataAgendamento } = Store.useState()

  const horarios = Array.from(Array(20).keys()).map((i) => {
    const inicio = addMinutes(addHours(startOfDay(dataAgendamento), 8), 30 * i)
    return { inicio, fim: addMinutes(inicio, 30) }
  })

  return (
    <Table>
      {horarios.map((horario, index) => <Horario key={index} horario={horario} />)}
    </Table>
  )
}
Lista.Table = styled.div`
  width: 100%;
  margin-top: 20px;
  overflow: auto;
  height: 640px;
  th{
    font-size: 14px;
    font-weight: bold;
    color: #7F7F7D;
    align-self: center;
  }
  tr{
    width: 100%;
    display: flex;
    gap: 20px;
    padding: 20px 0px;
  }
`

const Horario = ({ horario }: { horario }) => {
  const { getVistoriasHorario, Tr } = Horario
  const { relatorioVistorias, maximoAgendamentoIntervalo } = Store.useState()

  let vistoriasHorario = getVistoriasHorario(relatorioVistorias, horario)

  const vistoriasVazias = Array(Math.max(maximoAgendamentoIntervalo - vistoriasHorario.length, 0)).fill(null).map(x => null)
  vistoriasHorario = [...vistoriasHorario, ...vistoriasVazias]

  return (
    <Tr>
      <div>
        {format(horario.inicio, 'HH:mm')} - {format(horario.fim, 'HH:mm')}
      </div>
      {vistoriasHorario.map((vistoria, i) => <td key={vistoria?.id ?? i}><ListaItem vistoria={vistoria} /></td>)}
    </Tr>
  )
}
Horario.getVistoriasHorario = (relatorioVistoria: Schemas.Vistoria[], horario: { inicio, fim }): Array<Schemas.Vistoria | null> => {
  const compareHour = (inicioDate: Date, dataAgendamento) => {
    const fimDate = addMinutes(inicioDate, 30)
    if (new Date(dataAgendamento).getTime() <= new Date(fimDate).getTime() &&
      new Date(inicioDate).getTime() < new Date(dataAgendamento).getTime()) return true
    return false
  }

  const newRelatorio = relatorioVistoria.filter(vistoria => {
    return compareHour(horario.inicio, vistoria.dataAgendamento)
  })

  return newRelatorio
}
Horario.Tr = styled.tr`
  display: flex;
  align-items: center;
`

const ListaItem = ({ vistoria }: { vistoria: Schemas.Vistoria | null }) => {
  const { Card, CardItemFlex, Span } = ListaItem
  if (!vistoria) {
    return (
      <Card status={getStatus('VAZIO')}>
        <CardItemFlex status={getStatus('VAZIO')}>
          <Span className='titulo-card-item'>Status</Span>
          <Span className='valor-card-item status-enfatico'>{getStatus('VAZIO')}</Span>
        </CardItemFlex>
      </Card>
    )
  }
  return (
    <Card status={getStatus(vistoria.dataConclusao)}>
      <CardItemFlex>
        <Span className='valor-card-item enfatico'>{vistoria.veiculo?.placa}</Span>
      </CardItemFlex>

      <CardItemFlex>
        <Span className='valor-card-item'>{vistoria.veiculo?.tipo}</Span>
        <Span className='valor-card-item'>{vistoria.veiculo?.marcaModelo}</Span>
      </CardItemFlex>

      <CardItemFlex>
        <div className='card-item-grid'>
          <Span className='titulo-card-datagendamento'>Data Agendamento</Span>
          <Span>{vistoria.dataAgendamento ? format(new Date(vistoria.dataAgendamento), 'dd/MM/yyyy HH:mm') : ''}</Span>
        </div>
      </CardItemFlex>

      <CardItemFlex status={getStatus(vistoria.dataConclusao)}>
        <Span className='titulo-card-item'>Status</Span>
        <Span className='valor-card-item status-enfatico'>{getStatus(vistoria.dataConclusao)}</Span>
      </CardItemFlex>
    </Card>
  )
}

ListaItem.Card = styled.div<{ status?}>(({ status }) => `
  height: ${status === 'LIVRE' ? '150px;' : ''};
  border-radius: 5px 5px 8px 8px;
  padding: 18px 12px 12px 40px;
  border-top: ${boderColorStatus(status)};
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  transition: 0.4s all;
  box-shadow: 0px 2px 8px rgba(53, 49, 36, 0.2);
  width: 380px;
`)

ListaItem.CardItemFlex = styled.div<{ status?}>(({ status }) => `
display: flex;
align-items: center;
gap: 8px;
.titulo-card-item{
  color: #7F7F7D;
  font-weight: bold;
  font-size: 12px;
}
.valor-card-item{
  font-size: 15px;
}
.valor-card-item.enfatico{
  font-weight: bold;
}
.valor-card-item.status-enfatico{
  font-weight: bold;
  color: ${colorStatus(status)}
}
.card-item-grid{
  display: grid;
  .titulo-card-datagendamento{
    font-size: 13px;
    color: #7F7F7D;
  }
}
`)

ListaItem.Span = styled.span`
`

const boderColorStatus = (status: string) => {
  if (status === 'REALIZADO') return '3px solid #00CC66;'
  if (status === 'NÃO REALIZADO') return '3px solid #EB2A00;'
  if (status === 'LIVRE') return '3px solid #12A8EC;'
  if (status === 'INDISPONIVEL') return '3px solid #EB8D00;'
  return '3px solid #d4d4d4;'
}

const colorStatus = (status: string) => {
  if (status === 'REALIZADO') return '#00CC66;'
  if (status === 'NÃO REALIZADO') return '#EB2A00;'
  if (status === 'LIVRE') return '#12A8EC;'
  if (status === 'INDISPONIVEL') return '#EB8D00;'
  return 'black;'
}

const getStatus = (dataConclusao: any) => {
  if (!dataConclusao) return 'NÃO REALIZADO'
  if (dataConclusao !== 'VAZIO') return 'REALIZADO'
  if (dataConclusao === 'VAZIO') return 'LIVRE'
}
