import React, { useEffect, useMemo, useRef } from 'react'
import styled from '@emotion/styled'
import ReactDOM from 'react-dom'
import { Toaster } from 'react-hot-toast'

export const Modal = ({
  width,
  visible,
  onClose,
  children,
  ...props
}: { width: number; visible: boolean; onClose } & React.HTMLAttributes<HTMLDivElement>) => {
  const { Backdrop, Layout, Close } = Modal
  const ref = useRef<HTMLDivElement>(null)
  const element = document.getElementById('modal-portal')

  useEffect(() => {
    if (visible) {
      document.getElementById('root')!.style.filter = 'blur(3px)'
      return () => {
        document.getElementById('root')!.style.filter = ''
      }
    }
  }, [visible])

  useEffect(() => {
    function handleKeyPress(e) {
      if (e.key === 'Escape' && visible) onClose?.()
    }
    document.addEventListener('keydown', handleKeyPress)
    return () => document.removeEventListener('keydown', handleKeyPress)
  }, [visible])

  if (!visible) return null

  return ReactDOM.createPortal(
    <React.Fragment>
      <Toaster toastOptions={{ style: { background: '#363636', color: '#fff' } }} />
      <Backdrop className="modal-overlay" />
      <Layout ref={ref} tabIndex={-1} role="dialog" {...props} width={width}>
        <Close onClick={onClose}>X</Close>
        {children}
      </Layout>
    </React.Fragment>,
    element ?? document.body
  )
}
Modal.Backdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
`
Modal.Layout = styled.div<{ width }>(
  ({ width }) => `
  padding: 35px;
  position: fixed;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  background: #FFFFFF;
  border: #c3c3c3;
  max-width: ${width}px;
  z-index: 9999;
  border-radius: 15px;
  max-height: 95%;
  margin: 0 auto;
  top: 50%;
  right: 0;
  left: 0;
  overflow-y: auto;
  -webkit-transform:translateY(-50%);
  -moz-transform:translateY(-50%);
  -ms-transform:translateY(-50%);
  -o-transform:translateY(-50%);
  transform: translateY(-50%);
  @media(max-width: ${width + 48}px) {
    margin: 0 24px;
  }
  @media (max-width: 760px) {
    margin: 0 4px;
    padding: 24px 12px 68px 12px;
  }
`
)
Modal.Close = styled.div`
  cursor: pointer;
  padding: 12px;
  position: absolute;
  top: 20px;
  right: 25px;
`
