import styled from '@emotion/styled'
import React from 'react'
import { Schemas } from '../../../components/ApiSchemas'
import LogoVistoria from '../../../assets/logo-invertida.svg'
import { format } from 'date-fns'
import { Button } from '../../../components/Button'

export const NFComponent = ({ notafiscal, onClose }) => {
  const { Layout } = NFComponent

  return (
    <Layout>
      <ContainerTitles/>
      <ContainerDesktop notafiscal={notafiscal} onClose={onClose}/>
    </Layout>
  )
}

NFComponent.Layout = styled.div`
  padding: 20px;
`

const ContainerTitles = () => {
  const { Layout, Title, SubTitle } = ContainerTitles

  return (
    <Layout>
      <img src={LogoVistoria} width={100}/>
      <Title>Dados Nota Fiscal</Title>
      <SubTitle>Lembrando que sua NF ainda está processando, esse é apenas um espelho da nota com seus dados</SubTitle>
    </Layout>
  )
}

ContainerTitles.Layout = styled.div`
  max-width: 450px;
  margin: 0 auto;
  display: grid;
  gap: 13px;
  place-items: center;
`
ContainerTitles.Title = styled.span`
  font-size: 22px;
  font-weight: bolder;
  color: #122640;
`

ContainerTitles.SubTitle = styled.span`
  font-size: 15px;
  font-weight: 450px;
  text-align: center;
  color: #545451;
`

const ContainerDesktop = ({ notafiscal, onClose, ...props }) => {
  const { Layout, Flex, Grid, ContainerButton } = ContainerDesktop

  return (
    <Layout {...props}>
      <Flex>
        <Grid>
          <span>Data Emissão</span>
          <span>{notafiscal?.Data_Emissao ? format(new Date(notafiscal?.Data_Emissao), 'dd/MM/yyyy HH:mm') : ''}</span>
        </Grid>
        <Grid>
          <span>CNPJ Prestador</span>
          <span>{notafiscal?.Prestador?.cnpj}</span>
        </Grid>
        <Grid>
          <span>Razão Social Prestador</span>
          <span>{notafiscal?.Prestador?.razaoSocial}</span>
        </Grid>
        <Grid>
          <span>Endereço Prestador</span>
          <span>{notafiscal?.Prestador?.endereco}</span>
        </Grid>
        <Grid>
          <span>Município Prestador</span>
          <span>{notafiscal?.Prestador?.endereco}</span>
        </Grid>
        <Grid>
          <span>Tomador</span>
          <span>{notafiscal?.Tomador?.nome}</span>
        </Grid>
        <Grid>
          <span>Tomador CPF/CNPJ</span>
          <span>{notafiscal?.Tomador?.cpf}</span>
        </Grid>
        <Grid>
          <span>NF</span>
          <span>{notafiscal?.Tipo}</span>
        </Grid>
        <Grid>
          <span>Status Nota Fiscal</span>
          <span>{notafiscal?.Status}</span>
        </Grid>
      </Flex>
      <ContainerButton>
        <Button primary onClick={onClose}>Fechar</Button>
      </ContainerButton>
    </Layout>
  )
}

ContainerDesktop.Layout = styled.div`
  padding: 20px 0px;
`
ContainerDesktop.Flex = styled.div`
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`
ContainerDesktop.Grid = styled.div`
  flex: 1 1 220px;
  padding: 6px;
  box-sizing: border-box;
  display: grid;
  place-items: center;
  &>*:first-of-type {
    font-weight: 500;
    font-size: 16px;
  }
  span{
    font-size: 15px;
  }
`
ContainerDesktop.ContainerButton = styled.div`
  display: grid;
  place-items: center;
  margin-top: 35px;
`
