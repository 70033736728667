import { Schemas } from '../../components/ApiSchemas'
import { createSimpleStore } from 'react-simple-reducer'
import toast from 'react-hot-toast'
import { api } from '../../components/Api'
import { createSelector } from 'reselect'

export const NovaVistoriaStore = createSimpleStore(
  {
    placa: '' as string,
    vistoria: { cliente: {}, veiculo: {} } as Schemas.Vistoria,
    cobranca: null as Schemas.CreateVistoriaResponseDto['cobranca'] | null,
    ecv: {} as Schemas.Ecv,
    ecvs: [] as Schemas.Ecv[],
    ufAtual: 'Goiás',
    municipioAtual: 'Goiânia',
    municipiosDisponiveis: [] as string[],
    ecvIdSelecionada: null as number | null,
    loading: {
      ecvs: false,
      veiculo: false,
      municipiosDisponiveis: false,
      checkPagamento: false,
      distribui: false,
    },
    filtroEcvEspecifica: '',
  },
  {
    clear(state) {
      state.placa = ''
      state.vistoria = { cliente: {}, veiculo: {} } as Schemas.Vistoria
      state.cobranca = null
    },
    setPlaca(state, placa: string) {
      state.placa = placa
    },
    getOrCreateClienteSuccess(state, cliente: Schemas.Cliente) {
      state.vistoria = { ...state.vistoria, cliente }
    },
    getOrCreateVeiculoSuccess(state, veiculo: Schemas.Veiculo) {
      state.vistoria = { ...state.vistoria, veiculo }
    },
    getVeiculoStarted(state) {
      state.loading = { ...state.loading, veiculo: true }
    },
    getVeiculoSuccess(state, veiculo: Schemas.Veiculo) {
      state.vistoria = { ...state.vistoria, veiculo, veiculoId: veiculo.id }
      state.loading = { ...state.loading, veiculo: false }
    },
    getEcvsStarted(state, municipio?) {
      state.loading = { ...state.loading, ecvs: true }
      if (municipio) state.municipioAtual = municipio
    },
    getEcvsSuccess(state, ecvs: Schemas.Ecv[]) {
      state.ecvs = ecvs
      state.loading = { ...state.loading, ecvs: false }
      state.ecvIdSelecionada = null
    },
    getEcvsError(state) {
      state.loading = { ...state.loading, ecvs: false }
    },
    getVeiculoError(state) {
      state.loading = { ...state.loading, veiculo: false }
    },
    getMunicipiosDisponiveisStarted(state) {
      state.loading = { ...state.loading, municipiosDisponiveis: true }
    },
    getMunicipiosDisponiveisSuccess(state, municipiosDisponiveis) {
      state.municipiosDisponiveis = municipiosDisponiveis
      state.loading = { ...state.loading, municipiosDisponiveis: false }
    },
    getMunicipiosDisponiveisError(state) {
      state.loading = { ...state.loading, municipiosDisponiveis: false }
    },
    selecionaEcv(state, ecvId) {
      state.ecvIdSelecionada = ecvId
    },
    setEcvToVistoria(state, ecv: Schemas.Ecv) {
      state.vistoria = { ...state.vistoria, ecv, ecvId: ecv.id }
    },
    setVistoria(state, vistoria) {
      state.vistoria = vistoria
    },

    createVistoriaSuccess(state, payload: Schemas.CreateVistoriaResponseDto) {
      state.vistoria = payload.vistoria
      state.cobranca = payload.cobranca
    },
    efetuaPagamentoSuccess(state, meioPagamento) {
      state.vistoria.dataPagamentoCobranca = new Date().toISOString()
      if (state.cobranca) {
        state.cobranca = { ...state.cobranca, dataPagamento: new Date() }
        state.cobranca = { ...state.cobranca, meioPagamento: meioPagamento }
      }
    },
    changeFiltroEcvEspecifica(state, value: string) {
      state.filtroEcvEspecifica = value
    },
    efetuarAgendamentoSuccess(state, dataAgendamento: string) {
      state.vistoria.dataAgendamento = dataAgendamento
    },
    cancelarAgendamentoSuccess(state) {
      state.vistoria.dataAgendamento = null
    },
    checkPagamentoStarted(state) {
      state.loading = { ...state.loading, checkPagamento: true }
    },
    checkPagamentoSuccess(state, vistoria: Schemas.Vistoria) {
      state.vistoria = {
        ...state.vistoria,
        ...vistoria,
      }
      state.loading = { ...state.loading, checkPagamento: false }
    },
    checkPagamentoError(state) {
      state.loading = { ...state.loading, checkPagamento: false }
    },

    distribuiStarted(state) {
      state.loading.distribui = true
    },
    distribuiSuccess(state, ecv) {
      state.ecv = ecv
      state.loading.distribui = false
    },
    distribuiError(state) {
      state.loading.distribui = false
    },
  },
  {
    thunks: {
      getEcvs({ municipio }) {
        return async (dispatch) => {
          try {
            dispatch(NovaVistoriaStore.actions.getEcvsStarted(municipio))
            const ecvs = await api.Ecvs.getEcvs({ params: { municipio } })
            const ecvsWithOrder = ecvs.map((ecv) => ({
              ...ecv,
              ordenacao: ~~(Math.random() * 1e4),
            }))
            dispatch(NovaVistoriaStore.actions.getEcvsSuccess(ecvsWithOrder))
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro'
            toast.error(message)
            dispatch(NovaVistoriaStore.actions.getEcvsError())
          }
        }
      },
      getVeiculo({ placa }) {
        return async (dispatch) => {
          try {
            dispatch(NovaVistoriaStore.actions.getVeiculoStarted())
            const veiculo = await api.Veiculos.getOrCreateVeiculo({ placa })
            dispatch(NovaVistoriaStore.actions.getVeiculoSuccess(veiculo))
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro'
            toast.error(message)
            dispatch(NovaVistoriaStore.actions.getVeiculoError())
          }
        }
      },
      getMunicipiosDisponiveis() {
        return async (dispatch) => {
          try {
            dispatch(NovaVistoriaStore.actions.getMunicipiosDisponiveisStarted())
            const municipiosDisponiveis = await api.Ecvs.getMunicipiosDisponiveis()
            dispatch(
              NovaVistoriaStore.actions.getMunicipiosDisponiveisSuccess(municipiosDisponiveis)
            )
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro'
            toast.error(message)
            dispatch(NovaVistoriaStore.actions.getMunicipiosDisponiveisError())
          }
        }
      },
      checkPagamento() {
        return async (dispatch, getState) => {
          try {
            const {
              vistoria: { id: vistoriaId },
            } = getState()
            dispatch(NovaVistoriaStore.actions.checkPagamentoStarted())
            const vistoriaComPagamento = await api.Vistorias.checkPagamento({
              vistoriaId,
            })
            dispatch(NovaVistoriaStore.actions.checkPagamentoSuccess(vistoriaComPagamento))
          } catch (error: any) {
            const message =
              error.response?.data?.message ?? 'Ocorreu um erro ao buscar informações do pagamento'
            toast.error(message)
            dispatch(NovaVistoriaStore.actions.checkPagamentoError())
          }
        }
      },

      distribui() {
        return async (dispatch, getState) => {
          const { placa } = getState()
          try {
            dispatch(NovaVistoriaStore.actions.distribuiStarted())
            const ecv = await api.Vistorias.distruibiEcv({
              placa,
              municipio: 'Goiânia',
            })
            dispatch(NovaVistoriaStore.actions.distribuiSuccess(ecv))
          } catch (error: any) {
            toast(error.response?.data?.message ?? 'Ocorreu um erro')
            dispatch(NovaVistoriaStore.actions.distribuiError())
          }
        }
      },
    },
    options: {
      cache: {
        key: 'VISTORIA_FACIL_NOVA_VISTORIA',
        location: 'SESSIONSTORAGE',
      },
    },
  }
)

type IState = ReturnType<typeof NovaVistoriaStore.useState>

export const selectEcvs = createSelector(
  (s: IState) => s.ecvs,
  (s: IState) => s.filtroEcvEspecifica,
  (ecvs, filtroEcvEspecifica) => {
    const sortedEcvs = [...ecvs].sort((a: any, b: any) => (a.ordenacao > b.ordenacao ? 1 : -1))
    if (!filtroEcvEspecifica) return sortedEcvs
    return sortedEcvs.filter((e) => {
      const normalize = (str) =>
        str
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
      const nome = normalize(e.nome)
      const filtro = normalize(filtroEcvEspecifica)
      return nome.includes(filtro)
    })
  }
)
