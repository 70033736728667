import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { PassoAPassoVistoria } from './components/PassoAPassoVistoria'
import { Schemas } from '../../components/ApiSchemas'
import { NovaVistoriaStore as Store, selectEcvs } from './NovaVistoriaStore'
import logo from '../../assets/logo-invertida.svg'
import iconMarker from '../../assets/icon-marker.svg'
import iconMarkerSelected from '../../assets/icon-marker-selected.svg'
import iconLogo from '../../assets/icon-logo.svg'
import { Select } from '../../components/Select'
import { Button } from '../../components/Button'
import { Input } from '../../components/Input'
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, InfoBox } from '@react-google-maps/api'
import { Show } from '../../components/Show'
import { useApi } from '../../components/Api'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { formatValor } from '../../components/format-valor'
import { NovaVistoriaContainer } from './nova-vistoria/NovaVistoriaContainer'
import { Modal } from '../../components/Modal'
import { useWindowWidth } from '../../components/hooks/use-window-width'
import iconChevronDown from '../../assets/icon-chevron-down-black.svg'
import AnimateHeight from 'react-animate-height'
import { AuthStore } from '../auth/AuthStore'
import { GlobalStore } from '../../GlobalStore'

export const EscolhaEcv = () => {
  const { Grid, ContainerFiltros, ContainerLista, ContainerMapa, ContainerAbas, Aba } = EscolhaEcv
  const { token } = AuthStore.useState()

  const {
    placa,
    vistoria: { veiculo, cliente },
    cobranca,
    municipioAtual,
  } = Store.useState()
  const dispatch = Store.useDispatch()

  const navigate = useNavigate()
  const windowWidth = useWindowWidth()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [abaAtual, setAbaAtual] = useState<'lista' | 'mapa'>('lista')

  useEffect(() => {
    dispatch(Store.thunks.getMunicipiosDisponiveis())
    if (!cliente.id) navigate('/')
  }, [])

  useEffect(() => {
    if (placa && !veiculo.id) dispatch(Store.thunks.getVeiculo({ placa }))
  }, [placa, veiculo])

  useEffect(() => {
    if (municipioAtual) dispatch(Store.thunks.getEcvs({ municipio: municipioAtual }))
  }, [municipioAtual])

  useEffect(() => {
    if (cobranca) navigate('/pagamento')
  }, [cobranca])

  function handleOpenModal() {
    setIsModalVisible(true)
  }
  return (
    <>
      <NovaVistoriaContainer passoAtual={3}>
        <Grid abaAtual={abaAtual}>
          <Show when={windowWidth > 600}>
            <ContainerFiltros>
              <Estado />
              <Cidade />
              <EcvEspecifica />
            </ContainerFiltros>
          </Show>
          <Show when={windowWidth < 1025}>
            <ContainerAbas className="escolha-ecv-abas">
              <Aba active={abaAtual === 'lista'} onClick={() => setAbaAtual('lista')}>
                Lista
              </Aba>
              <Aba active={abaAtual === 'mapa'} onClick={() => setAbaAtual('mapa')}>
                Mapa
              </Aba>
            </ContainerAbas>
          </Show>
          <Show when={windowWidth > 1024 || abaAtual === 'lista'}>
            <ContainerLista className="escolha-ecv-lista">
              <TotalEcvs />
              <ListaEcvs>
                {(ecv) => <CardEcv ecv={ecv} key={ecv.id} openModal={handleOpenModal} />}
              </ListaEcvs>
            </ContainerLista>
          </Show>
          <Show when={windowWidth > 1024 || abaAtual === 'mapa'}>
            <ContainerMapa className="escolha-ecv-mapa">
              <MapaEcvs openModal={handleOpenModal} />
            </ContainerMapa>
          </Show>
        </Grid>
      </NovaVistoriaContainer>
      <Modal
        width={400}
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        style={{ padding: 24 }}
      >
        <ModalContent closeModal={() => setIsModalVisible(false)} />
      </Modal>
    </>
  )
}
EscolhaEcv.Grid = styled.div<{ abaAtual: 'lista' | 'mapa' }>(
  ({ abaAtual }) => `
  display: grid;
  height: 100%;
  grid-template-columns: 580px 1fr;
  grid-template-rows: 98px 1fr;
  grid-template-areas:
    'filtros filtros'
    'lista mapa';
  @media (max-width: 1024px) {
    grid-template-rows: 82px 48px 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:
      'filtros'
      'abas'
      '${abaAtual}';
  }
  @media (max-width: 600px) {
    grid-template-rows: 48px 1fr;
    grid-template-areas:
      'abas'
      '${abaAtual}';
  }
`
)
EscolhaEcv.ContainerFiltros = styled.div`
  grid-area: filtros;
  display: flex;
  gap: 24px;
  border-bottom: 1px solid #ebebeb;
  align-items: center;
  padding-left: 24px;
  color: #545451;
  @media (max-width: 1024px) {
    gap: 16px;
    padding-left: 16px;
  }
`
EscolhaEcv.ContainerLista = styled.div`
  grid-area: lista;
  overflow: auto;
  @media (max-width: 600px) {
    padding-bottom: 60px;
  }
`
EscolhaEcv.ContainerMapa = styled.div`
  grid-area: mapa;
`
EscolhaEcv.ContainerAbas = styled.div`
  grid-area: abas;
  display: flex;
`
EscolhaEcv.Aba = styled.div<{ active }>(
  ({ active }) => `
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-bottom: 1px solid #D4D4D4;
  ${active ? 'background: #D4D4D4;' : ''}
  &:first-of-type {
    border-right: 1px solid #D4D4D4;
  }
`
)

const TotalEcvs = () => {
  const { Layout, ContainerFiltros } = TotalEcvs
  const { ecv, municipioAtual: cidadeAtual, ufAtual: estadoAtual } = Store.useState()
  const windowWidth = useWindowWidth()
  const [showFiltros, setShowFiltros] = useState(false)

  return (
    <Layout>
      <div onClick={() => setShowFiltros(!showFiltros)}>
        Encontramos 1 ECVs em {cidadeAtual} - {estadoAtual}
        <img src={iconChevronDown} style={{ marginLeft: 12 }} />
      </div>
      <Show when={windowWidth <= 600}>
        <AnimateHeight height={showFiltros ? 'auto' : 0}>
          <ContainerFiltros>
            <Estado />
            <Cidade />
            <EcvEspecifica />
          </ContainerFiltros>
        </AnimateHeight>
      </Show>
    </Layout>
  )
}
TotalEcvs.Layout = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
  color: #545451;
  padding: 16px 24px;
  @media (min-width: 601px) {
  }
  @media (max-width: 600px) {
    font-size: 14px;
    padding: 16px 12px;
  }
`
TotalEcvs.ContainerFiltros = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Ordenacao = () => {
  const { Layout } = Ordenacao
  return (
    <Layout>
      <Select>
        <option>Maior pro menor</option>
        <option>Maior pro menor</option>
      </Select>
    </Layout>
  )
}
Ordenacao.Layout = styled.div`
  margin: 10px 0 16px 24px;
  select {
    height: 30px;
    width: 200px;
    padding: 0;
  }
`

const ListaEcvs = ({ children }: { children: Function }) => {
  const ecvs = Store.useSelector(selectEcvs)
  return <>{ecvs.map((e) => children(e))}</>
}

const CardEcv = ({
  ecv,
  openModal,
  ...props
}: {
  ecv: Schemas.Ecv & { ordenacao: number }
  openModal
} & React.HTMLAttributes<HTMLDivElement>) => {
  const {
    Layout,
    Foto,
    Nome,
    Valor,
    TempoEspera,
    Endereco,
    HorarioFuncionamento,
    Button,
    getHorarioFuncionamento,
  } = CardEcv
  const dispatch = Store.useDispatch()
  const { ecvIdSelecionada } = Store.useState()

  function handleClickContratar() {
    dispatch(Store.actions.setEcvToVistoria(ecv))
    openModal()
  }

  const horarioFuncionamento = getHorarioFuncionamento(ecv)
  const valor = formatValor(ecv.valor)
  // const tempoEspera = useGetTempoEspera(ecv)
  const srcOrNumber = ecv.foto ? ecv.foto : (ecv.ordenacao % 7) + 1

  return (
    <Layout
      onMouseEnter={() => dispatch(Store.actions.selecionaEcv(ecv.id))}
      active={ecvIdSelecionada === ecv.id}
      {...props}
    >
      <Foto src={srcOrNumber as any} className="card-ecv-foto" />
      <div className="card-ecv-container-texto">
        <Nome>{ecv.nome}</Nome>
        <Valor>{valor}</Valor>
        <Endereco>
          {ecv.endereco} {ecv.bairro} - {ecv.municipio}
        </Endereco>
        {/* <TempoEspera>Tempo de espera: {tempoEspera}</TempoEspera> */}
        <HorarioFuncionamento className="card-ecv-horario-funcionamento">
          Horário de funcionamento
          <br />
          {horarioFuncionamento}
        </HorarioFuncionamento>
        <Button onClick={handleClickContratar} data-cy={`btn-contratar-ecv-${ecv.id}`}>
          Contratar
        </Button>
      </div>
    </Layout>
  )
}
CardEcv.getHorarioFuncionamento = (ecv: Schemas.Ecv) => {
  return '08:00 às 18:00h - Segunda a Sexta | 08:00 às 12:00h - Sábado'
}
CardEcv.Layout = styled.div<{ active: boolean }>(
  ({ active }) => `
  display: flex;
  gap: 16px;
  border-bottom: 1px solid #D4D4D4;
  align-items: center;
  ${active ? 'background: #F4F4F4;' : ''}
`
)
CardEcv.Foto = styled.img<{ src: string | number }>(
  ({ src }: { src }) => `
  height: 146px;
  width: 169px;
  background-position: center;
  background-size: cover;
  background-image: url('${src}');
  ${ternary(
    !isNaN(src),
    `
    background-image: url('building-icons/${src}.png');
    filter: contrast(0.3);
    opacity: 0.7;
  `
  )}
  @media (max-width: 600px) {
    width: 133px;
  }
`
)
CardEcv.Nome = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
  color: #474744;
  line-height: 120%;
`
CardEcv.Valor = styled.div`
  color: #ffbc01;
  font-weight: 600;
  font-size: 14px;
  margin-top: 4px;
`
CardEcv.Endereco = styled.div`
  font-size: 12px;
  font-weight: 400px;
  line-height: 120%;
  color: #474744;
  margin-top: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100vw - 150px);
  white-space: nowrap;
`
CardEcv.TempoEspera = styled.div`
  color: #292926;
  font-size: 10px;
  font-weight: 600;
  margin-top: 4px;
`
CardEcv.HorarioFuncionamento = styled.div`
  color: #474744;
  font-size: 10px;
`
CardEcv.Button = styled(Button)`
  margin-top: 12px;
  background: #ffbc01;
  border-radius: 8px;
  color: white;
  border: 0;
  height: 22px;
  padding: 4px 8px;
  font-weight: 600;
  font-size: 12px;
`

const CardEcvMap = styled(CardEcv)`
  flex-direction: column;
  border: 1px solid #d4d4d4;
  box-shadow: 0px 2px 8px 0px #514b3933;
  gap: 12px;
  width: 212px;
  background: white;
  .card-ecv-foto {
    width: 212px;
    height: 60px;
  }
  .card-ecv-horario-funcionamento {
    display: none;
  }
  .card-ecv-container-texto {
    padding: 0 12px 12px 12px;
    max-width: 100%;
  }
`

const MapaEcvs = React.memo(({ openModal }: { openModal }) => {
  const [map, setMap] = useState<any>(null)
  const ecvsFiltradas = Store.useSelector(selectEcvs)
  const { ecv } = Store.useState()

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey:
      process.env.NODE_ENV === 'production' ? 'AIzaSyB_8edKF6x8YB3Agv88DnWd-hwhYTj15JM' : '',
  })

  const center = useMemo(() => {
    if (!ecvsFiltradas) return { lat: -16.680528, lng: -49.256383 }

    return {
      lat: ecvsFiltradas.reduce((prev, curr) => prev + curr.latitude, 0) / ecvsFiltradas.length,
      lng: ecvsFiltradas.reduce((prev, curr) => prev + curr.longitude, 0) / ecvsFiltradas.length,
    }
  }, [ecvsFiltradas])

  useEffect(() => {
    if (map && ecvsFiltradas.length) {
      const bounds = new window.google.maps.LatLngBounds()
      ecvsFiltradas.map((ecv) => {
        bounds.extend({
          lat: ecv.latitude,
          lng: ecv.longitude,
        })
        return null
      })
      map.initialZoom = true
      map.fitBounds(bounds)
      const zoom = map.getZoom()
      map.setZoom(zoom > 16 ? 16 : zoom)
    }
  }, [map, ecvsFiltradas])

  const onLoad = useCallback((map) => setMap(map), [])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '100%' }}
      center={center}
      onLoad={onLoad}
      options={{
        styles: [
          {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }],
          },
        ],
      }}
    >
      <Markers openModal={openModal} />
    </GoogleMap>
  ) : (
    <></>
  )
})

const Markers = ({ openModal }) => {
  const [showInfoWindow, setShowInfoWindow] = useState(false)
  const { ecvIdSelecionada } = Store.useState()
  const dispatch = Store.useDispatch()

  useEffect(() => {
    setShowInfoWindow(false)
  }, [ecvIdSelecionada])

  const ecvsFiltradas = Store.useSelector(selectEcvs)

  return (
    <>
      {ecvsFiltradas.map((ecv) => {
        const position = new google.maps.LatLng(ecv.latitude, ecv.longitude)
        return (
          <Marker
            position={{ lat: ecv.latitude, lng: ecv.longitude }}
            key={ecv.id}
            onMouseOver={() => dispatch(Store.actions.selecionaEcv(ecv.id))}
            onMouseDown={() => setShowInfoWindow(true)}
            icon={ecvIdSelecionada === ecv.id ? iconMarkerSelected : iconMarker}
            options={{ optimized: false }}
          >
            <Show when={showInfoWindow && ecv.id === ecvIdSelecionada}>
              <InfoBox position={position} onCloseClick={() => setShowInfoWindow(false)}>
                <CardEcvMap ecv={ecv as any} openModal={openModal} />
              </InfoBox>
            </Show>
          </Marker>
        )
      })}
    </>
  )
}

const Estado = () => {
  const { Layout } = Estado
  return (
    <Layout>
      Estado
      <br />
      <Select disabled>
        <option>Goiás</option>
      </Select>
    </Layout>
  )
}
Estado.Layout = styled.label`
  font-size: 14px;
  color: #d4d4d4;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 240px;
  @media (max-width: 1024px) {
    font-size: 12px;
    gap: 4px;
    width: 120px;
    select {
      padding: 4px 8px;
      height: 35px;
    }
  }
`

const Cidade = () => {
  const { Layout } = Cidade
  const { municipiosDisponiveis, municipioAtual } = Store.useState()
  const dispatch = Store.useDispatch()

  function handleChange(e) {
    dispatch(Store.thunks.getEcvs({ municipio: e.target.value }))
  }

  return (
    <Layout>
      Município
      <br />
      <Select value={municipioAtual} onChange={handleChange}>
        <option>Selecione o município</option>
        {municipiosDisponiveis.map((municipio) => {
          return (
            <option key={municipio} value={municipio}>
              {municipio}
            </option>
          )
        })}
      </Select>
    </Layout>
  )
}
Cidade.Layout = styled.label`
  font-size: 14px;
  color: #545451;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 280px;
  @media (max-width: 1024px) {
    font-size: 12px;
    gap: 4px;
    width: 160px;
    select {
      padding: 4px 8px;
      height: 35px;
    }
  }
`

const EcvEspecifica = () => {
  const { Layout } = EcvEspecifica
  const { filtroEcvEspecifica } = Store.useState()
  const dispatch = Store.useDispatch()

  function handleChange(e) {
    dispatch(Store.actions.changeFiltroEcvEspecifica(e.target.value))
  }

  return (
    <Layout>
      ECV Específica
      <br />
      <Input value={filtroEcvEspecifica} onChange={handleChange} />
    </Layout>
  )
}
EcvEspecifica.Layout = styled.label`
  font-size: 14px;
  color: #545451;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 280px;
  @media (max-width: 1024px) {
    font-size: 12px;
    gap: 4px;
    width: 160px;
    input {
      padding: 4px 8px;
      height: 35px;
    }
  }
`

const ModalContent = ({ closeModal }) => {
  const {
    getLabelVeiculo,
    Layout,
    CloseContainer,
    LogoContainer,
    Heading,
    Subheading,
    InfoContainer,
    BotoesContainer,
    ClearButton,
    Backdrop,
  } = ModalContent

  const dispatch = Store.useDispatch()
  const {
    vistoria: { cliente, veiculo, ecv },
  } = Store.useState()

  const [{ data, loading }, reqCreateVistoria] = useApi.Vistorias.createVistoria(
    {
      clienteId: cliente?.id ?? 0,
      ecvId: ecv?.id ?? 0,
      veiculoId: veiculo?.id ?? 0,
    },
    { options: { manual: true } }
  )

  async function handleClickProsseguir() {
    if (loading) return
    try {
      const responseReqCreateVistoria = await reqCreateVistoria().then((x) => x.data)
      dispatch(Store.actions.createVistoriaSuccess(responseReqCreateVistoria))
    } catch (error: any) {
      const message =
        error.response?.data?.message ?? 'Ocorreu um erro ao prosseguir para pagamento'
      toast(message)
    }
  }

  const solicitante = `${cliente?.nome} / ${cliente?.cpfCnpj}`
  const labelVeiculo = getLabelVeiculo(veiculo)
  const local = `${ecv?.nome} / ${ecv?.municipio} - ${ecv?.uf}`
  const valor = formatValor(ecv?.valor)

  return (
    <Layout>
      <LogoContainer>
        <img src={iconLogo} />
      </LogoContainer>
      <Heading>Prosseguir para pagamento?</Heading>
      <Subheading>Revise os dados abaixo, para então prosseguir para o pagamento.</Subheading>
      <InfoContainer>
        <div>
          Solicitante: <strong>{solicitante}</strong>
        </div>
        <div>
          Veículo: <strong>{labelVeiculo}</strong>
        </div>
        <div>
          Local: <strong>{local}</strong>
        </div>
        <div>
          Valor: <strong>{valor}</strong>
        </div>
      </InfoContainer>
      <BotoesContainer>
        <ClearButton onClick={closeModal}>Cancelar</ClearButton>
        <Button
          onClick={handleClickProsseguir}
          primary={true}
          loading={loading}
          style={{ width: 105 }}
          data-cy="btn-prosseguir"
        >
          Prosseguir
        </Button>
      </BotoesContainer>
    </Layout>
  )
}
ModalContent.getLabelVeiculo = (veiculo: Schemas.Veiculo) => {
  const placa = veiculo?.placa
  if (veiculo?.marcaModelo && veiculo?.ano) {
    return `${veiculo?.marcaModelo} ${veiculo?.ano} - ${placa}`
  }
  if (veiculo?.marcaModelo) {
    return `${veiculo?.marcaModelo} - ${placa}`
  }
  return `${placa}`
}
ModalContent.Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(18, 38, 64, 0.8);
  opacity: 0.75;
`
ModalContent.Layout = styled.div`
  width: 100%;
  border-radius: 8px;
`
ModalContent.CloseContainer = styled.div`
  text-align: right;
  color: #b9c0c7;
  cursor: pointer;
`
ModalContent.LogoContainer = styled.div`
  text-align: center;
`
ModalContent.Heading = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  color: #212529;
  margin-top: 32px;
`
ModalContent.Subheading = styled.div`
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #636c74;
  margin-top: 8px;
`
ModalContent.InfoContainer = styled.div`
  margin: 24px 0;
  padding: 8px 0;
  border-top: 1px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;

  font-weight: 400;
  font-size: 12px;
  color: #545451;
  & > div {
    margin-top: 4px;
  }
  strong {
    font-size: 14px;
    font-weight: 600;
    color: #292926;
  }
`
ModalContent.BotoesContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
`
ModalContent.ClearButton = styled(Button)`
  border: none;
`

const ternary = (condition, string) => (condition ? string : '')
