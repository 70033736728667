import React, { useEffect, useState, useMemo } from 'react'
import styled from '@emotion/styled'
import { UsuarioLogado } from '../../../components/UsuarioLogado'
import { Schemas } from '../../../components/ApiSchemas'
import { addDays, format } from 'date-fns'
import { Button } from '../../../components/Button'
import iconSearch from '../../../assets/icon-search.svg'
import { Input } from '../../../components/Input'
import iconSolicitacoes from '../../../assets/icon-solicitacoes.svg'
import iconSolicitacoesFinalizadas from '../../../assets/icon-solicitacoes-finalizadas.svg'
import iconSolicitacoesNaoRealizadas from '../../../assets/icon-solicitacoes-nao-realizadas.svg'
import iconSolicitacoesAguardandoPagamento from '../../../assets/icon-solicitacoes-aguardando-pagamento.svg'
import { createSimpleStore } from 'react-simple-reducer'
import { api } from '../../../components/Api'
import { createSelector } from 'reselect'
import toast from 'react-hot-toast'
import { Modal } from '../../../components/Modal'
import { ReciboComponent } from '../../home/components/ReciboComponent'
import { LogoMarcaVeiculo } from '../../../components/LogoMarcaVeiculo'
import { useWindowWidth } from '../../../components/hooks/use-window-width'
import { Show } from '../../../components/Show'
import { AuthStore, getUsuarioLogado } from '../../auth/AuthStore'
import { Paginacao } from '../../../components/Paginacao'
import { FluxoSolicitacao } from '../../home/components/FluxoSolicitacao'
import IconLaudo from '../../../assets/icon-laudo.png'
import { Paragraph } from '../../../components/Typograph'
import { PERMISSOES } from '../../../components/Permissoes'

type TFiltroTipo = 'TODAS' | 'FINALIZADAS' | 'NAO_REALIZADAS' | 'AGUARDANDO_PAGAMENTO'
type TPaginacao = 15 | 25 | 50 | 100

export const Store = createSimpleStore(
  {
    loading: false,
    total: 0,
    totalFinalizadas: 0,
    totalNaoRealizadas: 0,
    totalAguardandoPagamento: 0,
    filtroTipo: 'TODAS' as TFiltroTipo,
    filtroTexto: '',
    vistorias: [] as Schemas.Vistoria[],
    vistoriasTemp: [] as Schemas.Vistoria[],
    limitPage: 15 as TPaginacao,
    paginaAtual: 1 as number,
  },
  {
    getVistoriasStarted(state) {
      state.loading = true
    },
    getVistoriasSuccess(state, responseGetVistoriasAdm) {
      state.total = responseGetVistoriasAdm.total
      state.totalFinalizadas = responseGetVistoriasAdm.totalFinalizadas
      state.totalNaoRealizadas = responseGetVistoriasAdm.totalNaoRealizadas
      state.totalAguardandoPagamento = responseGetVistoriasAdm.totalAguardandoPagamento
      state.vistorias = responseGetVistoriasAdm.vistorias
      state.loading = false
    },
    getVistoriasError(state) {
      state.loading = false
    },
    changeFiltroTipo(state, filtroTipo: TFiltroTipo) {
      state.filtroTipo = filtroTipo
    },
    changeFiltroTexto(state, value: string) {
      state.filtroTexto = value
    },
    changeQuerys(state, query) {
      state.limitPage = query.limit
      state.paginaAtual = query.page
      state.loading = true
    },
    updateVistorias(state, vistorias) {
      state.vistorias = vistorias
    },
  },
  {
    thunks: {
      getVistoriasWithQueryParams({ page, offset, limit, filtroTexto, filtroTipo }) {
        return async (dispatch) => {
          try {
            dispatch(Store.actions.changeQuerys({ page, limit }))
            const responseGetVistoriasAdmWithQuery = await api.Vistorias.getVistoriasAdm({
              params: {
                limit,
                offset,
                order: 'createdAt',
                orderBy: 'DESC',
                filtroTexto,
                filtroTipo,
              } as any,
            })
            dispatch(Store.actions.getVistoriasSuccess(responseGetVistoriasAdmWithQuery))
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro'
            toast.error(message)
            dispatch(Store.actions.getVistoriasError())
          }
        }
      },
    },
  }
)

type IState = ReturnType<typeof Store.useState>
const totaisSolicitacoes = createSelector(
  (s: IState) => s.total,
  (s: IState) => s.totalFinalizadas,
  (s: IState) => s.totalAguardandoPagamento,
  (s: IState) => s.totalNaoRealizadas,
  (s: IState) => s.filtroTipo,
  (total, finalizadas, aguardandoPagamento, naoRealizadas, tipo) => {
    if (tipo === 'AGUARDANDO_PAGAMENTO') return aguardandoPagamento
    if (tipo === 'FINALIZADAS') return finalizadas
    if (tipo === 'NAO_REALIZADAS') return naoRealizadas
    return total
  }
)

export const Solicitacoes = () => {
  const { Layout, Flex, Heading, Grid, Divider, UsuarioLogado, ContainerLista } = Solicitacoes
  const [vistoriaByParams, setVistoriaByParams] = useState({
    vistoriaShow: false,
    vistoria: ({} as Schemas.Vistoria) || null,
  })
  const [AcoesVistoria, setAcoesVistoria] = useState<Schemas.Vistoria>({} as Schemas.Vistoria)
  const [modalAcoesVistoria, setModalAcoesStatus] = useState(false)
  const { ecvIdSelecionada, token } = AuthStore.useState()
  const [optionPrintVisible, setModalOptionPrint] = useState(false)

  const init = React.useCallback((dispatch: any) => {
    if (!token) return
    dispatch(Store.thunks.getVistoriasWithQueryParams({ offset: 0, limit: 15 }))
  }, [])

  const isWide = useWindowWidth() > 900

  const handleAcoesStatus = (vistoria) => {
    setModalAcoesStatus(true)
    setAcoesVistoria(vistoria)
  }

  return (
    <Store.Provider init={init} key={ecvIdSelecionada}>
      <Layout>
        <Flex>
          <Busca />
          <UsuarioLogado aplicacao="preto" />
        </Flex>
        <Divider />
        <Flex>
          <Heading>Solicitações</Heading>
          <ButtonRelatorio setModalOptionPrint={setModalOptionPrint} />
        </Flex>
        <Grid>
          <BlocoSolicitacoes />
          <BlocoFinalizadas />
          <BlocoNaoRealizadas />
          <BlocoAguardandoPagamento />
        </Grid>
        <ContainerLista>
          <Lista setVistoriaByParams={setVistoriaByParams} vistoriaByParams={vistoriaByParams}>
            {(vistoria) => (
              <ListaItem
                key={vistoria.id}
                vistoria={vistoria}
                className={vistoria.id}
                handleAcoesStatus={handleAcoesStatus}
              />
            )}
          </Lista>
          <PaginacaoValues />
        </ContainerLista>
      </Layout>
      {vistoriaByParams.vistoriaShow && (
        <Modal
          visible={vistoriaByParams.vistoriaShow}
          onClose={() => {
            setVistoriaByParams({ vistoria: {} as Schemas.Vistoria, vistoriaShow: false })
            window.history.pushState('', '', '/adm')
          }}
          width={isWide ? 1400 : 800}
        >
          <ReciboComponent selectedVistoria={vistoriaByParams.vistoria} />
        </Modal>
      )}
      <Modal visible={modalAcoesVistoria} width={470} onClose={() => setModalAcoesStatus(false)}>
        <ContentAcoesStatusVistoria
          onClose={() => setModalAcoesStatus(false)}
          vistoria={AcoesVistoria}
        />
      </Modal>
      <Modal visible={optionPrintVisible} width={470} onClose={() => setModalOptionPrint(false)}>
        <ContentOptionPrint onClose={() => setModalOptionPrint(false)} />
      </Modal>
    </Store.Provider>
  )
}
Solicitacoes.Layout = styled.div`
  padding: 24px;
  flex: 1;
  @media (max-width: 900px) {
    padding: 12px;
  }
`
Solicitacoes.Flex = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  align-items: center;
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`
Solicitacoes.Heading = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  color: #545451;
`
Solicitacoes.Grid = styled.div`
  margin-top: 24px;
  gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  overflow: auto;
`
Solicitacoes.Divider = styled.div`
  border: 1px solid #d4d4d4;
  width: 100%;
  margin: 24px 0;
  @media (max-width: 900px) {
    display: none;
  }
`
Solicitacoes.UsuarioLogado = styled(UsuarioLogado)`
  @media (max-width: 900px) {
    display: none;
  }
`
Solicitacoes.ContainerLista = styled.div`
  max-height: calc(100vh - 380px);
  display: grid;
  gap: 20px;
`

const Busca = () => {
  const { Layout, Input } = Busca
  const { filtroTexto, filtroTipo } = Store.useState()
  const dispatch = Store.useDispatch()

  const handleGetVistoriasByFilters = (filtroTexto) => {
    dispatch(Store.actions.changeFiltroTexto(filtroTexto))
    dispatch(
      Store.thunks.getVistoriasWithQueryParams({
        page: 1,
        offset: 0,
        limit: 15,
        filtroTexto,
        filtroTipo,
      })
    )
  }

  return (
    <Layout>
      <img src={iconSearch} />
      <Input
        placeholder="Buscar por placa, marca/veículo ou tipo"
        value={filtroTexto}
        onChange={(e) => handleGetVistoriasByFilters(e.target.value)}
      />
    </Layout>
  )
}
Busca.Layout = styled.div`
  position: relative;
  max-width: 620px;
  width: 100%;
  img {
    position: absolute;
    top: 13px;
    left: 26px;
  }
`
Busca.Input = styled(Input)`
  border-radius: 16px;
  padding-left: 60px;
  border: 1px solid #aaaaa8;
  height: 44px;
`

const Datas = () => {
  const { Layout } = Datas
  return <Layout>Datas</Layout>
}
Datas.Layout = styled.div``

const BlocoSolicitacoes = () => {
  const { total, filtroTipo, filtroTexto } = Store.useState()
  const dispatch = Store.useDispatch()

  function handleClick() {
    dispatch(Store.actions.changeFiltroTipo('TODAS'))
    dispatch(
      Store.thunks.getVistoriasWithQueryParams({
        page: 1,
        offset: 0,
        limit: 15,
        filtroTexto,
        filtroTipo: 'TODAS',
      })
    )
  }

  return (
    <Bloco
      onClick={handleClick}
      quantidade={total}
      label={<span>Todas</span>}
      icon={iconSolicitacoes}
      primaryColor="#12A8EC"
      secondaryColor="#E7F6FD"
      active={filtroTipo === 'TODAS'}
    />
  )
}

const BlocoFinalizadas = () => {
  const { totalFinalizadas, filtroTipo, filtroTexto } = Store.useState()
  const dispatch = Store.useDispatch()

  function handleClick() {
    dispatch(Store.actions.changeFiltroTipo('FINALIZADAS'))
    dispatch(
      Store.thunks.getVistoriasWithQueryParams({
        page: 1,
        offset: 0,
        limit: 15,
        filtroTexto,
        filtroTipo: 'FINALIZADAS',
      })
    )
  }

  return (
    <Bloco
      onClick={handleClick}
      quantidade={totalFinalizadas}
      label={<span>Concluídas</span>}
      icon={iconSolicitacoesFinalizadas}
      primaryColor="#00CC66"
      secondaryColor="#E9F5EB"
      active={filtroTipo === 'FINALIZADAS'}
    />
  )
}

const BlocoNaoRealizadas = () => {
  const { totalNaoRealizadas, filtroTipo, filtroTexto } = Store.useState()
  const dispatch = Store.useDispatch()

  function handleClick() {
    dispatch(Store.actions.changeFiltroTipo('NAO_REALIZADAS'))
    dispatch(
      Store.thunks.getVistoriasWithQueryParams({
        page: 1,
        offset: 0,
        limit: 15,
        filtroTexto,
        filtroTipo: 'NAO_REALIZADAS',
      })
    )
  }

  return (
    <Bloco
      onClick={handleClick}
      quantidade={totalNaoRealizadas}
      label={<span>Não Concluídas</span>}
      icon={iconSolicitacoesNaoRealizadas}
      primaryColor="#EB8D00"
      secondaryColor="#FDF4E5"
      active={filtroTipo === 'NAO_REALIZADAS'}
    />
  )
}

const BlocoAguardandoPagamento = () => {
  const { totalAguardandoPagamento, filtroTipo, filtroTexto } = Store.useState()
  const dispatch = Store.useDispatch()

  function handleClick() {
    dispatch(Store.actions.changeFiltroTipo('AGUARDANDO_PAGAMENTO'))
    dispatch(
      Store.thunks.getVistoriasWithQueryParams({
        page: 1,
        offset: 0,
        limit: 15,
        filtroTexto,
        filtroTipo: 'AGUARDANDO_PAGAMENTO',
      })
    )
  }

  return (
    <Bloco
      onClick={handleClick}
      quantidade={totalAguardandoPagamento}
      label={<span>Sem pagamento</span>}
      icon={iconSolicitacoesAguardandoPagamento}
      primaryColor="#EB2A00"
      secondaryColor="#FDEAE5"
      active={filtroTipo === 'AGUARDANDO_PAGAMENTO'}
    />
  )
}

const Bloco = ({ quantidade, label, icon, primaryColor, secondaryColor, active, ...props }) => {
  const { Layout } = Bloco
  return (
    <Layout {...props} primaryColor={primaryColor} secondaryColor={secondaryColor} active={active}>
      <div className="bloco-icon-container">
        <img src={icon} />
      </div>
      <div>
        <div className="bloco-contador">{quantidade}</div>
        <div className="bloco-label">{label}</div>
      </div>
    </Layout>
  )
}
Bloco.Layout = styled.div<{ primaryColor; secondaryColor; active }>(
  ({ primaryColor, secondaryColor, active }) => `
  background: #FFFFFF;
  border: 1px solid #D4D4D4;
  border-radius: 8px;
  height: 96px;
  ${active ? `border: 2px solid ${primaryColor};` : ''}
  ${active ? 'box-shadow: 0px 2px 8px rgba(81, 75, 57, 0.2);' : ''}
  transition: .25s all;
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 0 32px;
  span {
    font-size: 16px;
  }
  .bloco-icon-container {
    border-radius: 50%;
    width: 48px;
    min-width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${secondaryColor};
  }
  .bloco-contador {
    color: #545451;
    font-weight: bold;
    font-size: 20px;
  }
  .bloco-label {
    font-size: 12px;
    line-height: 120%;
    color: #7F7F7D;
    white-space: nowrap;
  }
  &:hover {
    cursor: pointer;
    box-shadow: 0px 2px 8px rgba(81, 75, 57, 0.2);
    background: #fafafa;
  }
  @media (max-width: 900px) {
    height: 78px;
    padding: 0 16px;
    gap: 8px;
  }
`
)

const ButtonRelatorio = ({ setModalOptionPrint }) => {
  return (
    <Button onClick={() => setModalOptionPrint(true)} type="button">
      Gerar Relatório
    </Button>
  )
}

const Lista = ({ children, setVistoriaByParams, vistoriaByParams }) => {
  const { Table } = Lista
  const { vistorias } = Store.useState()
  const windowWidth = useWindowWidth()

  useEffect(() => {
    if (vistoriaByParams.vistoriaShow || !vistorias.length) return
    const params = new URLSearchParams(window.location.search)
    const getStatusVistoria = params.get('modal-vistoria-atual')
    if (getStatusVistoria) {
      const newVistorias = vistorias?.find((vistoria) => vistoria.id === +getStatusVistoria)
      if (newVistorias) setVistoriaByParams({ vistoriaShow: true, vistoria: newVistorias })
    }
  }, [vistorias])

  if (windowWidth < 900) {
    return <div>{vistorias.map((vistoria) => children(vistoria))}</div>
  }

  return (
    <Table cellPadding={0} cellSpacing={0}>
      <thead>
        <tr>
          <th>
            <Paragraph>Placa/Cobrança</Paragraph>
          </th>
          <th>
            <Paragraph>Condutor</Paragraph>
          </th>
          <th style={{ width: 250 }}>
            <Paragraph>Marca/Veículo</Paragraph>
          </th>
          <th>
            <Paragraph>Tipo</Paragraph>
          </th>
          {/* <th>
            <Paragraph>Cidade/UF</Paragraph>
          </th>
          <th>
            <Paragraph>ECV</Paragraph>
          </th> */}
          <th style={{ width: 150 }}>
            <Paragraph>Data da Solicitação</Paragraph>
          </th>
          <th style={{ width: 115 }}>
            <Paragraph>Status</Paragraph>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>{vistorias.map((vistoria) => children(vistoria))}</tbody>
    </Table>
  )
}
Lista.Table = styled.table`
  width: 100%;
  margin-top: 32px;
  th {
    color: #7f7f7d;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1.5px solid #d4d4d4;
    padding-bottom: 16px;
    padding-right: 9px;
    text-align: left;
  }
  td {
    vertical-align: middle;
    padding: 18px 6px 15px 0px;
    border-bottom: 1.5px solid #d4d4d4;
    font-size: 14px;
    font-weight: 400;
    color: #545451;
    .enfatico {
      font-weight: 600;
    }
    .singelo {
      color: #7f7f7d;
      font-size: 12px;
    }
  }
  .button-content {
    display: flex;
    justify-content: center;
  }
`
const ListaItem = ({
  vistoria,
  className,
  handleAcoesStatus,
}: {
  vistoria: Schemas.Vistoria
  className
  handleAcoesStatus: (vistoria: Schemas.Vistoria) => void
}) => {
  const { Title, SubTitle, Small, Card, getNodeName, ButtonTable } = ListaItem
  const [isModalVisible, setIsModalVisible] = useState(false)
  const windowWidth = useWindowWidth()
  const usuarioLogado = AuthStore.useSelector(getUsuarioLogado)

  const { permissoes } = AuthStore.useState()

  const validButtonAcoes = useMemo(() => {
    if (!vistoria?.dataPagamentoCobranca) return true
    return false
  }, [vistoria])

  const handleSetModalVisible = () => {
    if (vistoria.deletedAt) return toast.error('Solicitação cancelada.')
    setIsModalVisible(true)
  }

  function closeModalVerMais() {
    setIsModalVisible(false)
  }

  function hasPermissaoConcluirVistoria() {
    return permissoes.includes(PERMISSOES.CONCLUIR_VISTORIA)
  }

  if (windowWidth <= 900) {
    return (
      <Card>
        <FlexLista style={{ placeContent: 'end', justifyContent: 'space-between' }}>
          <FlexLista>
            <Title>#{vistoria.cobrancaId}</Title>
            <SubTitle>
              - Solicitação em: {format(new Date(vistoria.createdAt), 'dd/MM/yyyy')}
            </SubTitle>
          </FlexLista>
          <Grid data-cy={`status-text-content-${vistoria?.id}`} className="status-acoes-mobile">
            <StatusVistoria vistoria={vistoria} />
          </Grid>
        </FlexLista>
        <FlexLista>
          <FlexLista>
            <Grid>
              <Paragraph className="enfatico">{vistoria.veiculo.placa}</Paragraph>
              <Paragraph className="singelo"># {vistoria.cobrancaId}</Paragraph>
            </Grid>
          </FlexLista>
          <Grid data-cy={`status-text-content-${vistoria?.id}`} className="status-acoes-mobile">
            {(usuarioLogado?.isAdmin || hasPermissaoConcluirVistoria()) &&
              vistoria.dataAgendamento && (
                <Button
                  data-cy={`button-acoes-${vistoria?.id}`}
                  onClick={() => handleAcoesStatus(vistoria)}
                  disabled={validButtonAcoes}
                >
                  Status
                </Button>
              )}
          </Grid>
          <Show when={!!vistoria.dataPagamentoCobranca}>
            <Grid className="align-grid">
              <Paragraph className="singelo"> Pagamento em:</Paragraph>
              <Paragraph>
                {format(new Date(vistoria.dataPagamentoCobranca), 'dd/MM/yyyy')}
              </Paragraph>
            </Grid>
          </Show>
        </FlexLista>

        {/* <Grid>
          <Small>{vistoria.cliente.nome}</Small>
          <Grid style={{ display: 'flex' }}>
            <SubTitle>{vistoria?.ecv?.nome} &nbsp;</SubTitle> -{' '}
            <Small>
              &nbsp; {vistoria?.ecv?.municipio} - {vistoria?.ecv?.uf}
            </Small>
          </Grid>
        </Grid> */}
        <Button id={getNodeName(className)} onClick={handleSetModalVisible}>
          Ver mais
        </Button>
        <Modal visible={isModalVisible} onClose={closeModalVerMais} width={800}>
          <FluxoSolicitacao vistoria={vistoria} role="ADM" />
        </Modal>
      </Card>
    )
  }

  return (
    <tr>
      <td>
        <Grid>
          <Paragraph className="enfatico">{vistoria.veiculo.placa}</Paragraph>
          <Paragraph className="singelo"># {vistoria.cobrancaId}</Paragraph>
        </Grid>
      </td>
      <td>
        <Grid>
          <Paragraph className="enfatico">{vistoria?.cliente.nome || '-'}</Paragraph>
          <Paragraph className="singelo">{vistoria?.cliente.cpfCnpj || '-'}</Paragraph>
        </Grid>
      </td>
      <td>
        <FlexLista>
          <Paragraph className="enfatico">{vistoria.veiculo.marcaModelo || '-'}</Paragraph>
        </FlexLista>
      </td>
      <td>
        <Paragraph>{vistoria.veiculo.tipo || '-'}</Paragraph>
      </td>
      {/* <td>
        <Paragraph>
          {vistoria.ecv.municipio} - {vistoria.ecv.uf}
        </Paragraph>
      </td>
      <td>
        <Paragraph>{vistoria.ecv.nome}</Paragraph>
      </td> */}
      <td>
        <Paragraph>{format(new Date(vistoria.createdAt), 'dd/MM/yyyy')}</Paragraph>
      </td>
      <td data-cy={`status-text-content-${vistoria?.id}`}>
        <StatusVistoria vistoria={vistoria} />

        {(usuarioLogado?.isAdmin || hasPermissaoConcluirVistoria()) && vistoria.dataAgendamento && (
          <Button
            data-cy={`button-acoes-${vistoria?.id}`}
            onClick={() => handleAcoesStatus(vistoria)}
            disabled={validButtonAcoes}
          >
            Status
          </Button>
        )}
      </td>
      <td style={{ textAlign: 'end' }}>
        <ButtonTable id={getNodeName(className)} onClick={handleSetModalVisible}>
          Ver mais
        </ButtonTable>
        <Modal visible={isModalVisible} onClose={() => setIsModalVisible(false)} width={1400}>
          <FluxoSolicitacao vistoria={vistoria} role="ADM" />
        </Modal>
      </td>
    </tr>
  )
}
const FlexLista = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  @media (max-width: 900px) {
    justify-content: space-between;
  }
`
const Grid = styled.div`
  display: grid;
`
ListaItem.LogoMarcaVeiculo = styled(LogoMarcaVeiculo)`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 0.5px solid #d4d4d4;
  border-radius: 50%;
  img {
    width: 14px;
  }
`
ListaItem.Card = styled.div`
  border-radius: 8px;
  padding: 12px;
  border: 1px solid #d4d4d4;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .enfatico {
    font-size: 20px;
    font-weight: 600;
  }
  .singelo {
    font-size: 12px;
    color: #7f7f7d;
  }
  .status-acoes-mobile {
    display: flex;
    place-items: center;
    gap: 15px;
  }
  .align-grid {
    text-align: center;
  }
`
ListaItem.Title = styled.h3`
  margin: 0;
  font-size: 14px;
  color: #000;
`
ListaItem.SubTitle = styled.span`
  margin: 0;
  font-size: 14px;
  color: #545451;
  font-weight: bold;
`
ListaItem.Small = styled.span`
  margin: 0;
  font-size: 12px;
  color: #545451;
`
ListaItem.getNodeName = (className: string) => {
  return `card-vistoria ${className}`
}
ListaItem.ButtonTable = styled(Button)`
  width: 80px;
  @media (max-width: 1124px) {
    font-size: 12.5px;
  }
`

const StatusVistoria = ({ vistoria, ...props }: { vistoria: Schemas.Vistoria }) => {
  const { Layout, Circle } = StatusVistoria
  if (vistoria.dataConclusao) {
    return (
      <Layout {...props}>
        <Circle status="done" />
        <Paragraph>Concluída</Paragraph>
      </Layout>
    )
  }
  if (vistoria.dataAgendamento && !vistoria.dataConclusao) {
    return (
      <Layout {...props}>
        <Circle status="done" />
        <Paragraph>Agendado</Paragraph>
      </Layout>
    )
  }
  if (vistoria.dataPagamentoCobranca) {
    return (
      <Layout {...props}>
        <Circle status="waiting" />
        <Paragraph>Paga</Paragraph>
      </Layout>
    )
  }
  return (
    <Layout {...props}>
      <Circle status="unpaid" />
      <Paragraph>Aguardando pagamento</Paragraph>
    </Layout>
  )
}
StatusVistoria.Layout = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  line-height: 120%;
  @media (max-width: 900px) {
    flex-direction: column;
    font-size: 12px;
  }
`
StatusVistoria.Circle = styled.div<{ status }>(
  ({ status }) => `
  min-width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${getColorByStatus(status)};
`
)

function getColorByStatus(status) {
  if (status === 'done') return '#00CC66'
  if (status === 'waiting') return '#FFBC01'
  return '#EB2A00'
}

const ContentAcoesStatusVistoria = ({
  vistoria,
  onClose,
}: {
  vistoria: Schemas.Vistoria
  onClose
}) => {
  const { Layout, Grid, Flex } = ContentAcoesStatusVistoria
  return (
    <Layout data-cy="content-acoes-status-vistoria">
      <Grid>
        <img src={IconLaudo} width={100} />
        <ContainerTituloVistoriaContent vistoria={vistoria} />
      </Grid>
      <Grid>
        <Flex>
          <strong>Solicitante</strong>
          <span>{vistoria?.cliente?.nome}</span>
        </Flex>
        <Flex>
          <strong>CPF/CNPJ</strong>
          <span>{vistoria?.cliente?.cpfCnpj}</span>
        </Flex>
        <Flex>
          <strong>Placa</strong>
          <span>{vistoria?.veiculo?.placa}</span>
        </Flex>
        <Flex>
          <strong>Veiculo</strong>
          <span>{vistoria?.veiculo?.marcaModelo}</span>
        </Flex>
        <Flex>
          <strong>Local</strong>
          <span>
            {vistoria?.ecv?.bairro} {vistoria?.ecv?.endereco && `/ ${vistoria?.ecv?.endereco}`}
          </span>
        </Flex>
      </Grid>
      <div style={{ marginTop: 20 }} className="divider-titulo-vistoria-status" />
      <ContainerButtons vistoria={vistoria} onClose={onClose} />
    </Layout>
  )
}

ContentAcoesStatusVistoria.Layout = styled.div`
  padding: 12px 20px;
  .divider-titulo-vistoria-status {
    height: 1px;
    width: 100%;
    background: #d4d4d4;
  }
`
ContentAcoesStatusVistoria.Grid = styled.div`
  display: grid;
  gap: 10px;
  text-align: center;
  place-items: center;
  &:not(:first-of-type) {
    margin-top: 15px;
  }
  h2 {
    color: #122640;
  }
  &:nth-of-type(2) {
    gap: 8px;
    place-items: start;
  }
`

ContentAcoesStatusVistoria.Flex = styled.div`
  display: flex;
  gap: 5px;
  strong {
    font-size: 16px;
    font-weight: 550;
  }
  span {
    font-size: 14px;
  }
`

const ContainerButtons = ({ vistoria, onClose }: { vistoria: Schemas.Vistoria; onClose }) => {
  const { Layout, ButtonName, atualizarVistoriaType } = ContainerButtons
  const [loading, setLoading] = useState(false)
  const dispatch = Store.useDispatch()
  const { limitPage, paginaAtual, filtroTexto, filtroTipo } = Store.useState()

  const handleUpdateStatusVistoria = async (type: 'APROVADA' | 'REPROVADA') => {
    try {
      setLoading(true)
      await api.Vistorias.updateDataConclusao(
        { vistoriaId: vistoria.id, type },
        { options: { manual: true } }
      )

      const page = paginaAtual ?? 1
      const offset = (page - 1) * limitPage
      dispatch(
        Store.thunks.getVistoriasWithQueryParams({
          page: page,
          offset,
          limit: limitPage,
          filtroTexto,
          filtroTipo,
        })
      )
      setLoading(false)
      toast.success('Vistoria atualizada com sucesso.')
      onClose()
    } catch (error: any) {
      setLoading(false)
      console.log(error)

      const messageError = error?.response?.data?.message ?? 'Erro ao atualizar status da vistoria'
      toast.error(messageError)
    }
  }

  return (
    <Layout>
      <Button data-cy="btn-fechar-modal-status-vistoria" onClick={onClose}>
        Fechar
      </Button>
      <Button
        data-cy="btn-atualizar-status-vistoria"
        primary
        loading={loading}
        onClick={async () => await handleUpdateStatusVistoria(atualizarVistoriaType(vistoria))}
      >
        {ButtonName(vistoria)}
      </Button>
    </Layout>
  )
}

ContainerButtons.ButtonName = (vistoria) => {
  if (vistoria?.dataConclusao) return 'Alterar para reprovada'
  return 'Aprovar'
}

ContainerButtons.atualizarVistoriaType = (vistoria) => {
  if (vistoria?.dataConclusao) return 'REPROVADA'
  return 'APROVADA'
}

ContainerButtons.Layout = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-top: 20px;
`

const ContainerTituloVistoriaContent = ({ vistoria }: { vistoria: Schemas.Vistoria }) => {
  const { Layout, Title, SubTitle, DefineTitleFromStatus, DefineSubTitleFromStatus } =
    ContainerTituloVistoriaContent

  return (
    <Layout>
      <Title status={!!vistoria?.dataConclusao}>
        {DefineTitleFromStatus(vistoria?.dataConclusao)}
      </Title>
      <SubTitle>{DefineSubTitleFromStatus(vistoria?.dataConclusao)}</SubTitle>
      <div className="divider-titulo-vistoria-status" />
    </Layout>
  )
}

ContainerTituloVistoriaContent.DefineTitleFromStatus = (dataConclusao) => {
  if (dataConclusao) return 'Solicitação Concluída'
  return 'Solicitação não Concluída'
}
ContainerTituloVistoriaContent.DefineSubTitleFromStatus = (dataConclusao) => {
  if (!dataConclusao) return 'Confirme o status desta vistoria se estiver aprovada.'
  return `Essa vistoria foi aprovada por você em ${format(
    new Date(dataConclusao),
    'dd/MM/yyyy'
  )}. Confirme se deseja alterar.`
}

ContainerTituloVistoriaContent.Layout = styled.div`
  text-align: center;
  display: grid;
  gap: 10px;
`
ContainerTituloVistoriaContent.Title = styled.div<{ status }>(
  ({ status }) => `
  font-size: 20px;
  color: ${getStatusDataConclusao(status)};
  font-weight: bold;
  line-height: 120%;
`
)

ContainerTituloVistoriaContent.SubTitle = styled.div`
  font-size: 16px;
  font-weight: 480;
`

const getStatusDataConclusao = (status) => {
  if (status) return '#00CC66'
  return '#FD2E00'
}
const PaginacaoValues = () => {
  const { limitPage, paginaAtual, loading, filtroTexto, filtroTipo } = Store.useState()
  const dispatch = Store.useDispatch()
  const total = Store.useSelector(totaisSolicitacoes)

  const handlePageActive = ({ page, newLimit }: { page: number; newLimit?: number }) => {
    const offset = (page - 1) * limitPage
    dispatch(
      Store.thunks.getVistoriasWithQueryParams({
        page: page,
        offset,
        limit: newLimit ?? limitPage,
        filtroTexto,
        filtroTipo,
      })
    )
  }

  return (
    <Paginacao
      total={total}
      limitPage={limitPage}
      paginaAtual={paginaAtual}
      handlePageActive={handlePageActive}
    />
  )
}

const ContentOptionPrint = ({ onClose }: { onClose }) => {
  const { Layout, Grid, Flex, Title } = ContentOptionPrint
  const usuarioLogado = AuthStore.useSelector(getUsuarioLogado)
  const { ecvIdSelecionada } = AuthStore.useState()
  const { filtroTexto, filtroTipo } = Store.useState()

  const [optionPrint, setOptionPrint] = useState('')

  function gerarRelatorio() {
    if (!optionPrint) return toast.error('Selecione um formato de arquivo')

    const query: any = { extension: optionPrint, filtroTexto, filtroTipo }
    if (usuarioLogado?.isAdmin) query.ecvId = ecvIdSelecionada
    else query.clienteId = usuarioLogado?.id
    const queryString = new URLSearchParams(query).toString()

    window.open(`${process.env.REACT_APP_BACKEND_URL}/vistorias/relatorio?${queryString}`)
  }

  return (
    <Layout data-cy="content-acoes-status-vistoria">
      <Title>Selecione o formato do arquivo do relatório</Title>

      <Flex style={{ marginBottom: '20px' }}>
        <label>
          <input
            type="radio"
            checked={optionPrint === 'xlsx'}
            onChange={() => setOptionPrint('xlsx')}
          />{' '}
          XLSX
        </label>
        <label>
          <input
            type="radio"
            checked={optionPrint === 'pdf'}
            onChange={() => setOptionPrint('pdf')}
          />{' '}
          PDF
        </label>
      </Flex>

      <Flex>
        <Button width={120} onClick={onClose}>
          Cancelar
        </Button>
        <Button width={120} onClick={gerarRelatorio}>
          Gerar
        </Button>
      </Flex>
    </Layout>
  )
}

ContentOptionPrint.Layout = styled.div`
  padding: 0px;
  .divider-titulo-vistoria-status {
    height: 1px;
    width: 100%;
    background: #d4d4d4;
  }
`
ContentOptionPrint.Grid = styled.div`
  display: grid;
  gap: 10px;
  text-align: center;
  place-items: center;
  &:not(:first-of-type) {
    margin-top: 15px;
  }
  h2 {
    color: #122640;
  }
  &:nth-of-type(2) {
    gap: 8px;
    place-items: start;
  }
`

ContentOptionPrint.Flex = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
  strong {
    font-size: 16px;
    font-weight: 550;
  }
  span {
    font-size: 14px;
  }
`

ContentOptionPrint.Title = styled.h1`
  font-size: 20px;
  font-weight: bold;
  color: #122640;
  line-height: 120%;
`

ContentOptionPrint.SubTitle = styled.div`
  font-size: 16px;
  font-weight: 480;
`
