import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { NovaVistoriaStore as Store } from '../NovaVistoriaStore'
import { Show } from '../../../components/Show'
import { useApi } from '../../../components/Api'
import { format } from 'date-fns'
import { getMeioPagamento } from '../functions'

export const PassoAPassoVistoria = ({ passoAtual, ...props }: { passoAtual: number } & React.HTMLAttributes<HTMLDivElement>) => {
  const { Layout, Title, Bloco } = PassoAPassoVistoria

  return (
    <>

        <Layout {...props}>
          <Title>Solicitação de vistoria veicular</Title>
          <Bloco>
            <Step numero={1} passoAtual={passoAtual} label="Dados do solicitante" />
            <DadosSolicitante />
          </Bloco>
          <Bloco>
            <Step numero={2} passoAtual={passoAtual} label="Placa do veículo" />
            <DadosVeiculo />
          </Bloco>
          <Bloco>
            <Step numero={3} passoAtual={passoAtual} label="Seleção de ECV" />
            <DadosEcv />
          </Bloco>
          <Bloco>
            <Step numero={4} passoAtual={passoAtual} label="Pagamento" />
            <DadosPagamento/>
          </Bloco>
          <Bloco>
            <Step numero={5} passoAtual={passoAtual} label="Agendamento" />
            <DadosAgendamento />
          </Bloco>
        </Layout>

    </>
  )
}
PassoAPassoVistoria.Layout = styled.div`
  border: 1px solid #D4D4D4;
  border-radius: 8px;
  width: 228px;
`
PassoAPassoVistoria.Title = styled.div`
  padding: 16px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #7F7F7D;
`
PassoAPassoVistoria.Bloco = styled.div`
  border-top: 1px solid #D4D4D4;
  padding: 16px;
  font-size: 12px;
  line-height: 120%;
  color: #545451;
  &>div {
    margin-top: 4px;
  }
  strong {
    color: #292926
  }
`

const DadosSolicitante = () => {
  const { Layout } = DadosSolicitante

  const { vistoria } = Store.useState()
  const cliente = vistoria.cliente

  if (!cliente) return null
  return (
    <div data-cy="dados-solicitante">
      <div>
        Nome: &nbsp;
        <strong>
          {cliente.nome}
        </strong>
      </div>

      <div>
        {cliente.cpfCnpj?.length === 14 ? 'CPF' : 'CNPJ'}: &nbsp;
        <strong>
          {cliente.cpfCnpj}
        </strong>
      </div>

      <Show when={!!cliente.telefone}>
        <div>
          Telefone: &nbsp;
          <strong>
            {cliente.telefone}
          </strong>
        </div>
      </Show>

      <Show when={!!cliente.email}>
        <div>
          E-mail: <strong>{cliente.email}</strong>
        </div>
      </Show>
    </div>
  )
}
DadosSolicitante.Layout = styled.div`

`

const DadosPagamento = () => {
  const { Layout } = DadosPagamento

  const { vistoria } = Store.useState()
  const cliente = vistoria.cliente

  const meioPagamento = getMeioPagamento(vistoria)

  if (!cliente) return null
  return (
    <div data-cy="dados-pagamento">
      <Show when={!!vistoria.meioPagamento}>
        <div>
          Tipo: &nbsp;
          <strong>
            {meioPagamento}
          </strong>
        </div>
      </Show>

      <Show when={!!vistoria.valorCobranca}>
        <div>
          Valor: <strong>R$ {vistoria.valorCobranca?.toFixed(2).replace('.', ',')}</strong>
        </div>
      </Show>
    </div>
  )
}
DadosPagamento.Layout = styled.div`

`

const DadosVeiculo = () => {
  const { vistoria: { veiculo }, placa } = Store.useState()
  if (!veiculo) return null

  return (
    <>
      <div>
        Placa: <strong>{placa?.toUpperCase() || veiculo.placa?.toUpperCase() || ''}</strong>
      </div>
      <Show when={!!veiculo.marcaModelo}>
        <div>
          Marca/Modelo: <strong>{veiculo.marcaModelo}</strong>
        </div>
      </Show>
      <Show when={!!veiculo.ano}>
        <div>
          Ano do veículo: <strong>{veiculo.ano}</strong>
        </div>
      </Show>
      <Show when={!!veiculo.tipo}>
        <div>
          Tipo: <strong>{veiculo.tipo}</strong>
        </div>
      </Show>
      <Show when={!!veiculo.cor}>
        <div>
          Cor: <strong>{veiculo.cor}</strong>
        </div>
      </Show>
    </>
  )
}

const DadosEcv = () => {
  const { vistoria: { ecv } } = Store.useState()
  if (!ecv?.id) return null
  return (
    <>
      <div>
        Município: <strong>{ecv.municipio}</strong>
      </div>
      <div>
        Vistoriadora: <strong>{ecv.nome}</strong><br />
        <small>{ecv.endereco} {ecv.bairro}</small>
      </div>
      <div>
        Serviço: <strong>Vistoria veicular</strong>
      </div>
    </>
  )
}

const DadosAgendamento = () => {
  const { vistoria } = Store.useState()

  if (vistoria.dataAgendamento) {
    return (
      <>
      <div>
        Data: <strong>{format(new Date(vistoria.dataAgendamento), 'dd/MM/yyyy')}</strong>
      </div>
      <div>
        Horário: <strong>{format(new Date(vistoria.dataAgendamento), 'HH:mm')}</strong>
      </div>
    </>
    )
  }
  return <></>
}

const Step = ({ numero, passoAtual, label }) => {
  const { Layout } = Step
  const active = passoAtual === numero
  const disabled = passoAtual < numero
  return (
    <Layout active={active} disabled={disabled}>
      <div className="badge">{numero}</div>
      {label}
    </Layout>
  )
}
Step.Layout = styled.div<{ active, disabled }>(({ active, disabled }) => `
  .badge {
    width: 22px;
    height: 22px;
    background: #00CC66;
    border-radius: 4px;
    color: white;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${active ? 'background: #ffbc01;' : ''}
    ${disabled ? 'background: #a0a8b3;' : ''}
  }
  display: flex;
  align-items: center;
  gap: 8px;
  ${disabled ? 'color: #bbbbb9;' : ''}
`)
