import styled from '@emotion/styled'
import { GoogleMap, InfoBox, Marker, useJsApiLoader } from '@react-google-maps/api'
import React, { useCallback, useMemo, useState } from 'react'
import { useEffect } from 'react'
import { Schemas } from '../../components/ApiSchemas'
import { Button } from '../../components/Button'
import { formatValor } from '../../components/format-valor'
import { Show } from '../../components/Show'
import { Paragraph, SubTitle, Title } from '../../components/Typograph'
import { NovaVistoriaContainer } from './nova-vistoria/NovaVistoriaContainer'
import { NovaVistoriaStore as Store } from './NovaVistoriaStore'
import iconMarker from '../../assets/icon-marker.svg'
import iconMarkerSelected from '../../assets/icon-marker-selected.svg'

export const LocalVistoria = () => {
  const { Layout, Header, Title, Subtitle, Button, Content } = LocalVistoria
  const state = Store.useState()
  const dispatch = Store.useDispatch()
  useEffect(() => {
    dispatch(Store.thunks.distribui())
  }, [])

  return (
    <NovaVistoriaContainer passoAtual={3}>
      <Layout>
        <Header>
          <Title>Local de vistoria</Title>
          <Subtitle>Confira o local determinado pelo DETRAN que irá realizar a vistoria.</Subtitle>
          <Button primary>Pagar agora &gt;</Button>
        </Header>
        <Content>
          <ListaInformacoes />
          <MapaEcvs openModal={true} />
        </Content>
      </Layout>
    </NovaVistoriaContainer>
  )
}
LocalVistoria.Layout = styled.div`
  padding: 10px 15px;
  height: 100%;
`
LocalVistoria.Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  margin-top: 10px;
  align-items: center;
`
LocalVistoria.Title = styled(Title)`
  color: #022859;
  text-align: center;
  font-size: 35px;
  font-weight: 700;
`
LocalVistoria.Subtitle = styled(SubTitle)`
  color: #545451;
  text-align: center;
  font-size: 18px;
`
LocalVistoria.Button = styled(Button)`
  font-size: 20px;
  padding: 10px 40px;
  height: 46px;
  @media (max-width: 768px) {
    font-size: 16px;
    height: 43px;
    padding: 5px 30px;
  }
`
LocalVistoria.Content = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
  height: calc(100vh - 400px);
  @media (max-width: 1024px) {
    flex-direction: column;
    height: 100%;
    padding-bottom: 80px;
  }
`
const ListaInformacoes = () => {
  const { Layout, ContainerInfo, TitleInfo, ValueInfo, Border } = ListaInformacoes
  const { ecv } = Store.useState()
  return (
    <Layout>
      <Border />
      <ContainerInfo>
        <TitleInfo>Vistoriadora</TitleInfo>
        <ValueInfo className="enfase">{ecv?.nome}</ValueInfo>
      </ContainerInfo>
      <ContainerInfo>
        <TitleInfo>CNPJ</TitleInfo>
        <ValueInfo>{ecv?.cnpj}</ValueInfo>
      </ContainerInfo>
      <ContainerInfo>
        <TitleInfo>Contato</TitleInfo>
        <ValueInfo>{ecv?.telefone}</ValueInfo>
      </ContainerInfo>
      <ContainerInfo>
        <TitleInfo>Endereço</TitleInfo>
        <ValueInfo>{ecv?.endereco}</ValueInfo>
      </ContainerInfo>
      <ContainerInfo>
        <TitleInfo>Município/UF</TitleInfo>
        <ValueInfo>{ecv?.municipio}</ValueInfo>
      </ContainerInfo>
      <ContainerInfo>
        <TitleInfo>Valor da vistoria</TitleInfo>
        <ValueInfo className="money">
          <strong>{formatValor(ecv?.valor)}</strong>
        </ValueInfo>
      </ContainerInfo>
    </Layout>
  )
}
ListaInformacoes.Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 37px;
  padding: 10px 15px;
  flex: 1;
`
ListaInformacoes.Border = styled.div`
  width: 180px;
  height: 10px;
  background-color: #ffbc01;
`
ListaInformacoes.ContainerInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  &:first-of-type {
    margin-top: 20px;
  }
  .enfase {
    color: #00cc66;
    font-weight: 600;
    font-size: 20px;
    @media (max-width: 768px) {
      font-size: 17px;
    }
  }
`
ListaInformacoes.TitleInfo = styled(Paragraph)`
  color: #292926;
  font-weight: 300;
  font-size: 17px;
  text-align: left;
`
ListaInformacoes.ValueInfo = styled(Paragraph)`
  color: #292926;
  font-size: 17px;
  text-align: right;
  font-weight: 500;
  strong {
    font-size: 23px;
    @media (max-width: 768px) {
      font-size: 17px;
    }
  }
  width: max-content;
`

const MapaEcvs = React.memo(({ openModal }: { openModal }): any => {
  const [map, setMap] = useState<any>(null)
  const { ecv } = Store.useState()
  const haveEcv = Object.keys(ecv).length > 0

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDiZsogKGoeOD_XLVeqPvrSyRzTCoR3y-I',
  })

  const center = useMemo(() => {
    if (!haveEcv) return { lat: -16.680528, lng: -49.256383 }

    return {
      lat: ecv?.latitude,
      lng: ecv?.longitude,
    }
  }, [ecv])

  useEffect(() => {
    if (map && haveEcv) {
      const bounds = new window.google.maps.LatLngBounds()
      bounds.extend({
        lat: ecv.latitude,
        lng: ecv.longitude,
      })
      map.initialZoom = true
      map.fitBounds(bounds)
      const zoom = map.getZoom()
      map.setZoom(zoom > 16 ? 16 : zoom)
    }
  }, [map, ecv])

  const onLoad = useCallback((map) => setMap(map), [])

  return isLoaded ? (
    <LayoutMapaEcv>
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '100%' }}
        center={center}
        onLoad={onLoad}
        options={{
          styles: [
            {
              featureType: 'poi',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'transit',
              elementType: 'labels.icon',
              stylers: [{ visibility: 'off' }],
            },
          ],
        }}
      >
        <Markers openModal={openModal} />
      </GoogleMap>
    </LayoutMapaEcv>
  ) : (
    <></>
  )
})
const LayoutMapaEcv = styled.div`
  flex: 2;
`

const Markers = ({ openModal }) => {
  const [showInfoWindow, setShowInfoWindow] = useState(false)
  const { ecvIdSelecionada } = Store.useState()
  const { ecv } = Store.useState()
  const dispatch = Store.useDispatch()

  useEffect(() => {
    setShowInfoWindow(false)
  }, [ecv])

  const position = new google.maps.LatLng(ecv.latitude, ecv.longitude)
  return (
    <Marker
      position={{ lat: ecv.latitude, lng: ecv.longitude }}
      key={ecv.id}
      onMouseOver={() => dispatch(Store.actions.selecionaEcv(ecv.id))}
      onMouseDown={() => setShowInfoWindow(true)}
      icon={ecvIdSelecionada === ecv.id ? iconMarkerSelected : iconMarker}
      options={{ optimized: false }}
    >
      <Show when={showInfoWindow && ecv.id === ecvIdSelecionada}>
        <InfoBox position={position} onCloseClick={() => setShowInfoWindow(false)}>
          <CardEcvMap ecv={ecv as any} openModal={openModal} />
        </InfoBox>
      </Show>
    </Marker>
  )
}

const CardEcv = ({
  ecv,
  openModal,
  ...props
}: {
  ecv: Schemas.Ecv & { ordenacao: number }
  openModal
} & React.HTMLAttributes<HTMLDivElement>) => {
  const {
    Layout,
    Foto,
    Nome,
    Valor,
    TempoEspera,
    Endereco,
    HorarioFuncionamento,
    Button,
    getHorarioFuncionamento,
  } = CardEcv
  const dispatch = Store.useDispatch()
  const { ecvIdSelecionada } = Store.useState()

  function handleClickContratar() {
    dispatch(Store.actions.setEcvToVistoria(ecv))
    openModal()
  }

  const horarioFuncionamento = getHorarioFuncionamento(ecv)
  const valor = formatValor(ecv.valor)
  const srcOrNumber = ecv.foto ? ecv.foto : (ecv.ordenacao % 7) + 1

  return (
    <Layout
      onMouseEnter={() => dispatch(Store.actions.selecionaEcv(ecv.id))}
      active={ecvIdSelecionada === ecv.id}
      {...props}
    >
      <Foto src={srcOrNumber as any} className="card-ecv-foto" />
      <div className="card-ecv-container-texto">
        <Nome>{ecv.nome}</Nome>
        <Valor>{valor}</Valor>
        <Endereco>
          {ecv.endereco} {ecv.bairro} - {ecv.municipio}
        </Endereco>
        {/* <TempoEspera>Tempo de espera: {tempoEspera}</TempoEspera> */}
        <HorarioFuncionamento className="card-ecv-horario-funcionamento">
          Horário de funcionamento
          <br />
          {horarioFuncionamento}
        </HorarioFuncionamento>
        <Button onClick={handleClickContratar} data-cy={`btn-contratar-ecv-${ecv.id}`}>
          Contratar
        </Button>
      </div>
    </Layout>
  )
}
CardEcv.getHorarioFuncionamento = (ecv: Schemas.Ecv) => {
  return '08:00 às 18:00h - Segunda a Sexta | 08:00 às 12:00h - Sábado'
}
CardEcv.Layout = styled.div<{ active: boolean }>(
  ({ active }) => `
  display: flex;
  gap: 16px;
  border-bottom: 1px solid #D4D4D4;
  align-items: center;
  ${active ? 'background: #F4F4F4;' : ''}
`
)
CardEcv.Foto = styled.img<{ src: string | number }>(
  ({ src }: { src }) => `
  height: 146px;
  width: 169px;
  background-position: center;
  background-size: cover;
  background-image: url('${src}');
  ${ternary(
    !isNaN(src),
    `
    background-image: url('building-icons/${src}.png');
    filter: contrast(0.3);
    opacity: 0.7;
  `
  )}
  @media (max-width: 600px) {
    width: 133px;
  }
`
)
CardEcv.Nome = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
  color: #474744;
  line-height: 120%;
`
CardEcv.Valor = styled.div`
  color: #ffbc01;
  font-weight: 600;
  font-size: 14px;
  margin-top: 4px;
`
CardEcv.Endereco = styled.div`
  font-size: 12px;
  font-weight: 400px;
  line-height: 120%;
  color: #474744;
  margin-top: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100vw - 150px);
  white-space: nowrap;
`
CardEcv.TempoEspera = styled.div`
  color: #292926;
  font-size: 10px;
  font-weight: 600;
  margin-top: 4px;
`
CardEcv.HorarioFuncionamento = styled.div`
  color: #474744;
  font-size: 10px;
`
CardEcv.Button = styled(Button)`
  margin-top: 12px;
  background: #ffbc01;
  border-radius: 8px;
  color: white;
  border: 0;
  height: 22px;
  padding: 4px 8px;
  font-weight: 600;
  font-size: 12px;
`

const CardEcvMap = styled(CardEcv)`
  flex-direction: column;
  border: 1px solid #d4d4d4;
  box-shadow: 0px 2px 8px 0px #514b3933;
  gap: 12px;
  width: 212px;
  background: white;
  .card-ecv-foto {
    width: 212px;
    height: 60px;
  }
  .card-ecv-horario-funcionamento {
    display: none;
  }
  .card-ecv-container-texto {
    padding: 0 12px 12px 12px;
    max-width: 100%;
  }
`
const ternary = (condition, string) => (condition ? string : '')
