import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { ReactComponent as Logo } from '../../assets/logo-terciaria.svg'
import { ReactComponent as LogoAmarelo } from '../../assets/logo.svg'
import iconSearch from '../../assets/icon-search.svg'
import InputMask from 'react-input-mask'
import { DadosSolicitante } from './DadosSolicitante'
import toast from 'react-hot-toast'
import { NovaVistoriaStore as Store } from './NovaVistoriaStore'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../components/Button'
import { UsuarioLogado } from '../../components/UsuarioLogado'
import { AuthStore, getUsuarioLogado } from '../auth/AuthStore'
import { Modal } from '../../components/Modal'
import { Schemas } from '../../components/ApiSchemas'
import { Show } from '../../components/Show'
import { api } from '../../components/Api'
import { CpfCnpjInput } from '../../components/CpfCnpjInput'
import { format } from 'date-fns'
import { ReactComponent as TextoFacil } from '../../assets/texto-facil.svg'
import { useOpenModal } from '../../GlobalStore'
import selecioneEcvIcon from '../../assets/icon-selecione-ecv.svg'
import pagueCobrancaIcon from '../../assets/icon-pague-cobranca.svg'
import agendeHorarioIcon from '../../assets/icon-agende-seu-horario.svg'
import realizeVistoriaIcon from '../../assets/icon-realize-vistoria.svg'
import acesseLaudoIcon from '../../assets/icon-acesse-laudo.svg'
import comoFuncionaIcon from '../../assets/icon-como-funciona.svg'
import introLpVistoriaIcon from '../../assets/icon-vistoria-lp-intro.svg'
import introLpVistoriaLogo from '../../assets/icon-lp-intro.svg'
import iconCalendar from '../../assets/icon-calendar.svg'
import logoSmartSistem from '../../assets/smart-sistem-logo.svg'
import beneficiosIcon from '../../assets/beneficios-icon.svg'
import groupFormaPgIcon from '../../assets/group-formapg-icon.svg'
import cadastroLpImg from '../../assets/cadastro-lp-img.png'
import { FluxoSolicitacao } from './components/FluxoSolicitacao'

export const Home = () => {
  const {
    Layout,
    Title,
    Subtitle,
    ContainerInput,
    ContainerButton,
    Flex,
    Container,
    Button,
    Logo,
    ImagemCarro,
    Rodape,
  } = Home

  const {
    placa,
    vistoria: { cliente },
  } = Store.useState()
  const { ecvIdSelecionada, token } = AuthStore.useState()
  const usuarioLogado = AuthStore.useSelector(getUsuarioLogado)
  const dispatch = Store.useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [veiculo, setVeiculo] = useState<Schemas.Veiculo | null>(null)
  const [modalDadosSolicitanteVisible, setModalDadosSolicitanteVisible] = useState(false)
  const [vistoriaEmAberto, setVistoriaEmAberto] = useState(null as Schemas.Vistoria | null)

  useEffect(() => {
    dispatch(Store.actions.clear())
  }, [])

  const submitPlaca = () => {
    if (placa?.replace(/_/g, '').length !== 7) {
      return toast('Informe a placa completa')
    }

    const vistoriaEmAberto = veiculo?.vistorias?.find((v) => v.dataConclusao == null)
    if (!vistoriaEmAberto) return avancaVistoria()
    if (vistoriaEmAberto) return setVistoriaEmAberto(vistoriaEmAberto)
  }

  const avancaVistoria = () => {
    // if (usuarioLogado && !usuarioLogado.isAdmin) {
    //   dispatch(Store.actions.getOrCreateClienteSuccess(usuarioLogado))
    //   // navigate('/local-de-vistoria')
    //   navigate('/escolha-ecv')
    // }

    setModalDadosSolicitanteVisible(true)
  }

  async function handleChange(e) {
    const { value } = e.target
    dispatch(Store.actions.setPlaca(value))
    if (value.replace(/[_]/g, '').length === 7) {
      await getVeiculoExistenteByPlaca(value)
    }
  }

  async function getVeiculoExistenteByPlaca(placa) {
    setLoading(true)
    try {
      const veiculo = await api.Veiculos.getVeiculoExistenteByPlaca({ placa })
      setVeiculo(veiculo)
    } catch (error) {
      setVeiculo(null)
    } finally {
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      <Layout>
        <Flex>
          <Logo />
          <UsuarioLogado aplicacao="preto" />
        </Flex>
        <ImagemCarro src="/vistoria-facil-carro.png" />
        <Container>
          <Title>
            Solicitação de vistoria
            <br />
            mais fácil!
          </Title>
          <Subtitle>
            Precisando vistoriar seu veículo?
            <br />
            <strong>Aqui é simples, rápido, fácil e seguro.</strong>
          </Subtitle>
          <ContainerInput>
            <InputMask
              formatChars={{ d: '[A-Za-z]', k: '[0-9A-Za-z]', n: '[0-9]' }}
              mask="dddnknn"
              placeholder="Placa do veículo"
              required
              value={placa}
              onChange={handleChange}
              data-cy="input-placa"
              onKeyDown={(e) => {
                if (e.key === 'Enter') submitPlaca()
              }}
            />
            <img src={iconSearch} />
            <Show when={loading}>
              <LoaderBig />
            </Show>
          </ContainerInput>
          <ContainerButton>
            <Button
              primary={true}
              onClick={() =>
                setTimeout(() => {
                  submitPlaca()
                })
              }
              data-cy="btn-buscar-placa"
            >
              Buscar placa &gt;
            </Button>
          </ContainerButton>
        </Container>
      </Layout>
      <LandingPageIntroducao />
      <LandingPageComoFunciona />
      <LandingPageBeneficios />
      <LandingPageCadastroVistoria />
      <LandingPageFooter />
      <ModalDadosSolicitante
        visible={modalDadosSolicitanteVisible}
        close={() => setModalDadosSolicitanteVisible(false)}
        isAdmin={usuarioLogado?.isAdmin}
      />
      <ModalFluxoVistoria
        placa={placa}
        getVeiculoExistenteByPlaca={getVeiculoExistenteByPlaca}
        setVistoriaEmAberto={setVistoriaEmAberto}
        vistoriaEmAberto={vistoriaEmAberto}
      />
    </React.Fragment>
  )
}
Home.Layout = styled.div`
  color: white;
  min-height: 90vh;
  background-size: contain;
  background-position-x: right;

  padding-left: 10vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  background: radial-gradient(50% 50% at 50% 50%, #f8ca50 0%, #f4b62d 100%);
  color: #122640;
  /* z-index: 1; */
  @media (min-width: 700px) {
    background-size: cover;
    background-position: center right;
    background-image: url('/vistoria-facil-bg.jpg');

    padding: 20px 20px 20px 10vw;
  }

  @media (max-width: 700px) {
    padding: 0;
  }
`
Home.Container = styled.div`
  @media (min-width: 700px) {
    max-width: 50vw;
  }
  @media (max-width: 700px) {
    padding: 0 24px;
  }
`
Home.TextoFacil = styled(TextoFacil)`
  width: 47px;
  height: 33px;
  position: relative;
  top: 9px;
`
Home.Title = styled.h1`
  margin: 0;
  font-size: 48px;
  line-height: 120%;
  font-weight: 700;
  @media (max-width: 700px) {
    font-size: 28px;
    margin-top: 12px;
  }
`
Home.Subtitle = styled.div`
  margin: 0;
  margin-top: 24px;
  font-size: 24px;
  line-height: 150%;
  font-weight: 400;
  strong {
    font-weight: 700;
  }
  @media (max-width: 700px) {
    font-size: 16px;
    margin-top: 12px;
  }
`
Home.ContainerInput = styled.div`
  margin-top: 60px;
  gap: 24px;
  display: flex;
  position: relative;
  img {
    position: absolute;
    top: 33px;
    left: 20px;
    width: 24px;
    height: 24px;
  }
  input {
    width: 600px;
    height: 90px;

    font-size: 40px;
    line-height: 120%;
    border-radius: 4px;
    padding-left: 64px;
    border: 1px solid #7f7f7d;
    max-width: 100%;
    &:focus {
      outline: none;
    }

    &:required:valid {
      text-transform: uppercase;
    }
  }
  @media (max-width: 700px) {
    margin-top: 24px;
    input {
      width: 100%;
      height: 64px;
      font-size: 26px;
      padding-left: 42px;
    }
    img {
      top: 18px;
      left: 10px;
    }
  }
`
Home.ContainerButton = styled('div')`
  margin-top: 48px;
  text-align: right;
  button {
    height: 44px;
    width: 213px;
    font-weight: 700;
    font-size: 20px;
  }
  @media (max-width: 700px) {
    margin-top: 16px;
    text-align: left;
  }
`
Home.Button = styled(Button)`
  background: #122640;
  color: white;
`
Home.Flex = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-right: 108px;
  @media (max-width: 700px) {
    padding: 0 24px;
    align-items: center;
  }
`
Home.Logo = styled(Logo)`
  @media (max-width: 700px) {
    width: 80px;
  }
`
Home.ImagemCarro = styled.img`
  width: 100%;
  @media (min-width: 700px) {
    display: none;
  }
`
Home.Rodape = styled.div`
  width: 100%;
  .mobile {
    text-align: center;
    font-size: 12px;
  }
  @media (min-width: 700px) {
    .mobile {
      display: none;
      text-align: center;
      font-size: 12px;
    }
  }
  @media (max-width: 701px) {
    .desktop {
      display: none;
    }
  }
`

const LandingPageIntroducao = () => {
  const { Layout, Flex } = LandingPageIntroducao

  return (
    <Layout>
      <Flex>
        <LandingPageIntroContent />
        <img className="intro-lp-logo" src={introLpVistoriaLogo} />
      </Flex>
    </Layout>
  )
}

LandingPageIntroducao.Layout = styled.div`
  background-color: #fffbee;
  padding: 40px 0px;
`

LandingPageIntroducao.Flex = styled.div`
  display: flex;
  max-width: 1000px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  .intro-lp-logo {
    max-width: 100%;
  }
  @media (max-width: 1000px) {
    flex-direction: column-reverse;
    width: auto;
    margin: 0px 50px;
  }
`

const LandingPageIntroContent = () => {
  const { Layout } = LandingPageIntroContent

  return (
    <Layout>
      <img src={introLpVistoriaIcon} />
      <div className="divider-lp-vistoria-intro" />
      <span className="title-lp-vistoria-intro">
        Sistema de solicitações e pagamentos de vistorias veiculares.
      </span>
      <span className="text-lp-vistoria-intro">
        O Vistoria Fácil conecta quem deseja realizar vistoria veicular e ECV (Empresa Credenciada
        de Vistoria) no objetivo de automatizar todo o processo de selecionar o local de vistoria,
        pagamento e agendamento de serviço.
        <br />
        <br />
        Devidamente credenciadas jutos ao DETRAN-GO, atuamos em todo o Estado de Goiás, com gestão
        em Goiânia-GO pela Hyperpay Serviços de Pagamentos.
      </span>
    </Layout>
  )
}

LandingPageIntroContent.Layout = styled.div`
  display: grid;
  gap: 12px;
  .divider-lp-vistoria-intro {
    width: 80px;
    background-color: #122640;
    height: 3px;
    border-radius: 40px;
  }
  .title-lp-vistoria-intro {
    font-size: 22px;
    color: #122640;
  }
  .text-lp-vistoria-intro {
    font-size: 15px;
    color: #545451;
  }
`

const LandingPageComoFunciona = () => {
  const { Layout, Flex, ContainerButton } = LandingPageComoFunciona

  return (
    <Layout>
      <Flex>
        <BlocoComoFuncionaInicial
          iconSvg={comoFuncionaIcon}
          text="É simples, rápido, fácil e seguro"
        />
        <BlocoComoFunciona
          iconSvg={selecioneEcvIcon}
          title="Selecione ECV"
          text="Pesquise e selecione a ECV (Empresa Credenciada de Vistoria) para próxima de você"
          color="#12A8EC"
        />
        <BlocoComoFunciona
          iconSvg={pagueCobrancaIcon}
          title="Pague a cobrança"
          text="Escolha a melhor forma de pagamento, seja por cartão de crédito, Pix ou boleto"
          color="#239C39"
        />
        <BlocoComoFunciona
          iconSvg={agendeHorarioIcon}
          title="Agende seu horário"
          text="Defina a data e hora de seu interesse e agende sua vistoria"
          color="#239C39"
        />
        <BlocoComoFunciona
          iconSvg={realizeVistoriaIcon}
          title="Realize a vistoria"
          text="Na data e horário agendados, compareça ao local escolhido, para a realização da vistoria"
          color="#FFBC01"
        />
        <BlocoComoFunciona
          iconSvg={acesseLaudoIcon}
          title="Acesse o laudo"
          text="Em breve"
          color="#12A8EC"
        />
      </Flex>
      <ContainerButton>
        <Button primary onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          Solicitar Vistoria
        </Button>
      </ContainerButton>
    </Layout>
  )
}

LandingPageComoFunciona.Layout = styled.div`
  background-color: #ffffff;
  padding: 40px 0px;
`

LandingPageComoFunciona.Flex = styled.div`
  display: flex;
  justify-content: center;
  padding: 6px;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1000px;
  margin: 0 auto;
`

LandingPageComoFunciona.ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`

const BlocoComoFunciona = ({
  iconSvg,
  title,
  text,
  color,
  ...props
}: {
  iconSvg: any
  title?: string
  text: string
  color?
}) => {
  const { Layout, Grid } = BlocoComoFunciona

  return (
    <Layout color={color} {...props}>
      <Grid className="content-card-cf">
        <div className="icon-img-cf">
          <img src={iconSvg} />
        </div>

        <div className="title-cf">
          <h2>{title}</h2>
        </div>

        <div className="descricao-cf">
          <div className="divider-cf" />
          <span>{text}</span>
        </div>
      </Grid>
    </Layout>
  )
}

BlocoComoFunciona.Layout = styled.div<{ color }>(
  ({ color }) => `
  padding: 13px 18px;
  border: 1px solid #D4D4D4;
  width: 300px;
  height: 225px;
  border-radius: 20px;
  transition: .25s all;
  &:hover{
    ${`border: 1px solid ${color};`}
  }
`
)

BlocoComoFunciona.Grid = styled.div`
  display: grid;
  align-items: center;
  gap: 3px;
  box-sizing: border-box;
  .icon-img-cf {
    width: 40px;
    height: 40px;
  }
  .title-cf {
    font-size: 16px;
    color: #122640;
  }
  .descricao-cf {
    font-size: 15px;
    color: #545451;
  }
`

const BlocoComoFuncionaInicial = styled(BlocoComoFunciona)`
  border: none;
  .content-card-cf {
    height: 100%;
    align-items: start;
    gap: 20px;
    box-sizing: border-box;
  }
  .title-cf {
    font-size: 16px;
    color: #122640;
    display: none;
  }
  .descricao-cf {
    .divider-cf {
      width: 80px;
      background-color: #122640;
      height: 3px;
      margin-bottom: 8px;
      border-radius: 40px;
    }
    span {
      font-size: 24px;
      font-weight: 380;
      color: #545451;
    }
  }
`

const LandingPageBeneficios = () => {
  const { Layout, Flex } = LandingPageBeneficios

  return (
    <Layout>
      <TitleBenefios />
      <Flex>
        <ContainerBlocoBeneficios />
        <ContaineirImagesBeneficios />
      </Flex>
    </Layout>
  )
}

LandingPageBeneficios.Layout = styled.div`
  background-color: #f4f4f4;
  padding: 40px 10px;
`

LandingPageBeneficios.Flex = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  @media (max-width: 1000px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`

const TitleBenefios = () => {
  const { Layout } = TitleBenefios

  return (
    <Layout>
      <div className="content-title-beneficios">
        <img src={beneficiosIcon} />
        <div className="divider-beneficios-lp" />
        <span className="title-beneficios-lp">
          Sistema de solicitações e pagamentos de vistorias veiculares.
        </span>
      </div>
    </Layout>
  )
}

TitleBenefios.Layout = styled.div`
  display: grid;
  justify-content: center;
  .divider-beneficios-lp {
    width: 80px;
    height: 3px;
    background-color: #122640;
    border-radius: 30px;
  }
  .content-title-beneficios {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  span {
    text-align: center;
    color: #292926;
    font-size: 20px;
  }
`

const ContaineirImagesBeneficios = () => {
  const { Layout } = ContaineirImagesBeneficios
  return (
    <Layout>
      <img className="img-sistem-sol-lp" src={logoSmartSistem} />
    </Layout>
  )
}

ContaineirImagesBeneficios.Layout = styled.div`
  .img-sistem-sol-lp {
    max-width: 100%;
  }
`

const ContainerBlocoBeneficios = () => {
  const { Layout } = ContainerBlocoBeneficios

  return (
    <Layout>
      <BlocoBeneficios
        iconSvg={iconCalendar}
        title="Otimização de processos"
        text="Além da comunicação direta com as UGC’s, nossas soluções oferecem ferramentas totalmente automatizadas,
        desde o agendamento à emissão do laudo, em todos os processos de uma vistoria."
      />
      <BlocoBeneficios
        iconSvg={iconCalendar}
        title="Facilidade para pagar"
        text="É fácil até na hora de pagar. Escolha a forma de pagamento que seja
        mais confortável para você: Boleto, Pix ou cartão de crédito."
      />
      <BlocoBeneficios
        iconSvg={iconCalendar}
        title="Pagamentos inteligentes"
        text="Nossos pagamentos são inteligentes, pois quando detectamos um pagamento em nossa base,
        o sistema realiza os repasses de pagamento a cada parte envolvida no processo."
      />
      <BlocoBeneficios
        iconSvg={iconCalendar}
        title="Suporte humanizado"
        text="Conte com um suporte feito por gente de verdade. Estamos prontos para te ajudar com o que precisar."
      />
      <BlocoBeneficios
        iconSvg={iconCalendar}
        title="Painel Administrativo"
        text="Tenha controle administrativo e financeiro, com status de vistorias, pagamento e laudos técnicos."
      />
    </Layout>
  )
}

ContainerBlocoBeneficios.Layout = styled.div`
  padding: 10px;
  width: 600px;
  & > *:not(:first-of-type) {
    margin-top: 20px;
  }
  @media (max-width: 600px) {
    width: auto;
  }
`

const BlocoBeneficios = ({
  title,
  text,
  iconSvg,
  ...props
}: {
  title: string
  text: string
  iconSvg
}) => {
  const { Layout, Flex } = BlocoBeneficios

  return (
    <Layout {...props}>
      <Flex>
        <img src={iconSvg} width={35} />
        <span className="title-lp-beneficios">{title}</span>
      </Flex>
      <span className="text-lp-beneficios">{text}</span>
    </Layout>
  )
}

BlocoBeneficios.Layout = styled.div`
  display: grid;
  gap: 15px;
  .text-lp-beneficios {
    color: #545451;
    font-size: 15px;
    font-weight: 400;
  }
`
BlocoBeneficios.Flex = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  .title-lp-beneficios {
    color: #292926;
    font-size: 19px;
    font-weight: 600;
  }
`

const LandingPageCadastroVistoria = () => {
  const { Layout, Button } = LandingPageCadastroVistoria

  return (
    <Layout>
      <div>
        <span>Deseja cadastrar sua ECV no Vistoria Fácil?</span>
        <Button
          primary
          onClick={() => window.open('https://app.pipefy.com/public/form/6-KIXtt-', '_blank')}
        >
          Cadastrar agora
        </Button>
      </div>
    </Layout>
  )
}

LandingPageCadastroVistoria.Layout = styled.div`
  background-size: cover;
  height: 235px;
  background-image: url(${cadastroLpImg});
  display: grid;
  align-items: center;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 0 20px;
    span {
      color: #122640;
      font-size: 30px;
      font-weight: 650;
    }
  }
`
LandingPageCadastroVistoria.Button = styled(Button)`
  border: 1px solid #122640;
  display: block;
  font-size: 17px;
`

const LandingPageFooter = () => {
  const { Layout, Logo, Grid } = LandingPageFooter

  return (
    <Layout>
      <Grid>
        <div className="logo-footer">
          <Logo />
        </div>

        <div className="sobrenos-footer">
          <span className="sobrenos-footer-title">Sobre nós</span>
          <span className="sobrenos-footer-text">
            Somos uma empresa especializada em soluções de solicitação e pagamento de vistorias
            veiculares em todo o Estado de Goiás, fazendo o elo entre o solicitante e as ECV’s
            devidamente credenciadas junto ao DETRAN- GO.
          </span>
        </div>

        <div className="contato-footer">
          <span className="contato-footer-title">Contato</span>
          <span className="contato-footer-email">contato@hyperpay.com.br</span>
          <span className="contato-footer-links-title">Links externos</span>
          <div className="contato-footer-links">
            <a href="/politica-de-privacidade" target="_blank" rel="noreferrer">
              Politica de privacidade
            </a>
            <a href="/termos-de-uso" target="_blank" rel="noreferrer">
              Termos de uso
            </a>
          </div>
        </div>

        <div className="formapg-footer">
          <span>Forma de Pagamento</span>
          <img src={groupFormaPgIcon} />
        </div>
      </Grid>
    </Layout>
  )
}

LandingPageFooter.Layout = styled.div`
  background-color: #122640;
  padding: 40px 100px;
`

LandingPageFooter.Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: 'logo-footer sobrenos-footer contato-footer formapg-footer';
  gap: 40px;
  & > * {
    height: fit-content;
    color: #ffffff;
  }
  .logo-footer {
    grid-area: logo-footer;
    display: flex;
    justify-content: center;
  }
  .sobrenos-footer {
    grid-area: sobrenos-footer;
    display: grid;
    gap: 20px;
    .sobrenos-footer-title {
      font-weight: bold;
    }
    .sobrenos-footer-text {
      font-size: 13px;
      font-weight: 330;
    }
  }
  .contato-footer {
    grid-area: contato-footer;
    display: grid;
    gap: 10px;
    justify-content: center;
    & > *:nth-of-type(odd) {
      font-weight: bold;
    }
    .contato-footer-email {
      font-size: 14px;
    }
    .contato-footer-links {
      display: grid;
      gap: 7px;
    }
    a {
      cursor: pointer;
      font-size: 13px;
      font-weight: 330;
      text-decoration: none;
      color: #ffffff;
    }
  }
  .formapg-footer {
    grid-area: formapg-footer;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    span {
      font-weight: bold;
    }
  }
  @media (min-width: 620px) and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'sobrenos-footer logo-footer'
      'contato-footer formapg-footer';
    padding: 10px;
    .sobrenos-footer,
    .contato-footer {
      text-align: center;
    }
  }
  @media (max-width: 620px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'sobrenos-footer'
      'contato-footer'
      'logo-footer'
      'formapg-footer';
    padding: 10px;
    .sobrenos-footer,
    .contato-footer {
      text-align: center;
    }
  }
`
LandingPageFooter.Logo = styled(LogoAmarelo)`
  width: 140px;
`

const ModalDadosSolicitante = ({ visible, close, isAdmin }) => {
  const { Title, Subtitle, Divider, Heading } = ModalDadosSolicitante
  const dispatch = Store.useDispatch()
  const navigate = useNavigate()

  const handleSuccessDadosSolicitante = ({
    cliente,
    vistoria,
    cobranca,
  }: {
    cliente: Schemas.Cliente
    vistoria?: Schemas.Vistoria
    cobranca?
  }) => {
    dispatch(Store.actions.getOrCreateClienteSuccess(cliente))
    if (vistoria?.id) {
      dispatch(Store.actions.createVistoriaSuccess({ vistoria, cobranca }))
      navigate('/pagamento')
    } else {
      navigate('/escolha-ecv')
    }
  }

  return (
    <Modal width={696} visible={visible} onClose={close}>
      <Title>Solicitação de vistoria veicular</Title>
      <Subtitle>Insira os dados do solicitante para prosseguir com a busca da placa</Subtitle>
      <Divider />
      <Heading>Dados do solicitante</Heading>

      <DadosSolicitante
        fechar={close}
        onSuccess={handleSuccessDadosSolicitante}
        finalizacaoCadastro={false}
        cadastroPelaSolicitacao
      />
    </Modal>
  )
}
ModalDadosSolicitante.Title = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  text-align: center;
  color: #122640;
`
ModalDadosSolicitante.Subtitle = styled.div`
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #122640;
  margin-top: 8px;
`
ModalDadosSolicitante.Divider = styled.div`
  margin-top: 32px;
  border-bottom: 1px solid #d4d4d4;
`
ModalDadosSolicitante.Heading = styled.div`
  font-size: 24px;
  font-weight: bold;
  line-height: 120%;
  margin-top: 24px;
`

const ModalFluxoVistoria = ({
  vistoriaEmAberto,
  setVistoriaEmAberto,
  getVeiculoExistenteByPlaca,
  placa,
}: {
  vistoriaEmAberto: Schemas.Vistoria | null
  setVistoriaEmAberto: (value) => void
  getVeiculoExistenteByPlaca: (placa) => void
  placa: string
}) => {
  return (
    <React.Fragment>
      <Modal
        visible={!!vistoriaEmAberto}
        width={1400}
        onClose={() => {
          setVistoriaEmAberto(null)
          void getVeiculoExistenteByPlaca(placa)
        }}
      >
        <FluxoSolicitacao vistoria={vistoriaEmAberto} role={'HOME'} />
      </Modal>
    </React.Fragment>
  )
}

const LoaderBig = styled.div`
  border: 8px solid #011425;
  border-radius: 50%;
  border-top: 8px solid transparent;
  width: 48px;
  height: 48px;
  animation: spin 2s linear infinite;
  position: absolute;
  right: 20px;
  top: 20px;
`
