import React, { useState } from 'react'
import styled from '@emotion/styled'
import { UsuarioLogado } from '../../../components/UsuarioLogado'
import { PassoAPassoVistoria } from '../components/PassoAPassoVistoria'
import logo from '../../../assets/logo-invertida.svg'
import { useNavigate } from 'react-router'
import { useWindowWidth } from '../../../components/hooks/use-window-width'
import AnimateHeight from 'react-animate-height'
import { Show } from '../../../components/Show'
import chevronDown from '../../../assets/icon-chevron-down-black.svg'
import { LogoMarcaVeiculo } from '../../../components/LogoMarcaVeiculo'
import { NovaVistoriaStore as Store } from '../NovaVistoriaStore'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Button } from '../../../components/Button'
import { Modal } from '../../../components/Modal'
import toast from 'react-hot-toast'
import { AuthStore, getUsuarioLogado } from '../../auth/AuthStore'
import { api } from '../../../components/Api'

export const NovaVistoriaContainer = ({
  passoAtual,
  children,
  ...props
}: { passoAtual } & React.HTMLAttributes<HTMLDivElement>) => {
  const {
    Layout,
    Topo,
    Logo,
    Flex,
    ContainerPassoAPasso,
    ContainerConteudo,
    ButtonContainer,
    ButtonSideBar,
  } = NovaVistoriaContainer
  const { vistoria } = Store.useState()
  const navigate = useNavigate()
  const windowWidth = useWindowWidth()
  const { token } = AuthStore.useState()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const usuarioLogado = AuthStore.useSelector(getUsuarioLogado)
  return (
    <Layout {...props}>
      <Topo>
        <Logo src={logo} onClick={() => navigate('/')} />
        <UsuarioLogado aplicacao="preto" />
      </Topo>
      <Flex>
        <ContainerPassoAPasso>
          <PassoAPassoVistoria passoAtual={passoAtual} />
          <Show when={!!token && !!vistoria.cobrancaId && !vistoria.dataPagamentoCobranca}>
            <ButtonContainer>
              <ButtonSideBar onClick={() => setIsModalVisible(true)}>
                Cancelar Solicitação
              </ButtonSideBar>
              <Link style={{ textDecoration: 'none' }} to={'/'}>
                <ButtonSideBar>Voltar à tela Inicial</ButtonSideBar>
              </Link>
            </ButtonContainer>
          </Show>
        </ContainerPassoAPasso>
        <ContainerConteudo>{children}</ContainerConteudo>
        <Rodape passoAtual={passoAtual} />
        <Modal
          width={400}
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          style={{ padding: 24 }}
        >
          <ModalCancelamentoSolicitacao close={() => setIsModalVisible(false)} />
        </Modal>
      </Flex>
    </Layout>
  )
}
NovaVistoriaContainer.Layout = styled.div`
  min-height: 100vh;
`
NovaVistoriaContainer.ButtonContainer = styled.div`
  margin-top: 40px;
  justify-content: center;
  display: grid;
`

NovaVistoriaContainer.ButtonSideBar = styled.div`
  border: 1px solid;
  border-radius: 8px;
  height: 35px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  text-align: center;
  background: #fff;
  color: #0d0e0f;
  font-weight: bold;
  font-size: 13px;
  place-content: center;

  cursor: pointer;
  &:hover {
    background: #0d0e0f;
    color: #fff;
  }
  &:last-child {
    margin-top: 10px;
  }
`

NovaVistoriaContainer.Topo = styled.div`
  height: 102px;
  border-bottom: 1px solid #d4d4d4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 0 120px;
  @media (max-width: 600px) {
    height: 68px;
    padding: 0 8px 0 8px;
  }
`
NovaVistoriaContainer.Logo = styled.img`
  cursor: pointer;
  @media (max-width: 600px) {
    width: 80px;
  }
`
NovaVistoriaContainer.ContainerPassoAPasso = styled.div`
  grid-area: passo-a-passo;
  padding: 24px;
  overflow: auto;
  border-right: 1px solid #d4d4d4;
  @media (max-width: 600px) {
    display: none;
  }
`
NovaVistoriaContainer.Flex = styled.div`
  display: flex;
  height: calc(100vh - 102px);
  @media (max-width: 600px) {
    height: calc(100vh - 68px);
  }
`
NovaVistoriaContainer.ContainerConteudo = styled.div`
  flex: 1;
  height: 100%;
  overflow: auto;
`

const ModalCancelamentoSolicitacao = ({ close }) => {
  const { Layout, Heading, SubHeading, Button, ButtonContainer } = ModalCancelamentoSolicitacao
  const { vistoria } = Store.useState()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  async function handleCancelarSolicitacao() {
    try {
      setLoading(true)
      const response: any = await api.Vistorias.cancelaVistoria({
        vistoriaId: vistoria.id,
      })
      if (response.success) {
        navigate('/adm')
        return toast.success('Solicitação cancelada')
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      return toast.error('Falha ao cancelar solicitação')
    }
  }

  return (
    <Layout>
      <SubHeading>Tem certeza que deseja cancelar esta solicitação?</SubHeading>
      <ButtonContainer>
        <Button primary loading={loading} onClick={handleCancelarSolicitacao}>
          Sim
        </Button>
        <Button primary onClick={close}>
          Não
        </Button>
      </ButtonContainer>
    </Layout>
  )
}

ModalCancelamentoSolicitacao.Layout = styled.div`
  width: 100%;
  color: #545451;
`
ModalCancelamentoSolicitacao.Heading = styled.div`
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  color: #122640;
  text-align: center;
`
ModalCancelamentoSolicitacao.SubHeading = styled.div`
  font-size: 16px;
  color: #122640;
  text-align: center;
  margin-top: 16px;
`
ModalCancelamentoSolicitacao.Button = styled(Button)`
  width: 40%;
  margin-top: 24px;
  height: 36px;
  font-size: 15px;
  text-align: center;
`
ModalCancelamentoSolicitacao.ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`

const Rodape = ({ passoAtual }) => {
  const { Layout, RodapeContainer, PassoAPassoVistoria, LogoMarcaVeiculo, Flex } = Rodape
  const [passoAPassoVisible, setPassoAPassoVisible] = useState(false)
  const {
    vistoria: {
      veiculo: { marcaModelo, placa },
    },
  } = Store.useState()
  return (
    <Layout>
      <RodapeContainer onClick={() => setPassoAPassoVisible(!passoAPassoVisible)}>
        <Flex>
          <LogoMarcaVeiculo marca={marcaModelo} />
          <div>
            <span>Placa</span>
            <strong>{placa}</strong>
          </div>
        </Flex>
        <img src={chevronDown} style={!passoAPassoVisible ? { transform: 'rotate(180deg)' } : {}} />
      </RodapeContainer>
      <AnimateHeight height={passoAPassoVisible ? 'auto' : 0} duration={300}>
        <div style={{ width: '100%', padding: 24 }}>
          <PassoAPassoVistoria passoAtual={passoAtual} />
        </div>
      </AnimateHeight>
    </Layout>
  )
}
Rodape.Layout = styled.div`
  @media (min-width: 601px) {
    display: none;
  }
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  max-height: calc(100vh - 116px);
  overflow: auto;
`
Rodape.Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`
Rodape.RodapeContainer = styled.div`
  justify-content: space-between;
  padding: 12px;
  border-top-left-radius: 8px;
  background: #f3f3f3;
  box-shadow: -1px -1px 3px rgb(0 0 0 / 20%);
  display: flex;
  span {
    display: block;
    font-size: 12px;
  }
  strong {
    font-size: 18px;
  }
`
Rodape.PassoAPassoVistoria = styled(PassoAPassoVistoria)`
  width: 100%;
  max-width: 380px;
  margin: 0 auto;
`
Rodape.LogoMarcaVeiculo = styled(LogoMarcaVeiculo)`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 0.5px solid #d4d4d4;
  border-radius: 50%;
  img {
    width: 14px;
  }
`
