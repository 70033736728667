import React, { useEffect, useState } from 'react'
import { ReciboComponent } from './components/ReciboComponent'
import { NovaVistoriaContainer } from './nova-vistoria/NovaVistoriaContainer'
import { NovaVistoriaStore as Store } from './NovaVistoriaStore'
import styled from '@emotion/styled'
import { Modal } from '../../components/Modal'
import iconError from '../../assets/icon-error.svg'
import iconAgendar from '../../assets/icon-agendar.svg'
import { Button } from '../../components/Button'
import { AuthStore } from '../auth/AuthStore'
import { useNavigate } from 'react-router'

export const ComprovanteAgendamento = () => {
  const { vistoria } = Store.useState()
  const { token } = AuthStore.useState()
  const navigate = useNavigate()
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => { if (!token) navigate('/') }, [token])

  return (
    <NovaVistoriaContainer passoAtual={6}>
      <HeaderComprovanteAgendamento/>
        <ReciboComponent selectedVistoria={vistoria}/>
        <Modal visible={isModalVisible} onClose={() => setIsModalVisible(false)} width={400}>
          <CofirmAlertVistoria/>
        </Modal>
    </NovaVistoriaContainer>
  )
}

const HeaderComprovanteAgendamento = () => {
  const { Layout, Title, SubTitle } = HeaderComprovanteAgendamento

  return (
    <Layout>
      <Title>Agendamento confirmado</Title>
      <SubTitle>
        A partir de agora você já pode comparecer no local escolhido para realizar sua vistoria.
        Procure chegar no horário agendado. O tempo máximo de tolerância para atraso é de no máximo 30 minutos.
      </SubTitle>
    </Layout>
  )
}
HeaderComprovanteAgendamento.Layout = styled.div`
  display: grid;
  margin: 0 auto;
  text-align: center;
  max-width: 900px;
  margin-top: 20px;
`
HeaderComprovanteAgendamento.Title = styled.span`
  color: #122640;
  font-size: 23px;
  margin-bottom:20px;
  font-weight: bold;
`
HeaderComprovanteAgendamento.SubTitle = styled.span`
  color: #292926;
  padding: 0 10px;
`

const CofirmAlertVistoria = () => {
  return (
  <ConfirmAlertReprovada />
  )
}

CofirmAlertVistoria.Layout = styled.div`
  display: flex;
  flex-direction: column;
`

const ConfirmAlertReprovada = () => {
  const { vistoria } = Store.useState()
  const { cliente, veiculo, ecv } = vistoria
  const { Layout, Grid, Title, SubTitleEnfatico, SubTitleSingelo, Content, ButtonsContainer, Button } = ConfirmAlertReprovada

  return (
    <Layout>
      <Grid>
        <img src={iconError}/>
        <Title>Vistoria reprovada!</Title>
        <SubTitleEnfatico>O solicitante tem 30 dias após a data de reprovação para renovar o pedido de forma gratuita.</SubTitleEnfatico>
        <SubTitleSingelo>Caso perca o prazo ou seja novamente reprovado, será necessário um novo pedido.</SubTitleSingelo>
      </Grid>
      <Content>
        <div>Solicitante: <strong>{cliente.nome}</strong></div>
        <div>
        {cliente.cpfCnpj?.length === 14 ? 'CPF' : 'CNPJ'}: <strong>{cliente.cpfCnpj}</strong>
        </div>
        <div>
        Placa: <strong>{veiculo.placa.toUpperCase()}</strong>
        </div>
        <div>
          Marca/Modelo: <strong>{veiculo.marcaModelo}</strong>
        </div>
        <div>
          Vistoriadora: <strong>{ecv.nome}</strong><br />
          <small>{ecv.endereco} {ecv.bairro}</small>
        </div>
      </Content>
      <ButtonsContainer>
        <Button>Fechar</Button>
        <Button primary>Reagendar</Button>
      </ButtonsContainer>
    </Layout>
  )
}

ConfirmAlertReprovada.Layout = styled.div`
  display: grid;
  gap: 20px;
`
ConfirmAlertReprovada.Grid = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: center;
  border-bottom: 1px solid #D4D4D4;
  img{
    width: 50px;
    margin-bottom: 20px;
  }
  span {
    text-align: center;
  }
`
ConfirmAlertReprovada.Title = styled.span`
  font-size: 18px;
  color: #292926;
  font-weight: 500;
`
ConfirmAlertReprovada.SubTitleEnfatico = styled.span`
  color: #545451;
  font-size: 14px;
  font-weight: 400;
`

ConfirmAlertReprovada.SubTitleSingelo = styled.span`
  color: #545451;
  font-size: 14px;
`

ConfirmAlertReprovada.Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  font-size: 15px;
  div {
    color: #545451;
    font-weight: 600;
    font-size: 14px;
  }
  strong {
    font-weight: 400;
  }
  small {
    font-weight: 300;
  }
`

ConfirmAlertReprovada.ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
`
ConfirmAlertReprovada.Button = styled(Button)`
  text-align: center;
  font-weight: 500;
`
