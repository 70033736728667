import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { NovaVistoriaStore as Store } from './NovaVistoriaStore'
import { useNavigate } from 'react-router-dom'
import { HyperpayCobranca } from '@hyperdev-tech/hyperpay-cobranca'
import logo from '../../assets/logo-invertida.svg'
import { PassoAPassoVistoria } from './components/PassoAPassoVistoria'
import { darken } from 'polished'
import { formatValor } from '../../components/format-valor'
import { UsuarioLogado } from '../../components/UsuarioLogado'
import { NovaVistoriaContainer } from './nova-vistoria/NovaVistoriaContainer'
import { AuthStore } from '../auth/AuthStore'
import { ComprovantePagamento } from './ComprovantePagamento'
import { Button } from '../../components/Button'
import { handleClickWhatsapp } from './components/ReciboComponent'
import iconWhatsapp from '../../assets/icon-whatsapp.svg'
import { Show } from '../../components/Show'

export const Pagamento = () => {
  const { vistoria } = Store.useState()
  const navigate = useNavigate()

  useEffect(() => {
    // if (!vistoria?.cobrancaId) navigate('/escolha-ecv')
    if (!vistoria?.cobrancaId) navigate('/')
  }, [vistoria?.cobrancaId, vistoria?.dataPagamentoCobranca])

  const conditionPagamento = !!vistoria?.dataPagamentoCobranca

  return (
    <NovaVistoriaContainer passoAtual={4}>
      <Show when={conditionPagamento}>
        <ComprovantePagamento />
      </Show>
      <Show when={!conditionPagamento}>
        <PagamentoContainer />
      </Show>
    </NovaVistoriaContainer>
  )
}

const PagamentoContainer = () => {
  const {
    getCobrancaEnv,
    ContainerValor,
    Divider,
    ContainerEcv,
    HyperpayCobranca,
    Heading,
    Subheading,
    ButtonWhatsapp,
    TextWarning,
  } = PagamentoContainer
  const dispatch = Store.useDispatch()
  const cobrancaEnv = getCobrancaEnv()
  const { vistoria } = Store.useState()

  useEffect(() => {
    if (vistoria?.cobrancaId && !vistoria.dataPagamentoCobranca)
      dispatch(Store.thunks.checkPagamento())
  }, [vistoria?.cobrancaId])

  function handleCobrancaSuccess() {
    dispatch(Store.thunks.checkPagamento())
  }

  return (
    <>
      <Heading>Seja bem vindo ao ambiente de pagamento Vistoria Fácil</Heading>
      <Subheading>Selecione a forma de pagamento desejada!</Subheading>
      <TextWarning>
        Você tem até 30 dias a partir da data de emissão dessa solicitação para realizar o
        pagamento. Após essa data a solicitação será finalizada automaticamente.
      </TextWarning>
      <TextWarning>
        Caso deseje alterar esta solicitação para outra ECV, veículo ou outro motivo, basta cancelar
        sua solicitação.
      </TextWarning>
      <Divider />
      <Subheading>Cobrança {vistoria?.cobrancaId}</Subheading>

      <ContainerValor>Valor: {formatValor(vistoria?.valorCobranca)}</ContainerValor>
      <ContainerEcv>{vistoria?.ecv?.nome}</ContainerEcv>
      <HyperpayCobranca
        cobrancaId={vistoria?.cobrancaId}
        token="asdfasdf"
        env={cobrancaEnv}
        onCobrancaSuccess={handleCobrancaSuccess}
      />
      <ButtonWhatsapp onClick={() => handleClickWhatsapp(vistoria)}>
        Enviar via WhatsApp <img src={iconWhatsapp} />
      </ButtonWhatsapp>
    </>
  )
}

PagamentoContainer.getCobrancaEnv = (): any => {
  if (process.env.REACT_APP_ENV) return process.env.REACT_APP_ENV
  return process.env.NODE_ENV
}
PagamentoContainer.Layout = styled.div``
PagamentoContainer.Topo = styled.div`
  height: 102px;
  border-bottom: 1px solid #d4d4d4;
`
PagamentoContainer.Logo = styled.img`
  margin: 18px 0 18px 120px;
`
PagamentoContainer.Grid = styled.div`
  display: grid;
  height: calc(100vh - 102px);
  grid-template-columns: 276px 1fr;
`
PagamentoContainer.ContainerPassoAPasso = styled.div`
  padding: 24px;
  overflow: auto;
`
PagamentoContainer.ContainerPagamento = styled.div`
  border-left: 1px solid #d4d4d4;
`
PagamentoContainer.Heading = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  text-align: center;
  color: #122640;
  max-width: 490px;
  margin: 0 auto;
  margin-top: 36px;
  padding: 0 10px;
`
PagamentoContainer.Subheading = styled.div`
  color: #545451;
  font-size: 16px;
  text-align: center;
  margin-top: 24px;
`

PagamentoContainer.TextWarning = styled.div`
  color: #122640;
  font-size: 13px;
  text-align: center;
  margin-top: 24px;
  display: flex;
  font-weight: bold;
  justify-content: center;
`

PagamentoContainer.ContainerValor = styled.div`
  margin-top: 32px;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  color: #2043a1;
`
PagamentoContainer.Divider = styled.div`
  border-bottom: 1px solid #d4d4d4;
  max-width: 564px;
  margin: 0 auto;
  margin-top: 24px;
`
PagamentoContainer.ContainerEcv = styled.div`
  font-size: 16px;
  color: #545451;
  text-align: center;
  font-weight: bold;
`
PagamentoContainer.HyperpayCobranca = styled(HyperpayCobranca)`
  --primary-color: #ffbc01;
  --primary-color-shade: ${darken(0.1, '#FFBC01')};
  --titles-color: white;
  --text-color: #545451;

  font-family: 'Inter', 'Molde', sans-serif;

  .layout {
    border-radius: 8px;
  }
  .meio-pagamento-pix {
    border-radius: 8px 8px 0 0;
  }
  .meio-pagamento-cartao_credito {
    border-radius: 0 0 8px 8px;
  }
  button {
    font-weight: 600;
    height: 35px;
  }
  .heading-container {
    background: none;
    margin-top: 0;
    .heading {
      display: none;
    }
  }
  .instrucao {
    display: none;
  }
  .title {
    font-weight: 600;
  }
  .codigo-seguranca {
    width: 145px !important;
  }
  .data-expiracao {
    width: 125px !important;
  }
  .email-titular {
    width: 280px !important;
  }
  .codigo-barras {
    max-width: calc(100vw - 40px);
  }
`

PagamentoContainer.Button = styled(Button)`
  display: flex;
  align-items: center;
  gap: 10px;
`
PagamentoContainer.ButtonWhatsapp = styled(PagamentoContainer.Button)`
  border-color: #239c39;
  color: #239c39;
  margin: auto;
`
