import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { NovaVistoriaStore as Store } from './NovaVistoriaStore'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/logo-invertida.svg'
import { PassoAPassoVistoria } from './components/PassoAPassoVistoria'
import { ReciboComponent } from './components/ReciboComponent'
import { Button } from '../../components/Button'
import iconLogo from '../../assets/icon-logo.svg'
import { UsuarioLogado } from '../../components/UsuarioLogado'
import { NovaVistoriaContainer } from './nova-vistoria/NovaVistoriaContainer'

export const Recibo = () => {
  const { Layout, Topo, Logo, Grid, ContainerPassoAPasso, ContainerRecibo, SubHeadingWarning, Heading, Subheading } = Recibo
  const { vistoria, cobranca } = Store.useState()
  const navigate = useNavigate()

  useEffect(() => {
    // if (!vistoria.cobrancaId) navigate('/escolha-ecv')
    if (!vistoria.cobrancaId) navigate('/')
    if (cobranca && !cobranca.dataPagamento) navigate('/pagamento')
  }, [])

  return (
    <NovaVistoriaContainer passoAtual={4}>

      <Heading data-cy="title-pagamento-efetuado">
        Comprovante de pagamento
      </Heading>
      <Subheading>
        A partir de agora você já pode comparecer no local escolhido para realizar sua vistoria.<br />
        A vistoria acontece por ordem de chegada. Consulte o horário de funcionamento!
      </Subheading>
      <SubHeadingWarning>
        Caso o veículo seja reprovado na vistoria, o proprietário terá até 30 dias corridos para realizar uma nova vistoria gratuita.<br />
        Caso perca o prazo ou seja novamente reprovado, será necessário iniciar uma nova solicitação, gerando uma nova cobrança.
      </SubHeadingWarning>

      <PassosExecucaoVistoria />

      <ReciboComponent selectedVistoria={vistoria} />

    </NovaVistoriaContainer>
  )
}
Recibo.Layout = styled.div`

`
Recibo.Topo = styled.div`
  height: 102px;
  border-bottom: 1px solid #d4d4d4;
`
Recibo.Logo = styled.img`
  margin: 18px 0 18px 120px;
`
Recibo.Grid = styled.div`
  display: grid;
  height: calc(100vh - 102px);
  grid-template-columns: 276px 1fr;
`
Recibo.ContainerPassoAPasso = styled.div`
  padding: 24px;
  overflow: auto;
`
Recibo.ContainerRecibo = styled.div`
  border-left: 1px solid #D4D4D4;
`
Recibo.Heading = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  text-align: center;
  color: #122640;
  width:  470px;
  margin: 0 auto;
  margin-top: 36px;
`
Recibo.Subheading = styled.div`
  color: #545451;
  font-size: 16px;
  text-align: center;
  margin-top: 24px;
  font-weight: 400px;
`
Recibo.SubHeadingWarning = styled.div`
  color: #545451;
  font-size: 16px;
  text-align: center;
  margin-top: 24px;
  font-weight: 600px;
  color: #eb8d00;
`

const PassosExecucaoVistoria = () => {
  const { Layout, Bloco, Divisor } = PassosExecucaoVistoria
  return (
    <Layout>
      <Bloco>
        <img src={iconLogo} />
        Seleção ECV
      </Bloco>
      <Divisor />
      <Bloco>
        <img src={iconLogo} />
        Pagamento
      </Bloco>
      <Divisor />
      <Bloco inactive>
        <img src={iconLogo} />
        Vistoria
      </Bloco>
    </Layout>
  )
}
PassosExecucaoVistoria.Layout = styled.div`
margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  img {
    width: 18px;
  }
`
PassosExecucaoVistoria.Bloco = styled.div<{ inactive?}>(({ inactive }) => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  ${inactive ? 'opacity: .4;' : ''}
`)
PassosExecucaoVistoria.Divisor = styled.div`
  border: 1px solid #7F7F7D;
  width: 32px;
`
