export const createApi = (
  fn: (params: {
    url: string
    method: 'get' | 'post' | 'put' | 'delete'
    body?
    params?
    options?
    callee?: [string, string]
  }) => any
) => {
  return {
    Vistorias: {
      getVistorias(extraProps?: { params?: any; options?: any }): Promise<Schemas.Vistoria[]> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/vistorias',
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Vistorias', 'getVistorias'],
        })
      },
      cancelaVistoria(
        body: Schemas.CancelaVistoriaDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<unknown> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/vistorias',
          method: 'delete',
          body: body,
          params,
          options,
          callee: ['Vistorias', 'cancelaVistoria'],
        })
      },
      createVistoria(
        body: Schemas.CreateVistoriaDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.CreateVistoriaResponseDto> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/vistorias',
          method: 'post',
          body: body,
          params,
          options,
          callee: ['Vistorias', 'createVistoria'],
        })
      },
      getVistoriasAdm(extraProps?: {
        params?: {
          order?: string
          orderBy?: string
          orderByEntity?: string
          offset?: number
          limit?: number
          filtroTexto?: string
          query: undefined
          ecvId: undefined
        }
        options?: any
      }): Promise<Schemas.GetVistoriasAdmResponseDto> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/vistorias/adm',
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Vistorias', 'getVistoriasAdm'],
        })
      },
      getHorariosDisponiveis(
        pathParams: { vistoriaId: number },
        extraProps?: { params?: { data: string }; options?: any }
      ): Promise<Schemas.GetHorariosDisponiveisResponseDto[]> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: `/vistorias/${pathParams.vistoriaId}/horarios-disponiveis`,
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Vistorias', 'getHorariosDisponiveis'],
        })
      },
      getRelatorioAgendamentos(
        pathParams: { ecvId: number },
        extraProps?: { params?: { data: string }; options?: any }
      ): Promise<Schemas.Vistoria[]> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: `/vistorias/relatorio/agendamento/${pathParams.ecvId}`,
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Vistorias', 'getRelatorioAgendamentos'],
        })
      },
      checkPagamento(
        pathParams: { vistoriaId: number },
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.Vistoria> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: `/vistorias/check-pagamento/${pathParams.vistoriaId}`,
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Vistorias', 'checkPagamento'],
        })
      },
      createAgendamento(
        body: Schemas.CreateAgendamentoDto,
        pathParams: { vistoriaId: number },
        extraProps?: { params?: any; options?: any }
      ): Promise<unknown> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: `/vistorias/${pathParams.vistoriaId}/create-agendamento`,
          method: 'post',
          body: body,
          params,
          options,
          callee: ['Vistorias', 'createAgendamento'],
        })
      },
      cancelarAgendamento(
        pathParams: { vistoriaId: number },
        extraProps?: { params?: any; options?: any }
      ): Promise<unknown> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: `/vistorias/${pathParams.vistoriaId}/cancelar-agendamento`,
          method: 'post',
          body: undefined,
          params,
          options,
          callee: ['Vistorias', 'cancelarAgendamento'],
        })
      },
      getNF(
        pathParams: { idVistoria: number },
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.IDataValuesNf> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: `/vistorias/get-nf/${pathParams.idVistoria}`,
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Vistorias', 'getNF'],
        })
      },
      updateDataConclusao(
        body: { vistoriaId: number; type: 'APROVADA' | 'REPROVADA' },
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.Vistoria> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: `/vistorias/concluir`,
          method: 'put',
          body,
          params,
          options,
          callee: ['Vistorias', 'updateDataConclusao'],
        })
      },
      getBoletosByContaId(extraProps?: { params?: any; options?: any }): Promise<unknown> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/vistorias/relatorio',
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Vistorias', 'getBoletosByContaId'],
        })
      },
      distruibiEcv(
        body: Schemas.DistribuiEcvDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.Ecv> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/vistorias/distribui',
          method: 'post',
          body: body,
          params,
          options,
          callee: ['Vistorias', 'distruibiEcv'],
        })
      },
    },
    UgcsExternal: {
      getPagamentoVistoria(extraProps?: {
        params?: { placa: string; cnpjVistoriadora: string }
        options?: any
      }): Promise<Schemas.GetPagamentoVistoriaResponseDto> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/ugcs-external/pagamento',
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['UgcsExternal', 'getPagamentoVistoria'],
        })
      },
    },
    Ecvs: {
      getEcvs(extraProps?: {
        params?: { municipio: string }
        options?: any
      }): Promise<Schemas.Ecv[]> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/ecvs',
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Ecvs', 'getEcvs'],
        })
      },
      getMunicipiosDisponiveis(extraProps?: { params?: any; options?: any }): Promise<string[]> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/ecvs/municipios',
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Ecvs', 'getMunicipiosDisponiveis'],
        })
      },
      importEcv(extraProps?: { params?: { cnpj: string }; options?: any }): Promise<Schemas.Ecv> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/ecvs/importa',
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Ecvs', 'importEcv'],
        })
      },
      getEcv(
        pathParams: { id: number },
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.Ecv> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: `/ecvs/${pathParams.id}`,
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Ecvs', 'getEcv'],
        })
      },
      updateECV(
        body: Schemas.Ecv,
        pathParams: { id: number },
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.Ecv> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: `/ecvs/update/${pathParams.id}`,
          method: 'put',
          body: body,
          params,
          options,
          callee: ['Ecvs', 'updateECV'],
        })
      },
      getEcvFoto(extraProps?: { params?: { ecvId: number }; options?: any }): Promise<unknown> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/ecvs/adm/foto',
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Ecvs', 'getEcvFoto'],
        })
      },
      uploadEcvFoto(
        body: Schemas.UploadEcvFotoDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<unknown> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/ecvs/adm/foto',
          method: 'post',
          body: body,
          params,
          options,
          callee: ['Ecvs', 'uploadEcvFoto'],
        })
      },
      deleleEcvFoto(
        body: Schemas.DeleleEcvFotoDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<unknown> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/ecvs/adm/foto',
          method: 'delete',
          body: body,
          params,
          options,
          callee: ['Ecvs', 'deleleEcvFoto'],
        })
      },
      getEcvCertificado(extraProps?: {
        params?: { ecvId: number }
        options?: any
      }): Promise<Record<string, any>> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/ecvs/adm/certificado',
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Ecvs', 'getEcvCertificado'],
        })
      },
      uploadEcvCertificado(
        body: Schemas.UploadEcvCertificadoDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<unknown> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/ecvs/adm/certificado',
          method: 'post',
          body: body,
          params,
          options,
          callee: ['Ecvs', 'uploadEcvCertificado'],
        })
      },
      deleleEcvCertificado(
        body: Schemas.DeleleEcvCertificadoDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<unknown> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/ecvs/adm/certificado',
          method: 'delete',
          body: body,
          params,
          options,
          callee: ['Ecvs', 'deleleEcvCertificado'],
        })
      },
    },
    Clientes: {
      getOrCreateCliente(
        body: Schemas.GetOrCreateClienteDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.Cliente> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clientes/get-or-create',
          method: 'post',
          body: body,
          params,
          options,
          callee: ['Clientes', 'getOrCreateCliente'],
        })
      },
      updateCadastro(
        body: Schemas.UpdateCadastroDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.ValidaTokenDto> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clientes',
          method: 'put',
          body: body,
          params,
          options,
          callee: ['Clientes', 'updateCadastro'],
        })
      },
      verificaCadastroIncompleto(
        pathParams: { cpfCnpj: string },
        extraProps?: { params?: any; options?: any }
      ): Promise<boolean> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: `/clientes/verifica-cadastro-incompleto/${pathParams.cpfCnpj}`,
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Clientes', 'verificaCadastroIncompleto'],
        })
      },
      finalizaCadastro(
        body: Schemas.FinalizaCadastroDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.Cliente> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clientes/finaliza-cadastro',
          method: 'put',
          body: body,
          params,
          options,
          callee: ['Clientes', 'finalizaCadastro'],
        })
      },
      updateSenha(
        body: Schemas.UpdateSenhaDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.Cliente> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clientes/senha',
          method: 'put',
          body: body,
          params,
          options,
          callee: ['Clientes', 'updateSenha'],
        })
      },
      getIfExistCliente(extraProps?: {
        params?: { cpfCnpj: string }
        options?: any
      }): Promise<Record<string, any>> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clientes/get-if-exist',
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Clientes', 'getIfExistCliente'],
        })
      },
      recuperaSenha(
        body: Schemas.RecuperaSenhaDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<unknown> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clientes/recupera-senha',
          method: 'post',
          body: body,
          params,
          options,
          callee: ['Clientes', 'recuperaSenha'],
        })
      },
      recuperaEmail(
        body: Schemas.RecuperaEmailDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<string> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clientes/recupera-email',
          method: 'post',
          body: body,
          params,
          options,
          callee: ['Clientes', 'recuperaEmail'],
        })
      },
      validaToken(
        body: Schemas.ValidaTokenDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<boolean> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clientes/valida-token',
          method: 'post',
          body: body,
          params,
          options,
          callee: ['Clientes', 'validaToken'],
        })
      },
      updateSenhaToken(
        body: Schemas.UpdateSenhaTokenDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<boolean> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clientes/senha/token',
          method: 'put',
          body: body,
          params,
          options,
          callee: ['Clientes', 'updateSenhaToken'],
        })
      },
    },
    Veiculos: {
      getVeiculoExistenteByPlaca(
        pathParams: { placa: string },
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.Veiculo> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: `/veiculos/${pathParams.placa}`,
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Veiculos', 'getVeiculoExistenteByPlaca'],
        })
      },
      getOrCreateVeiculo(
        body: Schemas.GetOrCreateVeiculoDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.Veiculo> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/veiculos/get-or-create',
          method: 'post',
          body: body,
          params,
          options,
          callee: ['Veiculos', 'getOrCreateVeiculo'],
        })
      },
    },
    Auth: {
      login(
        body: Schemas.LoginDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.LoginResponseDto> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/auth/login',
          method: 'post',
          body: body,
          params,
          options,
          callee: ['Auth', 'login'],
        })
      },
    },
    Ugcs: {
      getAll(extraProps?: { params?: any; options?: any }): Promise<Schemas.Ugc[]> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/ugcs',
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Ugcs', 'getAll'],
        })
      },
    },
    Webhooks: {
      notificarMudancaStatusPagamentoRep(extraProps?: {
        params?: any
        options?: any
      }): Promise<Record<string, any>> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/webhooks/pagamento/hyperpay/{cobrancaId}',
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Webhooks', 'notificarMudancaStatusPagamentoRep'],
        })
      },
    },
  }
}

export namespace Schemas {
  export interface UgcValor extends Model {
    ugcId: number
    ugc: Schemas.Ugc
    ecvId: number
    ecv: Schemas.Ecv
    valor: number
  }
  export interface Ugc extends Model {
    descricao: string
    cnpj: string
    ugcValores: Schemas.UgcValor[]
  }
  export interface Ecv extends Model {
    ugcId: number
    ugc: Schemas.Ugc
    nome: string
    cnpj: string
    valor: number
    municipio: string
    uf: string
    latitude: number
    longitude: number
    endereco: string
    bairro: string
    telefone: string
    foto: string
    certificado: string
    tipoAtendimento: 'AGENDAMENTO' | 'ORDEM_CHEGADA'
    qtdVistoriadores: number
    repassesAdicionais: string[]
    repassesAdicionaisExibicao: string[]
    createdBy: string
  }
  export interface Cliente extends Model {
    cpfCnpj: string
    nome: string
    email: string
    telefone: string
    senha: string
    token: string
    createdBy: string
    vistorias: Schemas.Vistoria[]
  }
  export interface VistoriaEvento extends Model {
    vistoriaId: number
    vistoria: Schemas.Vistoria
    status: 'AGENDADA' | 'AGENDAMENTO_CANCELADO' | 'APROVADA' | 'REPROVADA' | 'BLOQUEADA'
    dadosAdicionais: string
  }
  export interface Vistoria extends Model {
    ecvId: number
    ecv: Schemas.Ecv
    ugcId: number
    ugc: Schemas.Ugc
    veiculoId: number
    veiculo: Schemas.Veiculo
    clienteId: number
    cliente: Schemas.Cliente
    vistoriaEvento: Schemas.VistoriaEvento
    cobrancaId: number
    idNotaFiscal: string
    dataVencimentoCobranca: string
    dataPagamentoCobranca: string
    dataConclusao?: string | null
    dataAgendamento?: string | null
    valorCobranca: number
    valorRepasse: number
    meioPagamento: string
    createdBy: string
  }
  export interface Veiculo extends Model {
    placa: string
    marcaModelo: string
    ano: string
    tipo: string
    cor: string
    municipio: string
    uf: string
    chassi: string
    dadosAdicionais: string
    vistorias: Schemas.Vistoria[]
    createdBy: string
  }
  export interface CancelaVistoriaDto {
    vistoriaId: number
  }
  export interface GetVistoriasAdmResponseDto {
    total: Record<string, any>
    totalFinalizadas: number
    totalNaoRealizadas: number
    totalAguardandoPagamento: number
    vistorias: Schemas.Vistoria[]
  }
  export interface GetHorariosDisponiveisResponseDto {
    inicio: string
    fim: string
  }
  export interface CreateVistoriaDto {
    ecvId: number
    veiculoId: number
    clienteId: number
  }
  export interface CreateVistoriaResponseDto {
    vistoria: Schemas.Vistoria
    cobranca: Record<string, any>
  }
  export interface CreateAgendamentoDto {
    dataAgendamento: string
  }
  export interface NotaFiscal {
    Id: string
    Data_Emissao: string
    Data_Referencia: string
    Valor: number
    IdExterno: string
    ServicoExterno: string
    Status: 'NA_FILA' | 'PROCESSANDO' | 'ERRO_AO_ENVIAR' | 'CONCLUIDO' | 'REJEITADO'
    Mensagem_Provedor: string
    Tipo: 'SERVICO' | 'PRODUTO'
    Servico: string
    Itens: string
    Prestador_Id: number
    Prestador: Record<string, any>
    Tomador_Id: number
    Tomador: Record<string, any>
  }
  export interface IDataValuesNf {
    data?: Schemas.NotaFiscal | null
    success: boolean
  }
  export interface DistribuiEcvDto {
    municipio: string
    placa: string
  }
  export interface GetPagamentoVistoriaResponseDto {
    pago: boolean
    dataPagamento?: string | null
  }
  export interface UploadEcvFotoDto {
    ecvId: number
    imageBase64: string
  }
  export interface DeleleEcvFotoDto {
    ecvId: number
  }
  export interface UploadEcvCertificadoDto {
    ecvId: number
    fileName: string
    certificadoBase64: string
  }
  export interface DeleleEcvCertificadoDto {
    ecvId: number
    fileName: string
  }
  export interface GetOrCreateClienteDto {
    cpfCnpj: string
    nome: string
    telefone?: string | null
    email?: string | null
    senha?: string | null
  }
  export interface UpdateCadastroDto {
    cpfCnpj: string
    nome: string
    telefone: string
  }
  export interface ValidaTokenDto {
    token: string
  }
  export interface FinalizaCadastroDto {
    cpfCnpj: string
    telefone?: string | null
    email: string
    senha: string
    confirmarSenha: string
  }
  export interface UpdateSenhaDto {
    cpfCnpj: string
    senhaAtual: string
    novaSenha: string
    confirmarSenha: string
  }
  export interface RecuperaSenhaDto {
    email: string
  }
  export interface RecuperaEmailDto {
    cpfCnpj: string
  }
  export interface UpdateSenhaTokenDto {
    token: string
    senha: string
  }
  export interface GetOrCreateVeiculoDto {
    placa: string
  }
  export interface LoginDto {
    cpf: string
    senha: string
  }
  export interface LoginResponseDto {
    token: string
    usuario: any
    ecvs?: Schemas.Ecv[] | null
  }
}

interface Model {
  id: number
  createdAt: string
  updatedAt: string
  deletedAt?: string
}
