import React from 'react'
import styled from '@emotion/styled'

export const Input = styled.input`
  padding: 8px 16px;
  height: 41px;
  background: #FFFFFF;
  border: 1px solid #D4D4D4;
  border-radius: 4px;
  font-size: 14px;
  line-height: 120%;
  color: #292926;
  transition: .25s all;
  width: 100%;
  &:disabled {
    background: #EAEAE9;
    color: #AAAAA8;
  }
  &:focus {
    outline: none;
    border: 1px solid #FFBC01;
    box-shadow: 0px 0px 3px #FFBC01;
  }
`
