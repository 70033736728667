import axios from 'axios'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { createSelector } from 'reselect'
import { createSimpleStore } from 'react-simple-reducer'
import toast from 'react-hot-toast'
import { Schemas } from '../../components/ApiSchemas'
import { api } from '../../components/Api'
import { PERMISSOES } from '../../components/Permissoes'

export const AuthStore = createSimpleStore(
  {
    fetching: false,
    loading: false,
    isTokenValid: null as boolean | null,
    emailExists: null as string | null,
    token: null as string | null,
    usuario: null as any,
    permissoes: [] as PERMISSOES[],
    envioEmailSucceded: null as boolean | null,
    ecvs: [] as Array<Schemas.Ecv & { isSocioAdmin?: boolean }>,
    ecvIdSelecionada: null as number | null,
    loadingPublicKey: false as boolean,
    publicKey: '' as string,
    isActiveSession: false as boolean,
  },
  {
    loginFetch(state) {
      state.fetching = true
    },
    loginSuccess(state, payload: { token; usuario; ecvs? }) {
      state.fetching = false
      state.token = payload.token
      state.usuario = payload.usuario
      const decodedToken = jwtDecode<{ perms: PERMISSOES[] }>(payload.token)
      state.permissoes = decodedToken.perms
      if (payload.ecvs?.length) {
        state.ecvs = payload.ecvs
        state.ecvIdSelecionada = payload.ecvs[0].id
      }
    },
    loginError(state) {
      state.fetching = false
    },
    logout(state) {
      state.token = null
      state.ecvIdSelecionada = null
      state.ecvs = []
    },
    changeEcv(state, ecvId: number) {
      state.ecvIdSelecionada = ecvId
    },
    mudarSenhaTokenFetch(state) {
      state.loading = true
    },
    mudarSenhaTokenSuccess(state) {
      state.loading = false
      state.isTokenValid = false
    },
    mudarSenhaTokenError(state) {
      state.loading = false
    },
    validaToken(state, isTokenValid: boolean) {
      state.isTokenValid = isTokenValid
    },
    checkEmailExistsFetch(state) {
      state.loading = true
    },
    stopCheckEmailExistsSuccess(state) {
      state.loading = false
    },
    stopCheckEmailExists(state) {
      state.loading = false
    },
    enviaEmailSuccess(state) {
      state.loading = false
      state.envioEmailSucceded = true
    },
    clearEnvioEmailSucceded(state) {
      state.envioEmailSucceded = null
    },
    checkEmailExistsSuccess(state, emailExists: string) {
      state.emailExists = emailExists
      state.loading = false
    },
    clearEmail(state) {
      state.emailExists = null
    },
    importEcvSuccess(state, ecv: Schemas.Ecv) {
      if (!state.ecvs.some((x) => x.id === ecv.id)) {
        state.ecvs = [...state.ecvs, ecv]
      }
      state.ecvIdSelecionada = ecv.id
    },
    changeEcvs(state, ecvs: Schemas.Ecv[]) {
      state.ecvs = ecvs
    },
    updateCadastroToken(state, token: string) {
      state.token = token
    },
    getPublicKeyStarted(state) {
      state.loadingPublicKey = true
    },
    getPublicKeySuccess(state, publicKey: string) {
      state.loadingPublicKey = false
      state.publicKey = publicKey
      state.isActiveSession = !!publicKey
    },
    getPublicKeyError(state) {
      state.loadingPublicKey = false
      state.isActiveSession = false
    },
  },
  {
    thunks: {
      recuperarSenha({ email }: { email: string }) {
        return async (dispatch: any) => {
          try {
            dispatch(AuthStore.actions.checkEmailExistsFetch())
            await api.Clientes.recuperaSenha({ email })
            dispatch(AuthStore.actions.enviaEmailSuccess())
            toast('E-mail será enviado nas próximas horas')
          } catch (error: any) {
            dispatch(AuthStore.actions.stopCheckEmailExistsSuccess())
            const message =
              error.response?.data?.message ??
              'Falha ao enviar envio de email para recuperação de senha'
            toast.error(message)
          }
        }
      },
      mudarSenhaToken({ senha, token }: { senha: string; token: string }) {
        return async (dispatch: any) => {
          try {
            dispatch(AuthStore.actions.mudarSenhaTokenFetch())
            await api.Clientes.updateSenhaToken({ senha, token })
            dispatch(AuthStore.actions.mudarSenhaTokenSuccess())
            toast('Senha alterada com sucesso')
          } catch (error: any) {
            const message =
              error.response?.data?.message ??
              'Falha ao enviar envio de email para recuperação de senha'
            toast.error(message)
            dispatch(AuthStore.actions.mudarSenhaTokenError())
          }
        }
      },
      validaToken({ token }: { token: string }) {
        return async (dispatch: any) => {
          try {
            const isTokenValid = await api.Clientes.validaToken({ token })
            dispatch(AuthStore.actions.validaToken(isTokenValid))
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Falha ao realizar validação de token'
            toast.error(message)
          }
        }
      },
      recuperarEmail({ cpfCnpj }: { cpfCnpj: string }) {
        return async (dispatch: any) => {
          try {
            dispatch(AuthStore.actions.checkEmailExistsFetch())
            const emailExists = await api.Clientes.recuperaEmail({ cpfCnpj })
            dispatch(AuthStore.actions.checkEmailExistsSuccess(emailExists))
          } catch (error: any) {
            dispatch(AuthStore.actions.stopCheckEmailExists())
            const message = error.response?.data?.message ?? 'Falha ao buscar email'
            toast.error(message)
          }
        }
      },
      getSession() {
        return async (dispatch) => {
          try {
            dispatch(AuthStore.actions.getPublicKeyStarted())
            const publicKey = await axios
              .get('/crypto', { withCredentials: true })
              .then((res) => res.data)
            dispatch(AuthStore.actions.getPublicKeySuccess(publicKey))
          } catch (error) {
            dispatch(AuthStore.actions.getPublicKeyError())
          }
        }
      },
    },
    options: {
      cache: {
        key: 'VISTORIAFACIL_AUTH',
        location: 'LOCALSTORAGE',
      },
    },
  }
)

type IState = ReturnType<typeof AuthStore.useState>

export const getUsuarioLogado = createSelector(
  (s: IState) => s.token,
  (s: IState) => s.usuario,
  (token, usuario) => {
    if (!token) return null
    // const decodedToken = jwtDecode<JwtPayload & Schemas.Cliente & { isAdmin?: boolean }>(token)
    // const now = Date.now().valueOf() / 1000
    // if (!decodedToken || !decodedToken.exp || decodedToken.exp < now) return null
    return usuario
  }
)

export const selectEcvAtual = createSelector(
  (s: IState) => s.ecvs,
  (s: IState) => s.ecvIdSelecionada,
  (ecvs, ecvId) => {
    return ecvs.find((e) => e.id === ecvId)
  }
)
